<template>
    <edit-treasure
        v-if="editingPin"
        :pin="editingPin"
        :wandering="editingIsAdventure"
        :wanderingPins="editingAdventurePins"
        @close="closeEditPin"
    />

    <StatsView :stats="stats" main-action="user-active">
        <template #title>Dashboard</template>
        <template #subtitle>Her er en oversikt over all data samlet på brukere</template>
        <template #left>
            <div class="left-side" v-if="area">
                <div class="map">
                    <Map :pins="pins" :center="{ lat: area.location.latitude, lng: area.location.longitude }" @click-pin="editPin" style="display:block;"></Map>
                    <div class="category-filter-wrapper">
                        <checkbox-dropdown :values="categoryFilterOptions" v-model="categoryFilter" style="min-width: 100px;">
                            <template #default="{value}">Kategori</template>
                            <template #value="{value}">{{ categoryById(value).name }}</template>
                        </checkbox-dropdown>
                    </div>
                </div>
                <div class="total-users" v-if="useRawStats">
                    <b>Aktive brukere:</b> {{ totalUsers }}
                </div>
                <div class="top-pins-row">
                    <div class="top-treasures">
                        <h4>Mest populære skatter</h4>
                        <table>
                            <tr>
                                <th>Skatt</th>
                                <th>Besøk</th>
                            </tr>
                            <tr v-for="pin in topTreasures">
                                <td><a href="" @click.prevent="editPin(getPin(pin.treasure))">{{ getPin(pin.treasure).name }}</a></td>
                                <td v-if="useRawStats">{{ pin.checkins }}</td>
                                <td v-else>{{ Math.floor(10000 * pin.checkins) / 100 }}%</td>
                            </tr>
                        </table>
                    </div>
                    <div class="top-adventures">
                        <h4>Mest populære vandringer</h4>
                        <table>
                            <tr>
                                <th>Vandring</th>
                                <th>Besøk</th>
                            </tr>
                            <tr v-for="pin in topAdventures">
                                <td><a href="" @click.prevent="editPin(getPin(pin.adventure))">{{ getPin(pin.adventure).wanderingName }}</a></td>
                                <td v-if="useRawStats">{{ pin.started }}</td>
                                <td v-else>{{ Math.floor(10000 * pin.started) / 100 }}%</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </StatsView>
</template>

<script>
import C from '@/const'
import Area from '@/types/Area'
import StatsView from './StatsView.vue'
import Map from './DashboardView/Map.vue'
import EditTreasure from './TreasuresView/EditTreasure.vue'
import CheckboxDropdown from '@/components/CheckboxDropdown.vue'

const genders = { male: 'Menn', female: 'Kvinner', other: 'Andre' };

export default {
    components: {
        StatsView,
        Map,
        EditTreasure,
        CheckboxDropdown,
    },
    data: () => ({
        editingPin: null,
        editingAdventurePins: null,
        editingIsAdventure: false,
        selectedCategoryFilter: null,
    }),
    computed: {
        useRawStats() {
            return this.$store.state.Stats.useRawStats;
        },
        area() {
            return this.$store.state.Areas.selectedArea?.area;
        },
        pins() {
            return this.$store.state.Pins.pins.filter(pin => pin.published).filter(pin => this.selectedCategoryFilter == null || this.selectedCategoryFilter.indexOf(pin.category) != -1);
        },
        stats() {
            return this.$store.state.Stats.main;
        },
        topTreasures() {
            return this.$store.getters['Stats/topTreasures'];
        },
        topAdventures() {
            return this.$store.getters['Stats/topAdventures'];
        },
        totalUsers() {
            return this.$store.getters['Stats/countAction'](this.stats, 'user-active');
        },
        periodFilter() {
            return this.$store.state.Stats.periodFilter;
        },
        categoryFilterOptions() {
            return this.$store.state.PinCategories.categories.map(cat => cat.id);
        },
        categoryFilter: {
            get() {
                if (!this.selectedCategoryFilter) return this.categoryFilterOptions;
                return this.selectedCategoryFilter;
            },
            set(val) {
                this.selectedCategoryFilter = val;
            },
        },
    },
    methods: {
        async load() {
            this.$store.dispatch('Stats/load', 'Area/' + this.area.id);
            await this.$store.dispatch('Pins/ensureLoaded');
            this.$store.dispatch('Stats/loadPinStats', this.$store.state.Pins.pins);
        },
        getPin(id) {
            return this.$store.state.Pins.pinsById[id];
        },
        editPin(pin) {
            this.editingPin = pin;
            this.editingIsAdventure = pin.wanderingChildren.length > 0;
            if (this.editingIsAdventure) {
                this.editingAdventurePins = pin.wanderingChildren.map(id => this.$store.state.Pins.pinsById[id]);
            } else {
                this.editingAdventurePins = null;
            }
        },
        closeEditPin() {
            this.editingPin = null;
        },
        categoryById(id) {
            return this.$store.state.PinCategories.byId[id];
        },
    },
    watch: {
        area: {
            async handler(_, old) {
                if (!this.area) return null;
                if (old && this.area.id == old.id) return;
                this.load();
            },
            immediate: true,
        },
        periodFilter() {
            this.load();
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.left-side {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .map {
        position: relative;
        flex-grow: 1;
        background: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .category-filter-wrapper {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }

    .total-users {
        padding: 10px 30px;
        background: white;
        border-radius: 10px;
    }

    .top-pins-row {
        display: flex;
        gap: 30px;

        .top-treasures,
        .top-adventures {
            padding: 10px 30px;
            background: white;
            border-radius: 10px;
            flex-grow: 1;

            h4 {
                margin-bottom: 10px;
            }

            table {
                width: 100%;
                border-collapse: collapse;

                tr td, tr th {
                    text-align: left;
                    //width: 50%;
                    padding: 5px 0;

                    + td {
                        text-align: right;
                    }

                    a {
                        color: black;
                    }
                }
            }
        }
    }
}
</style>