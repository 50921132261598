<template>
    <div id="dropdown-overlay" :class="{ open: isOpen }" @click="clickOverlay">
        <div class="options overlay-options" ref="overlayOptions" :style="overlayRect">
            <div class="overlay-options-inner" v-pre></div>
        </div>
    </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'

export default defineComponent({
    name: 'DropdownOverlay',
    data: () => ({
        dropdown: null,
        isOpen: false,
        overlayRect: {
            left: 0,
            top: 0,
        },
    }),
    methods: {
        clickOverlay(event) {
            let target = event.target;
            let done = false;
            while (!done) {
                if (target == this.$el) {
                    // Click on the overlay - close
                    this.isOpen = false;
                    this.dropdown.clickOverlay();
                    done = true;
                } else if (target.classList.contains('search')) {
                    // It's the search field - do nothing with the click
                    return;
                } else if (target.classList.contains('clickable')) {
                    // It's a custom clickable element - notify it
                    done = true;
                    this.isOpen = this.dropdown.clickClickable(target);
                } else if (target.parentElement.parentElement == this.$refs.overlayOptions) {
                    // Click on an option - close, and emit value
                    done = true;
                    this.isOpen = this.dropdown.clickOption(target.getAttribute('value'));
                } else {
                    // Not found anything yet - go up and check again
                    target = target.parentElement;
                }
            }
        },
        open(dropdown) {
            this.dropdown = dropdown;
            this.$refs.overlayOptions.firstChild.innerHTML = '';
            let searchElmt;
            [...dropdown.$refs.options.children].forEach(child => {
                const el = child.cloneNode(true);
                this.$refs.overlayOptions.firstChild.appendChild(el);
                if (el.classList.contains('search')) searchElmt = el;
            });

            this.$nextTick(() => {
                if (searchElmt) {
                    const input = searchElmt.querySelector('input');
                    input.addEventListener('input', () => dropdown.editSearch(input.value));
                }

                const rect = dropdown.$refs.options.getBoundingClientRect();
                this.overlayRect = {
                    left: rect.left + 'px',
                    top: rect.top + 'px',
                    width: rect.width + 'px',
                    height: rect.height + 'px',
                };
                this.isOpen = true;
            });
        },
        reopen() {
            [...this.$refs.overlayOptions.firstChild.querySelectorAll('.option')].forEach(el => el.remove());
            [...this.dropdown.$refs.options.children].forEach(child => {
                if (child.classList.contains('search')) return;
                const el = child.cloneNode(true);
                this.$refs.overlayOptions.firstChild.appendChild(el);
            });

            this.$nextTick(() => {
                const rect = this.dropdown.$refs.options.getBoundingClientRect();
                this.overlayRect = {
                    left: rect.left + 'px',
                    top: rect.top + 'px',
                    width: rect.width + 'px',
                    height: rect.height + 'px',
                };
            });
        },
    },
    mounted() {
        getCurrentInstance().appContext.config.globalProperties.$dropdownOverlay = this;
    },
    beforeUnmount() {
        getCurrentInstance().appContext.config.globalProperties.$dropdownOverlay = null;
    },
})
</script>

<style lang="scss" scoped>
#dropdown-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 2147483647;

    &.open {
        display: block;
    }

    .overlay-options {
        position: absolute;
        box-sizing: border-box;
        background: white;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

        .overlay-options-inner {
            :deep(.option) {
                padding: 10px;
                text-align: center;
                cursor: default;

                &:hover {
                    background: #EEEEEE;
                }
            }

            :deep(.search) {
                .search-field {
                    width: 100%;
                    border: 1px solid lightgrey;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin: 10px;
                    cursor: text;

                    .search-input {
                        border: none;
                        outline: none;
                        width: 100%;
                    }
                }
            }

            :deep(.calendar) {
                .period-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    .period-control.clickable {
                        font-weight: bold;
                        font-size: 1.3em;
                        cursor: pointer;
                        border-radius: 5px;
                        padding: 8px 12px;
                        border: none;

                        &:hover {
                            background: #EEE;
                        }
                    }

                    .period {
                        flex-grow: 1;
                    }
                }

                table {
                    margin: auto;
                    width: 350px;
                }

                .week-header {
                    padding-right: 10px;
                }

                .week-number {
                    margin-right: 10px;
                }

                .clickable {
                    border: 1px solid darkgrey;
                    border-radius: 5px;
                    padding: 8px;
                    cursor: pointer;

                    &.cal-number {
                        width: 40px;
                        box-sizing: border-box;
                    }

                    &:hover {
                        background: #EEE;
                    }

                    &.selected {
                        background: #DDD;
                    }

                    &.selected:hover {
                        background: #BBB;
                    }
                }
            }
        }
    }
}
</style>