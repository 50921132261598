<template>
    <div class="google-map"></div>
</template>

<script>
import googlemaps from '@googlemaps/js-api-loader';

import { isWanderingChild, isWanderingParent } from '@/types/Pin'

export default {
    name: 'DashboardMap',
    props: {
        center: {
            type: Object,
            required: true,
        },
        pins: {
            type: Array,
            required: true,
        },
        zoom: {
            type: Number,
            default: 14,
        },
    },
    data: () => ({
        map: null,
        markers: [],

        isInternalUpdate: false,
        clickListener: null,
    }),
    methods: {
        onClick(marker, pin) {
            let el;
            if (this.$store.getters['Areas/hasEditorAccess']) {
                el = document.createElement('a');
                el.setAttribute('href', `/${isWanderingParent(pin) ? 'vandringer' : 'skatter'}/${pin.id}`);
                el.textContent = isWanderingParent(pin) ? pin.wanderingName : pin.name;
                el.addEventListener('click', ev => {
                    ev.preventDefault();
                    this.$emit('clickPin', pin);
                });
            } else {
                el = document.createElement('span');
                el.textContent = isWanderingParent(pin) ? pin.wanderingName : pin.name;
            }
            const infoWindow = new google.maps.InfoWindow({ content: el });
            infoWindow.open(this.map, marker);
        },
        setUpPins() {
            // Keeping the map wasn't removing the markers, even when calling the function the documentation said was
            // supposed to remove the marker.
            this.map = new google.maps.Map(this.$el, {
                zoom: this.zoom,
                center: this.center,
                disableDefaultUI: true,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: "transit",
                        stylers: [{ visibility: 'off' }],
                    },
                ],
            });

            this.markers = [];

            for (let pin of this.pins) {
                if (isWanderingChild(pin)) continue;
                const category = this.$store.state.PinCategories.byId[pin.category];
                let marker = new google.maps.Marker({
                    position: { lat: pin.location.latitude, lng: pin.location.longitude },
                    map: this.map,
                    icon: {
                        url: this.$store.getters['PinCategories/assetUrl'](category),
                        scaledSize: {
                            width: 32,
                            height: 32,
                        },
                        anchor: {
                            x: 0,
                            y: 32,
                        },
                    },
                });
                marker.addListener('click', event => this.onClick(marker, pin));
                this.markers.push(marker);
            }
        },
    },
    watch: {
        pins() {
            if (this.map && this.pins.length == 0) return;
            this.setUpPins();
        },
    },
    mounted() {
        this.setUpPins();
    },
}
</script>

<style lang="scss" scoped>
.google-map {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
</style>