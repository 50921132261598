<template>
    <div>
        <img src="./logo.png" alt="logo" />
    </div>
</template>

<script>
export default {
    name: 'TheLogo'
}
</script>

<style scoped>
div {
    background: white;
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.13);
}
img {
    object-fit: contain;
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
    /* padding: 5px; */
}
</style>