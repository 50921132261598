<template>
    <div>
        <div class="lang-btns">
            <button v-for="lang in languages" :key="lang.key" :class="{ selected: modelValue == lang }" @click="select(lang)">{{ lang.key }}</button>
        </div>
        <div class="reminder">Husk å legge til både norsk og engelsk!</div>
    </div>
</template>

<script>
import Language from '@/types/Language'

export default {
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Language,
            required: false,
        },
    },
    computed: {
        languages() {
            return this.$store.state.Languages.languages;
        },
    },
    methods: {
        select(lang) {
            this.$emit('update:modelValue', lang);
        },
    },
}
</script>

<style lang="scss" scoped>
    .lang-btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 5px;

        button {
            border: none;
            border-radius: 20px;
            color: white;
            padding: .3rem 1rem;
            margin: 0 5px 0 0;
            background: white;
            color: #6d6c97;
            cursor: pointer;
            text-transform: uppercase;
            border: 1px solid #6d6c97;
        }

        .selected {
            background: #6d6c97;
            color: white;
        }
    }

    .reminder {
        color: #CE6868;
        font-weight: bold;
    }
</style>