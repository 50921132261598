<template>
    <div class="dropdown">
        <div class="main">
            <slot :value="modelValue" v-if="modelValue">{{ modelValue }}</slot>
            <template v-else>{{ placeholder }}</template>
            <div class="arrow" :class="{ open: isOpen }"></div>
        </div>
        <div class="inner">
            <div class="main-sim" @click="open"></div>
            <div class="options" ref="options" v-show="isOpen">
                <label class="search" v-if="searchFilter">
                    <div class="search-field"><input type="text" class="search-input"></div>
                </label>
                <template v-for="(value, idx) in values">
                    <div class="option" :value="idx" v-if="displayValues[idx] !== false">
                        <slot :value="value">{{ value }}</slot>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

// How to use:
// values must be the values the dropdown will show. These can be any type - templates are used to determine what they look like:
// <dropdown :values="myItems" v-model="selectedItem">
//     <template #default="{ value }">{{ myItem.name }}</template>
// </dropdown>
// The template is used both for the default value and items in the list.
// v-model is used for the selected value. The item from the list that was passed is set directly when updated.
// A placeholder can be passed to display when nothing is selected. This is a string only.
// If there is no template, the default is to simply print the items in the template directly. If the values are already
// strings, this may be adequate.
// A search filter function can optionally be passed. If it is, a search box is shown at the top of the dropdown option
// list, that the user can type in. When the user types, the filter function is called for each value with the search
// query to determine whether to include that item in the search results.

// For maintainers:
// In order to facilitate proper dropdown behaviour, the option list is copied to a central overlay location which is
// placed in App.vue. This is done by direct element manipulation, and bypasses a lot of the Vue mechanisms. This
// behaviour is encapsulated into these two components, and should not be exposed to the world around them.

export default {
    name: 'Dropdown',
    props: {
        values: {
            type: Array,
            required: true,
        },
        modelValue: {
            required: false,
        },
        placeholder: {
            type: String,
            default: 'Velg...',
        },
        searchFilter: {
            type: Function,
            required: false,
        },
    },
    data: () => ({
        isOpen: false,
        displayValues: {},
        searchQuery: '',
    }),
    methods: {
        open() {
            this.searchQuery = '';
            this.isOpen = true;
            this.$dropdownOverlay.open(this);
        },
        clickOverlay() {
            this.isOpen = false;
        },
        clickOption(index) {
            this.$emit('update:modelValue', this.values[index]);
            this.isOpen = false;
            return false; // Should close dropdown
        },
        editSearch(query) {
            this.searchQuery = query;
        },
        updateSearch() {
            this.displayValues = {};
            if (this.searchQuery.trim() != '') {
                for (let i = 0; i < this.values.length; ++i) {
                    if (!this.searchFilter(this.values[i], this.searchQuery)) this.displayValues[i] = false;
                }
            }
            if (this.isOpen) this.$nextTick(() => {
                this.$dropdownOverlay.reopen();
            });
        },
    },
    watch: {
        searchQuery: {
            handler() {
                this.updateSearch();
            },
            immediate: true,
        },
        values() {
            this.updateSearch();
        },
    },
}
</script>

<style lang="scss" scoped>
.dropdown {
    background: white;
    border-radius: 10px;
    padding: 10px 30px;
    position: relative;
    z-index: 1;

    border: 1px solid darkgray;

    .main {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .arrow {
            width: 12px;
            height: 7px;
            background-color: darkslategray;
            clip-path: polygon(25% 0, 50% 55%, 75% 0, 100% 0, 50% 100%, 0 0);
            justify-self: end;
            margin-left: 10px;

            &.open {
                transform: scaleY(-1);
            }
        }
    }

    .inner {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .main-sim {
            width: 100%;
            height: 100%;
        }
    }

    .options {
        background: white;

        .search {
            .search-field {
                width: 100%;
                border: 1px solid lightgrey;
                padding: 5px 10px;
                border-radius: 5px;
                margin: 10px;
                cursor: text;

                .search-input {
                    border: none;
                    outline: none;
                    width: 100%;
                }
            }
        }

        .option {
            padding: 10px;
            text-align: center;
            cursor: default;

            &:hover {
                background: #EEEEEE;
            }
        }
    }
}
</style>