import { createApp } from 'vue'
import ImageKit from 'imagekit-javascript'
import { initializeApp } from 'firebase/app'

import App from './App.vue'
import router from './router'
import store from './store'

import C from './const'

import PopupShell from './components/PopupShell.vue'
import HeaderShell from './components/HeaderShell.vue'
import HeaderButton from '@/components/HeaderShell/HeaderButton.vue'
import NewFormShell from './components/NewFormShell.vue'
import ItemCard from "./components/ItemCard.vue"
import TheFooter from "./components/TheFooter.vue"
import MainWrapper from "./components/MainWrapper.vue"
import LanguageBtns from "./components/LanguageBtns.vue"
import SelectDropdown from "./components/SelectDropdown.vue"

//import serverConfig from './config/server'
let serverConfig = require('./config/server.default').default;
try {
    serverConfig = Object.assign(serverConfig, require('./config/server').default);
} catch (e) {}

/*/
// Dev config
const firebaseApp = initializeApp({
    apiKey: "AIzaSyCnqTFXmwIBR3CfJoZAZHKEHre-mYWCiXw",
    authDomain: "kulturjakten-dev-79228.firebaseapp.com",
    projectId: "kulturjakten-dev-79228",
    storageBucket: "kulturjakten-dev-79228.appspot.com",
    messagingSenderId: "190431929206",
    appId: "1:190431929206:web:f50320e7a94040251e9057"
});

// @ts-ignore Should remove this before final publication
window.$store = store;
/*/
// Prod config
const firebaseApp = initializeApp({
    apiKey: "AIzaSyBBrhNeBkTFJqIwTI_kh4uwH6In9q62zfU",
    authDomain: "kulturjakten-prod.firebaseapp.com",
    projectId: "kulturjakten-prod",
    storageBucket: "kulturjakten-prod.appspot.com",
    messagingSenderId: "1077960677320",
    appId: "1:1077960677320:web:0e26206a0794237b08d2fd"
});
/**/

const app = createApp(App)
app.use(store)
app.use(router)

app.component('item-card', ItemCard)
app.component('popup-shell', PopupShell)
app.component('header-shell', HeaderShell)
app.component('header-button', HeaderButton)
app.component('new-form-shell', NewFormShell)
app.component('main-wrapper', MainWrapper)
app.component('the-footer', TheFooter)
app.component('language-btns', LanguageBtns)
app.component('select-dropdown', SelectDropdown)

app.mount('#app')

declare global {
    interface Window {
        imagekit: ImageKit
        google: any
        googleLoader: Promise<any>
    }
}

import { Loader } from '@googlemaps/js-api-loader';
window.googleLoader = new Loader({
    apiKey: C.googleMapsApiKey,
    version: "weekly",
}).load().then(google => {
    window.google = google;
});

window.imagekit = new ImageKit(serverConfig.imageKit);

// @ts-ignore Should remove this before final publication
//window.$store = store;