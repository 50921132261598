<template>
    <slot v-if="user" :user="user"></slot>
    <slot v-else name="placeholder"></slot>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UserLoader',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        user() {
            return this.$store.state.Users.users[this.id];
        },
    },
    watch: {
        id: {
            handler() {
                this.$store.dispatch('Users/loadUser', this.id);
            },
            immediate: true,
        },
    },
});
</script>