<template>
    <TextQuestion v-if="answer.type == 'q-text'" :question="question" :answer="answer" />
    <NumberQuestion v-if="answer.type == 'q-number'" :question="question" :answer="answer" />
    <MultipleChoiceQuestion v-if="answer.type == 'q-multiple'" :question="question" :answer="answer" />
    <MultipleChoiceGridQuestion v-if="answer.type == 'q-multiple-grid'" :question="question" :answer="answer" />
    <AreaQuestion v-if="answer.type == 'q-area'" :question="question" :answer="answer" />
</template>

<script>
import { defineComponent } from 'vue'

import TextQuestion from './TextQuestion.vue'
import NumberQuestion from './NumberQuestion.vue'
import MultipleChoiceQuestion from './MultipleChoiceQuestion.vue'
import MultipleChoiceGridQuestion from './MultipleChoiceGridQuestion.vue'
import AreaQuestion from './AreaQuestion.vue'

export default defineComponent({
    name: 'QuestionWrapper',
    components: {
        TextQuestion,
        NumberQuestion,
        MultipleChoiceQuestion,
        MultipleChoiceGridQuestion,
        AreaQuestion,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        },
    },
});
</script>