<template>
    <div v-pre></div>
</template>

<script>
import { defineComponent } from 'vue'
import Plotly from 'plotly.js-dist-min'

export default defineComponent({
    name: 'MultipleChoiceQuestion',
    props: {
        question: {
            type: Object,
            required: true,
        },
        answers: {
            type: Object,
            required: true,
        },
    },
    computed: {
        data() {
            return [
                {
                    x: this.question.optionsOrder.map(idx => this.question.options[idx]),
                    y: this.question.optionsOrder.map(idx => this.answers[idx]),
                    type: 'bar',
                },
            ];
        },
    },
    mounted() {
        Plotly.newPlot(this.$el, this.data);
    },
});
</script>