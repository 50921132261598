import { PutObjectCommand, DeleteObjectsCommand, PutObjectCommandInput, ListObjectsCommand, S3Client } from '@aws-sdk/client-s3';
import * as uuid from 'uuid'

import callFirebase from '@/util/callFirebase'

interface KeyInfo {
    key: string
    secret: string
    endpoint: string
    region: string
    bucket: string
    cdnBaseUrl: string
}

type ImageType = 'pin'|'prize'

export default class ImageUploader {
    private image: Blob
    private fileType: string
    private contentType: string
    private maxSize?: number

    private status: 'ready'|'working'|'uploading'|'uploaded'|'finalizing'|'finalized'|'error' = 'ready'

    private keyInfo?: KeyInfo
    private s3client?: S3Client
    private filelist?: string[]

    constructor(image: File) {
        this.image = image;
        this.fileType = image.name.substr(image.name.lastIndexOf('.') + 1);
        this.contentType = image.type;
    }

    resize(maxSize: number = 400): Promise<void> {
        if (this.status != 'ready') throw 'Uploader not ready.';
        this.status = 'working';
        
        this.maxSize = maxSize;
        this.status = 'ready';
        return Promise.resolve();
    }

    async upload(type: ImageType, areaId: string, objectId: string) {
        if (this.status != 'ready') throw 'Cannot reuse uploader.';
        this.status = 'uploading';

        //this.keyInfo = (await Parse.Cloud.run(`${type}-getspaceskey`, { areaId })).value;
        this.keyInfo = await callFirebase<void, KeyInfo>('images-getspaceskey');
        this.s3client = new S3Client({
            endpoint: this.keyInfo!.endpoint, // Find your endpoint in the control panel, under Settings. Prepend "https://".
            region: this.keyInfo!.region, // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
            credentials: {
                accessKeyId: this.keyInfo!.key, // Access key pair. You can create access key pairs using the control panel or API.
                secretAccessKey: this.keyInfo!.secret, // Secret access key defined through an environment variable.
            },
        });

        // Fetch a list of all files in the directory (so we can delete them)
        let _filelist = await this.s3client.send(new ListObjectsCommand({
            Bucket: this.keyInfo!.bucket,
            Prefix: `area/${areaId}/${type}/${objectId}/`,
        }));
        this.filelist = _filelist.Contents?.map(file => file.Key) as string[];

        let fileUuid = uuid.v4();

        // If resizing was requested, ImageKit it
        if (this.maxSize) {
            let response = await window.imagekit.upload({
                file: this.image,
                fileName: `${fileUuid}.${this.fileType}`,
            });
            let fetched = await fetch(`${response.url}?tr=h-${this.maxSize}`);
            this.image = await fetched.blob();
        }

        let params: PutObjectCommandInput = {
            Bucket: this.keyInfo!.bucket,// "example-space/example-folder/", // The path to the directory you want to upload the object to, starting with your Space name.
            Key: `area/${areaId}/${type}/${objectId}/${fileUuid}.${this.fileType}`, // Object key, referenced whenever you want to access this file later.
            Body: this.image, // The object's contents. This variable is an object, not a string.
            ACL: 'public-read', // Defines ACL permissions, such as private or public.
            ContentType: this.contentType,
        };

        let imageUrl = this.keyInfo!.cdnBaseUrl;
        if (!imageUrl.endsWith('/') && !params.Key!.startsWith('/')) imageUrl += '/';
        imageUrl += params.Key;

        try {
            await this.s3client.send(new PutObjectCommand(params));
        } catch (err) {
            this.status = 'error';
            console.error(err);
            return;
        }

        this.status = 'uploaded';
        return imageUrl;
    }

    async finalize() {
        if (this.status != 'uploaded') throw 'Uploader not in finalizable state.';
        this.status = 'finalizing';

        // Delete all other previously uploaded files for the same object
        if (this.filelist) {
            try {
                await this.s3client!.send(new DeleteObjectsCommand({
                    Bucket: this.keyInfo!.bucket,
                    Delete: {
                        Objects: this.filelist.map(fkey => ({ Key: fkey })),
                    }
                }));
            } catch (err) {
                // Console log the error, but failing to delete doesn't disrupt functionality, so don't do anything else
                this.status = 'error';
                console.error(err);
                return;
            }
        }

        this.status = 'finalized';
    }
}