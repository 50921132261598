<template>
    <opened-suggestion
    v-if="popup"
    @close="closePopup"
    @accept="$emit('accepted', suggestions[selectedPost])"
    @reject="rejectAndClose(selectedPost)"
    :post-id="suggestions[selectedPost].id"
    :title="suggestions[selectedPost].name"
    :description="suggestions[selectedPost].description"
    :descriptionSrc="suggestions[selectedPost].descSrc"
    :image="suggestions[selectedPost].image"
    :imageSrc="suggestions[selectedPost].imageSrc"
    ></opened-suggestion>
    <div>
        <header>
            <button @click="goBack()" class="back-btn">
                <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.944368 10.9351C0.356342 11.5186 0.352705 12.4683 0.936244 13.0564L10.4456 22.6388C11.0291 23.2268 11.9788 23.2305 12.5669 22.6469C13.1549 22.0634 13.1585 21.1136 12.575 20.5256L4.12226 12.0079L12.64 3.55518C13.228 2.97164 13.2316 2.0219 12.6481 1.43388C12.0646 0.84585 11.1148 0.842213 10.5268 1.42575L0.944368 10.9351ZM29.6054 10.6055L2.0067 10.4998L1.99521 13.4998L29.5939 13.6055L29.6054 10.6055Z" fill="black"/>
                </svg>
            </button>
            <h1>Forslag</h1>
        </header>
    </div>
    <div class="treasures">
        <div class="inner-treasures">
            <item-card v-for="(treasure, index) in suggestions" :key="treasure.id">
                <template #image>
                    <img :src="'../' + treasure.image" />
                </template>
                <template #title>{{ treasure.name }}</template>
                <template #default>Sendt inn av {{ treasure.subittedBy }}</template>
                <template #btn-slot1>
                    <button class="open-btn" @click="openPopup(index)">Åpne</button>
                </template>
                <template #btn-slot2>
                    <button class="reject-btn" @click="rejectSuggestion(index)">Avslå</button>
                </template>
            </item-card>
        </div>
    </div>
    <the-footer />
</template>

<script>
import OpenedSuggestion from "./OpenedSuggestion.vue"

export default {
    emits: ['accepted'],
    components:  {
        OpenedSuggestion,
    },
    data() {
        return {
            popup: false,
            selectedPost: null,
            suggestions: [
                {
                    id: 1,
                    name: "Bryggen i Bergen",
                    subittedBy: "Berit",
                    image: "assets/bryggen.png",
                    imageSrc: "www.bryggen.no/bilde",
                    description: "Bryggen har en lang og spennende historie",
                    descSrc: "www.bryggen.no/info"
                },
                {
                    id: 2,
                    name: "Bryggen i Bergen",
                    subittedBy: "Berit",
                    image: "assets/bryggen.png",
                    imageSrc: "www.bryggen.no/bilde",
                    description: "Bryggen har en lang og spennende historie",
                    descSrc: "www.bryggen.no/info"
                },                
                {
                    id: 3,
                    name: "Museum",
                    subittedBy: "Berit",
                    image: "assets/museum.png"
                },
                {
                    id: 4,
                    name: "Museum",
                    subittedBy: "Berit",
                    image: "assets/museum.png"
                },                {
                    id: 5,
                    name: "Bryggen i Bergen",
                    subittedBy: "Berit",
                    image: "assets/bryggen.png",
                    imageSrc: "www.bryggen.no/bilde",
                    description: "Bryggen har en lang og spennende historie",
                    descSrc: "www.bryggen.no/info"
                },
                {
                    id: 6,
                    name: "Bryggen i Bergen",
                    subittedBy: "Berit",
                    image: "assets/bryggen.png",
                    imageSrc: "www.bryggen.no/bilde",
                    description: "Bryggen har en lang og spennende historie",
                    descSrc: "www.bryggen.no/info"
                },                
                {
                    id: 7,
                    name: "Museum",
                    subittedBy: "Berit",
                    image: "assets/museum.png"
                },
                {
                    id: 8,
                    name: "Museum",
                    subittedBy: "Berit",
                    image: "assets/museum.png"
                },
            ]
        }
    },
    methods: {
        openPopup(idx) {
            this.selectedPost = idx
            // console.log(this.selectedPost)
            this.popup = true
        },
        closePopup() {
            this.popup = false
        },
        goBack() {
            history.back()
        },
        rejectSuggestion(idx) {
            this.suggestions.splice(idx, 1)
        },
        rejectAndClose(post) {
            console.log(post)
            this.rejectSuggestion(post)
            this.closePopup()
        }

    }
}
</script>


<style lang="scss" scoped>
.back-btn {
background: none;
padding: 0;
margin: 0;
}


header {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2vw;

    h1 {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0.15em;
        font-family: 'Quicksand', sans-serif;
        text-transform: uppercase;
        text-align: left;
        padding: 0 1vw;
    }

    button, a{
        background: #6d6c97;
        color: white;
        border-radius: 40px;
        border: none;
        padding: 1rem 2rem;
        font-size: 14px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 10px;
        text-decoration: none;
    }
}

.treasures {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    height: 1px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 25px;
    .inner-treasures {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;

        img {
        object-fit: cover;
        height: 125px;
        width: 200px;
        border-radius: 10px;
        }
        button {
            border-radius: 25px;
            border: none;
            padding: .75rem 2rem;
            font-size: 14px;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            cursor: pointer;
            color: white;
        }

        .open-btn {
            background: #6d6c97;
        }
        .reject-btn {
            background: #D28484;
        }

    }
}
</style>
