<template>
    <div class="wrapper">
        <h1>Slett konto</h1>
        <div class="description">
            <p>Dette vil slette din konto i Kulturjakten.</p>
            <p>
                Når du gjennomfører denne prosessen, vil kontoen din først stenges og markeres for sletting.<br />
                En uke etter dette vil kontoen slettes fullstendig, og vil ikke lenger være tilgjengelig.<br /><br />
                Venner i appen vil se en slettet konto uten noen form for identifiserende informasjon.<br />
                Informasjon om skatter og vandringer du har besøkt vil være lagret i opp til et år for å samle statistikk,
                men dette vil ikke inkludere datoer eller tidspunkter.
            </p>
        </div>
        <template v-if="user">
            <div class="description">Hvis du sletter kontoen din, kan den ikke gjenopprettes. Er du sikker på at du vil slette kontoen din?</div>
            <div class="button-container">
                <process-button v-model="deleting" :process="deleteAccount">
                    <template #default="{ processing }">
                        <template v-if="processing">Sletter konto...</template>
                        <template v-else>Ja, jeg er sikker.</template>
                    </template>
                </process-button>
            </div>
        </template>
        <template v-else>
            <div class="description">
                Hvis du ønsker å slette kontoen din, kan du enten <router-link to="?login=1">logge inn</router-link>
                for å starte prosessen selv. Dersom du ikke har tilgang til kontoen din eller ikke ønsker å logge inn,
                kan du sende en e-post til
                <a href="mailto:kulturjakten@linkutvikling.no">kulturjakten@linkutvikling.no</a>, så kan vi hjelpe deg
                videre.
            </div>
        </template>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import ProcessButton from '@/components/ProcessButton.vue'

export default defineComponent({
    name: 'DeleteUserView',
    components: {
        ProcessButton,
    },
    data: () => ({
        deleting: false,
    }),
    computed: {
        user() {
            return this.$store.state.Auth.user;
        },
    },
    methods: {
        async deleteAccount() {
            await this.$store.dispatch('Auth/deleteAccount');
            alert('Kontoen din har blitt markert for sletting. Den vil slettes innen en uke.');
        },
    },
});
</script>

<style lang="scss" scoped>
.wrapper {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .description {
        text-align: left;
    }

    button {
        border-radius: 25px;
        border: none;
        padding: .75em 1.5em;
        font-size: 14px;
        letter-spacing: 0.15em;
        cursor: pointer;
        background: #6d6c97;
        color: white;
        margin: 0 10px;

        &.processing {
            opacity: 0.6;
        }
    }
}
</style>