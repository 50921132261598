<template>
    <confirmation-box
        v-if="removingStop"
        :item="removingStop.name && removingStop.name.trim() != '' ? removingStop.name : `Stopp ${pinInfo.indexOf(removingStop)}`"
        @confirm="confirmRemoveStop"
        @close="removingStop = null"
    ></confirmation-box>
    <confirmation-box v-if="closing" @confirm="confirmClose" @close="closing = false">Er du sikker på at du vil avbryte redigering? Du har endringer du ikke har lagret.</confirmation-box>
    <message-box v-if="error" @close="error = null">{{ error }}</message-box>
    <message-box v-if="problems" @close="problems = null">
        <h3>Har du glemt noe?</h3>
        <template v-for="(problemList, index) in problems">
            <div v-if="problemList && index < pinInfo.length" class="problemList">
                <h4 v-if="index > 0">{{ pinInfo[index].name && pinInfo[index].name.trim() != '' ? pinInfo[index].name : `Stopp ${index}` }}</h4>
                <h4 v-else>{{ wandering ? 'Vandring' : 'Kulturskatt' }}</h4>
                <div v-for="problem in problemList" class="problemLine">{{ problem }}</div>
            </div>
            <div v-if="problemList && index == pinInfo.length" class="problemList">
                <h4>Spørsmål</h4>
                <div v-for="problem in problemList" class="problemLine">{{ problem }}</div>
            </div>
        </template>
    </message-box>
    <popup-shell @close="tryClose">
        <div class="popup-menu">
            <button 
                @click="selectedPinIndex = 0" 
                :class="{ selected: selectedPinIndex == 0 }"
            >{{ wandering ? 'Vandring' : 'Kulturskatt' }}</button>
            <div v-if="wandering">
                <template v-for="(pin, index) in pinInfo">
                    <button
                        v-if="index > 0 && !pin.remove"
                        :draggable="index > 1 ? true : false"
                        @dragstart="holdPin(pin)"
                        @dragend="releasePin(pin)"
                        :key="pin.name"
                        @click.self="selectedPinIndex = index" 
                        :class="{ selected: selectedPinIndex == index, unnamed: pin.name.trim().length == 0 }"
                    >{{ pin.name.trim().length > 0 ? pin.name : `Stopp ${index}` }}
                        <button @click="removeStop(pin)" v-if="index > 1 && pinInfo.filter(pin => !pin.remove).length > 3 && !saving">✖</button>
                    </button>
                </template>
            </div>
            <button 
                class="new-stop-button" 
                v-if="wandering && !saving" 
                @click="addStop"
            >+ Nytt stopp</button>
            <button 
                @click="selectedPinIndex = -1" 
                :class="{ selected: selectedPinIndex == -1 }"
            >Spørsmål</button>
        </div>
        <div class="new-post-container" >
            <h4 v-if="selectedPinIndex === -1">Spørsmål</h4>
            <h4 v-else>
                <span v-if="pin">Endre </span><span v-else>Legg til </span> 
                <span v-if="wandering">vandring</span><span v-else>kulturskatt</span>
            </h4>
            <language-btns v-model="selectedLanguage"></language-btns>
            <div class="form" v-if="selectedPinIndex >= 0">
                <div class="input-div">
                    <input type="text" :placeholder="trPlaceholder('Tittel')" v-model="trName" :disabled="saving" />
                    <info-box helpType="title" :what="wanderingOrTreasure" />
                </div>
                <div class="category" v-if="!wandering">
                    <template v-for="category in categories">
                        <div class="category-option" v-if="category.iconName != 'adventure'" @click="selectCategory(category)">
                            <img :src="$store.getters['PinCategories/assetUrl'](category)" :class="{ selected: category.id == selectedCategory }" />
                            <span>{{ category.name }}</span>
                        </div>
                    </template>
                </div>
                <div class="input-div">
                    <h5 v-if="!wandering">Beskrivelser</h5>
                    <p class="description-explanation" v-if="!wandering">
                        For å gjøre appen interessant for brukere over lang tid anbefaler vi at dere legger inn minst 3 forskjellige beskrivelser
                        per skatt, som vil rullere i appen. Hver beskrivelse skal ha sine egne spørsmål, som defineres under "Spørsmål".
                    </p>
                    <index-selector v-if="!wandering" v-model="selectedDescriptionIndex" :max="mainPin.descriptions.length" allowAdd :allowDelete="trDescriptions.length > 1" @add="addDescription" @delete="removeDescription($event)" :disabled="saving" />
                    <textarea :disabled="saving" class="description" :placeholder="trPlaceholder('Beskrivelse')" rows="6" :value="trDescriptions[selectedDescriptionIndex]" @input="changeDescription(selectedDescriptionIndex, $event.target.value)" ></textarea>
                    <info-box v-if="wandering" helpType="description" :what="wanderingOrTreasure" />
                    <info-box v-else info="Her kan du skrive inn en eller flere beskrivelser. Du kan skrive spørsmål til hver beskrivelse, og i appen vil en beskrivelse og tilhørende spørsmål vises." />
                </div>
                <div class="input-div">
                    <textarea type="text" :placeholder="trPlaceholder('Kilder')" :value="trSources[selectedDescriptionIndex]" @input="changeSource(selectedDescriptionIndex, $event.target.value)" rows="1" :disabled="saving"></textarea>
                    <info-box helpType="sources" :what="wanderingOrTreasure" />
                </div>
                <div v-if="!wandering || selectedPinIndex > 0">
                    <div class="input-div">
                        <input type="text" :placeholder="trPlaceholder('Adresse')" v-model="address" @input="addressChanged(selectedPin, $event.target.value)" @blur="addressLookup(selectedPin)" @keydown.enter.prevent :disabled="saving" />
                        <info-box helpType="address" :what="wanderingOrTreasure" />
                    </div>
                    <GoogleMap v-model="location" :disabled="saving" />
                </div>
                <div v-if="wandering && selectedPinIndex == 0">
                    <div class="input-div">
                        <input type="text" :placeholder="trPlaceholder('Tidsestimat')" v-model="trWanderingEstimatedTime" :disabled="saving" />
                        <info-box info="Her skriver du omtrent hvor lang tid vandringen tar." />
                    </div>
                </div>
                <div class="input-div">
                    <label class="upload-image-div">
                        <input type="file" name="image-file-input" @change="imageSelected" accept="image/*" :disabled="saving" />
                        <input type="text" :placeholder="trPlaceholder('Last opp bilde')" disabled :value="selectedPin.selectedImageName" />
                        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.21387 1.27539L7.58435 5.18015L1.21387 9.08492" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </label>
                    <info-box helpType="uploadImg" :what="wanderingOrTreasure" />
                </div>
                <div v-if="selectedPin.displayImageUrl">
                    <img class="preview" :src="selectedPin.displayImageUrl" />
                </div>
                <div class="input-div">
                    <textarea :placeholder="trPlaceholder('Bildebeskrivelse')" rows="1" v-model="trImageDescription" :disabled="saving"></textarea>
                    <info-box info="Her skriver du inn litt informasjon om bildet." />
                </div>
                <div>
                    <div class="input-div">
                        <input type="text" placeholder="Link" v-model="link" :disabled="saving" />
                        <info-box info="Her kan du skrive inn en link til mer informasjon, hvis en finnes." />
                    </div>
                </div>
                <div class="btns">
                    <process-button class="save-button" v-model="saving" :process="submitForm"><template v-slot="{ processing }">{{ processing ? 'Lagrer...' : 'Lagre' }}</template></process-button>
                    <button class="save-button unpublish-button" v-if="published" @click="unpublish">Avpubliser</button>
                    <process-button class="save-button publish-button" v-model="saving" v-else :process="publish" @error="onPublishError"><template v-slot="{ processing }">{{ processing ? 'Lagrer' : 'Lagre og publiser' }}</template></process-button>
                </div>
            </div>
            <questions-component 
                v-if="selectedPinIndex === -1"
                v-model="questions" 
                :descriptions="trDescriptions"
                :wandering="wandering"
                :defaultLang="defaultLang"
                :lang="selectedLanguage"
                :disabled="saving"
                @addQuestion="addQuestion" 
                @removeQuestion="removeQuestion" 
                @addAnswer="addAnswer" 
                @removeAnswer="removeAnswer"
            >
                <template #button-slot>
                    <div class="btns">
                        <process-button class="save-button" v-model="saving" :process="submitForm"><template v-slot="{ processing }">{{ processing ? 'Lagrer...' : 'Lagre' }}</template></process-button>
                        <button class="save-button unpublish-button" v-if="published" @click="unpublish">Avpubliser</button>
                        <process-button class="save-button publish-button" v-model="saving" v-else :process="publish" @error="onPublishError"><template v-slot="{ processing }">{{ processing ? 'Lagrer' : 'Lagre og publiser' }}</template></process-button>
                    </div>
                </template>
            </questions-component>
        </div>
    </popup-shell>
</template>

<script lang="js">
import { GeoPoint } from 'firebase/firestore'
import C from '@/const'
import Pin from '@/types/Pin'
import hasChanged from '@/util/hasChanged'
import QuestionsComponent from "@/components/QuestionsComponent.vue"
import deepClone from '@/util/deepClone'
import ConfirmationBox from "@/components/ConfirmationBox.vue"
import MessageBox from '@/components/MessageBox.vue'
import ProcessButton from '@/components/ProcessButton.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import InfoBox from '@/components/InfoBox.vue'
import IndexSelector from '@/components/IndexSelector.vue'
import { toRaw } from 'vue'

// TODO Find a more resilient way to do this
const placeholders = {
    en: {
        'Tittel': 'Title',
        'Beskrivelse': 'Description',
        'Adresse': 'Address',
        'Tidsestimat': 'Estimated time',
        'Last opp bilde': 'Upload image',
        'Link til bilde': 'Link to image',
        'Bildebeskrivelse': 'Image description',
        'Kilder': 'Sources',
    },
}

class TrPromise extends Promise {
    toString() {
        return 'Oversetter...'
    }
}

// image: Apparently not used, other than being set right before being saved? Test removing
// imageUrl: Final form URL. For text URLs, contain the URL; while image file selected, is empty
// displayImageUrl: Either final form or data URL, used to display image for preview

export default {
    components: {
        QuestionsComponent,
        ConfirmationBox,
        MessageBox,
        ProcessButton,
        GoogleMap,
        InfoBox,
        IndexSelector,
    },
    emits: ['submit', 'close'],
    props: {
        pin: {
            type: Pin,
            required: false,
        },
        wanderingPins: {
            type: Array,
            required: false,
        },
        wandering: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            pinInfo: [],
            questions: [],

            originalPinInfo: [],
            originalQuestions: [],

            addressChangedTimeout: null,

            selectedLanguage: null,
            selectedPinIndex: 0,
            selectedDescriptionIndex: 0,

            removingStop: null,
            closing: false,
            error: null,
            problems: null,
            saving: false,

            activeDragPin: null,
            oldPins: [],

            currentDragPinPosition: null,
            originalDragPinPosition: null,

            onMouseMove: null,
        };
    },
    computed: {
        categories() {
            return this.$store.state.PinCategories.categories;
        },
        wanderingOrTreasure() {
            return this.wandering ? this.selectedPinIndex == 0 ? 'vandringen' : 'stoppet' : 'kulturskatten';
        },
        defaultLang() {
            return this.$store.state.Languages.languages.filter(lang => lang.key == this.$store.state.Areas.selectedArea.area.defaultLang)[0];
        },
        selectedPin() {
            return this.pinInfo[this.selectedPinIndex];
        },
        mainPin() {
            return this.pinInfo[0];
        },
        published() {
            return this.pin && this.$store.state.Pins.pinsById[this.pin.id].published;
        },
        hasChanged() {
            return hasChanged(this.originalPinInfo, this.pinInfo) || hasChanged(this.originalQuestions, this.questions);
        },
        selectedCategory() {
            return this.selectedPin.category;
        },
        location: {
            get() {
                return { lat: this.selectedPin.location[0], lng: this.selectedPin.location[1], };
            },
            set(val) {
                this.selectedPin.location = [val.lat, val.lng];
            },
        },
        address: {
            get() {
                return this.selectedPin.address;
            },
            set(val) {
                this.selectedPin.address = val;
            },
        },
        link: {
            get() {
                return this.selectedPin.link;
            },
            set(val) {
                this.selectedPin.link = val;
            },
        },
        trDescriptions() {
            let pin = this.selectedPin ?? this.mainPin;
            if(this.selectedLanguage == this.defaultLang) return pin.descriptions ?? [];
            return pin.tr[this.selectedLanguage?.key]?.descriptions ?? [];
        },
        trSources() {
            let pin = this.selectedPin ?? this.mainPin;
            if(this.selectedLanguage == this.defaultLang) return pin.sources ?? [];
            return pin.tr[this.selectedLanguage?.key]?.sources ?? [];
        },
        trName: {
            get() {
                if (this.selectedLanguage == this.defaultLang) return this.selectedPin.name;
                return this.selectedPin.tr[this.selectedLanguage?.key]?.name ?? '';
            },
            set(val) {
                if (this.selectedLanguage == this.defaultLang) this.selectedPin.name = val;
                else this.selectedPin.tr[this.selectedLanguage.key] = Object.assign(this.selectedPin.tr[this.selectedLanguage.key] ?? {}, { name: val });
            },
        },
        trImageDescription: {
            get() {
                if (this.selectedLanguage == this.defaultLang) return this.selectedPin.imageDescription;
                return this.selectedPin.tr[this.selectedLanguage?.key]?.imageDescription ?? '';
            },
            set(val) {
                if (this.selectedLanguage == this.defaultLang) this.selectedPin.imageDescription = val;
                else this.selectedPin.tr[this.selectedLanguage.key] = Object.assign(this.selectedPin.tr[this.selectedLanguage.key] ?? {}, { imageDescription: val });
            },
        },
        trWanderingEstimatedTime: {
            get() {
                if (this.selectedLanguage == this.defaultLang) return this.selectedPin.wanderingEstimatedTime;
                return this.selectedPin.tr[this.selectedLanguage?.key]?.wanderingEstimatedTime ?? '';
            },
            set(val) {
                if (this.selectedLanguage == this.defaultLang) this.selectedPin.wanderingEstimatedTime = val;
                else this.selectedPin.tr[this.selectedLanguage.key] = Object.assign(this.selectedPin.tr[this.selectedLanguage.key] ?? {}, { wanderingEstimatedTime: val });
            },
        },
    },
    methods: {
        pinMove() {
            const offsetY = this.currentDragPinPosition.y - this.originalDragPinPosition.y

            const pixelInIndex = 70

            const indexChange = Math.round(offsetY / pixelInIndex)

            const dragStartIndex = this.oldPins.findIndex(
                (pin, index) => (pin.id === this.activeDragPin.id && index !== 0)
            )
            
            console.log(dragStartIndex)
            if (dragStartIndex === -1) return console.error('Could not find pin in oldPins')
            if (dragStartIndex < 1) return
            
            const newIndex = Math.min(Math.max(dragStartIndex + indexChange, 2), this.oldPins.length - 1)
            
            this.dragCurrentIndex = newIndex

            this.pinInfo = this.oldPins.map((pin, index) => {
                if (index === dragStartIndex) return this.oldPins[newIndex]
                if (index === newIndex) return this.oldPins[dragStartIndex]
                return pin
            })

            console.log(newIndex)
        },

        holdPin(pinInfo) {
            console.log("new pins", this.pinInfo)
            this.activeDragPin = pinInfo
            this.oldPins = deepClone(this.pinInfo)
        },

        releasePin() {
            this.activeDragPin = null
            this.originalDragPinPosition = null
        },

        trPlaceholder(ph) {
            let lang = this.selectedLanguage.key;
            if (!(lang in placeholders)) return ph;
            return placeholders[lang][ph] ?? ph;
        },
        tryClose() {
            if (!this.hasChanged) return this.confirmClose();
            this.closing = true;
        },
        confirmClose() {
            this.closing = false;
            this.$emit('close');
        },
        addStop() {
            this.pinInfo.push({
                name: '',
                descriptions: [''],
                address: '',
                location: [0, 0],
                sources: [''],
                image: '',
                imageDescription: '',
                link: '',
                category: this.$store.state.PinCategories.categories.filter(cat => cat.iconName == 'adventure')[0].id,
                tr: {},

                originalAddress: '',
                imageUrl: null,
                displayImageUrl: null,
                selectedImage: null,
                selectedImageName: null,
            });
            this.selectedPinIndex = this.pinInfo.length - 1;
        },
        removeStop(stop) {
            this.removingStop = stop;
        },
        confirmRemoveStop() {
            if (this.pinInfo[this.selectedPinIndex] == this.removingStop) this.selectedPinIndex = 0;
            this.removingStop.remove = true;
            this.removingStop = null;
        },
        selectCategory(category) {
            if (this.saving) return;
            this.selectedPin.category = category.id;
        },
        imageSelected(event) {
            this.selectedPin.selectedImage = event.target.files[0];
            this.selectedPin.selectedImageName = event.target.files[0].name;

            let reader = new FileReader();
            reader.addEventListener('load', () => {
                //this.selectedPin.imageUrl = reader.result;
                this.selectedPin.imageUrl = null;
                this.selectedPin.displayImageUrl = reader.result;
                event.target.value = '';
            });
            reader.readAsDataURL(event.target.files[0]);
        },
        imageUrlChanged(event) {
            this.selectedPin.imageUrl = event.target.value;
            this.selectedPin.displayImageUrl = event.target.value;
            this.selectedPin.selectedImage = null;
            this.selectedPin.selectedImageName = null;
        },
        addressChanged(pin, address) {
            clearTimeout(this.addressChangedTimeout);
            this.addressChangedTimeout = setTimeout(() => this.addressLookup(pin, address), 1200);
        },
        async addressLookup(pin, address) {
            if (!this.addressChangedTimeout) return;
            if (!address) address = pin.address;
            if (address == '') return;
            clearTimeout(this.addressChangedTimeout);
            this.addressChangedTimeout = null;
            try {
                let geocode = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(pin.address).replace(/\+/g, '%2B')}&key=${C.googleMapsApiKey}`);
                let json = await geocode.json();
                if (json.status != 'OK') throw 'error';
                if (json.results.length == 0) throw 'no_results';
                pin.location = [json.results[0].geometry.location.lat, json.results[0].geometry.location.lng];
            } catch (e) {
                console.error(e);
                this.error = `Fant ikke adresse: ${pin.address}`;
                return;
            }
        },
        addDescription() {
            // Add a description and a corresponding source field
            this.mainPin.descriptions.push('');
            this.mainPin.sources.push('');
            for (let langKey in this.mainPin.tr) {
                if (this.mainPin.tr[langKey].descriptions) {
                    this.mainPin.tr[langKey].descriptions.push('');
                }
                if (this.mainPin.tr[langKey].sources) {
                    this.mainPin.tr[langKey].sources.push('');
                }
            }

            // Add a question to the new description
            this.addQuestion(this.mainPin.descriptions.length - 1);

            this.selectedDescriptionIndex = this.mainPin.descriptions.length - 1;
        },
        changeDescription(index, val) {
            let pin = this.selectedPin ?? this.mainPin;
            if (this.selectedLanguage == this.defaultLang) pin.descriptions[index] = val;
            else {
                if (!pin.tr[this.selectedLanguage.key]) pin.tr[this.selectedLanguage.key] = {};
                if (!pin.tr[this.selectedLanguage.key].descriptions) pin.tr[this.selectedLanguage.key].descriptions = deepClone(pin.descriptions);
                pin.tr[this.selectedLanguage.key].descriptions[index] = val;
            }
        },
        changeSource(index, val) {
            let pin = this.selectedPin ?? this.mainPin;
            if (this.selectedLanguage == this.defaultLang) pin.sources[index] = val;
            else {
                if (!pin.tr[this.selectedLanguage.key]) pin.tr[this.selectedLanguage.key] = {};
                if (!pin.tr[this.selectedLanguage.key].sources) pin.tr[this.selectedLanguage.key].sources = deepClone(pin.sources);
                pin.tr[this.selectedLanguage.key].sources[index] = val;
            }
        },
        removeDescription(index) {
            // Remove all questions for this description
            // Start by replacing all translated versions of the question with null (to preserve indexes)
            for (let i in this.questions) {
                let q = this.questions[i]
                if (q.description < index) continue;
                if (q.description > index) {
                    // While we're working on the list, decrement all higher indexes
                    --q.description;
                    continue;
                }
                for (let lang in this.mainPin.tr) {
                    if (!this.mainPin.tr[lang].questions) continue;
                    this.mainPin.tr[lang].questions[i] = null;
                }
                this.questions[i] = null;
            }

            // Then remove all null questions
            this.questions = this.questions.filter(q => q);
            for (let lang in this.mainPin.tr) {
                if (this.mainPin.tr[lang].questions) this.mainPin.tr[lang].questions = this.mainPin.tr[lang].questions.filter(q => q);
            }

            // Remove the description and corresponding source
            this.mainPin.descriptions.splice(index, 1);
            this.mainPin.sources.splice(index, 1);
            for (let langKey in this.mainPin.tr) {
                if (this.mainPin.tr[langKey].descriptions) {
                    this.mainPin.tr[langKey].descriptions.splice(index, 1);
                }
                if (this.mainPin.tr[langKey].sources) {
                    this.mainPin.tr[langKey].sources.splice(index, 1);
                }
            }

            if (index <= this.selectedDescriptionIndex) this.selectedDescriptionIndex--;
        },
        addQuestion(descIndex) {
            const q = { question: '', answers: ['', ''], description: descIndex, correctAnswers: [0], tr: {}, };
            for (let langKey in this.mainPin.tr) {
                q.tr[langKey] = { question: '', answers: ['', ''] }
            }
            this.questions.push(q);
        },
        removeQuestion(index) {
            this.questions.splice(index, 1);
        },
        addAnswer(questionIndex) {
            this.questions[questionIndex].answers.push('');
            for (let langKey in this.questions[questionIndex].tr) {
                this.questions[questionIndex].tr[langKey].answers.push('');
            }
        },
        removeAnswer({questionIndex, index}) {
            this.questions[questionIndex].answers.splice(index, 1);
            for (let langKey in this.questions[questionIndex].tr) {
                this.questions[questionIndex].tr[langKey].answers.splice(index, 1);
            }
        },
        validationProblems() {
            // Every pin, whether treasure or adventure, must have:
            // - name (lang: use default)
            // - description (1 or more) (lang: autotranslate)
            // - image
            // (due to the data structure used for editing adventures, the adventure itself is "pin 0" and has these fields)

            // Treasure pins must have:
            // - category
        
            // Each question must have at least two answers, at least one of which has to be correct. Questions and
            // answers that are not filled in for a language will be autotranslated.

            const problems = [];

            for (let i in this.pinInfo) {
                const pin = this.pinInfo[i];

                if (pin.remove) continue;
                const pinProblems = [];

                if (i == 0 && !this.wandering) {
                    // Check for a category
                    // This should - in theory - never be a problem, but in practice might become an issue for bad imports
                    if (!(pin.category && pin.category.trim().length > 0 && this.$store.state.PinCategories.byId[pin.category])) {
                        pinProblems.push('Du må velge en kategori.');
                    }
                }

                if (!pin.displayImageUrl) pinProblems.push('Du må velge et bilde.');
                if (!pin.name || pin.name == '') pinProblems.push('Du må skrive inn et navn.');
                if (!pin.descriptions || pin.descriptions.length == 0) pinProblems.push('Du må skrive inn en beskrivelse.');
                if (pin.descriptions && pin.descriptions.filter(desc => desc.trim().length == 0).length > 0) pinProblems.push(`Du må fylle inn ${pin.descriptions.length == 1 ? 'en beskrivelse' : 'alle beskrivelser'}.`);
                if (pinProblems.length > 0) problems[i] = pinProblems;
            }

            const questionProblems = [];

            let incompleteQuestion = false;
            for (let qIdx in this.questions) {
                if (!this.questions[qIdx].question || this.questions[qIdx].question == '') incompleteQuestion = true;
                if (this.questions[qIdx].correctAnswers.length == 0) incompleteQuestion = true;
                for (let aIdx in this.questions[qIdx].answers) {
                    if (!this.questions[qIdx].answers[aIdx] || this.questions[qIdx].answers[aIdx] == '') incompleteQuestion = true;
                }
            }
            if (incompleteQuestion) questionProblems.push('Du må fylle inn alle spørsmål og svar.');

            if (questionProblems.length > 0) problems[this.pinInfo.length] = questionProblems;

            if (problems.length == 0) return null;
            return problems;
        },
        async translateOne(lang, val) {
            if (!val || val.trim() == '') return '';
            const translated = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${C.googleMapsApiKey}`, {
                method: 'POST',
                body: JSON.stringify({
                    q: val,
                    source: this.defaultLang.key,
                    target: lang,
                    format: 'text',
                }),
            });
            const response = await translated.json();
            return response.data.translations[0].translatedText;
        },
        async awaitDeep(obj) {
            const promises = [];
            for (let k in obj) {
                if (obj[k] instanceof Promise) promises.push(obj[k].then(val => { obj[k] = val }));
                else if (obj[k] === null) continue;
                else if (typeof obj[k] == 'object') promises.push(this.awaitDeep(obj[k]));
            }
            await Promise.all(promises);
        },
        async ensureTranslated() {
            const defaultLang = this.defaultLang.key;
            const otherLangs = this.$store.state.Languages.languages.filter(lang => lang.key != defaultLang).map(lang => lang.key);

            for (let i in this.pinInfo) {
                const pin = this.pinInfo[i];
                if (!pin.tr) pin.tr = {};
                for (let lang of otherLangs) {
                    if (!(lang in pin.tr)) pin.tr[lang] = {};
                    if (!pin.tr[lang].name || pin.tr[lang].name.trim() == '') pin.tr[lang].name = pin.name;
                    if (!pin.tr[lang].imageDescription || pin.tr[lang].imageDescription.trim() == '') pin.tr[lang].imageDescription = new TrPromise(res => this.translateOne(lang, pin.imageDescription).then(res));
                    if (!pin.tr[lang].sources) pin.tr[lang].sources = pin.sources.map(src => '');
                    for (let s = 0; s < pin.sources.length; ++s) {
                        if (!pin.tr[lang].sources[s] || pin.tr[lang].sources[s].trim() == '') {
                            const promise = new TrPromise(res => this.translateOne(lang, pin.sources[s]).then(res));
                            pin.tr[lang].sources[s] = promise;
                        }
                    }
                    if (!pin.tr[lang].descriptions) pin.tr[lang].descriptions = pin.descriptions.map(desc => ''); // Array of empty descriptions
                    for (let d = 0; d < pin.descriptions.length; ++d) {
                        if (!pin.tr[lang].descriptions[d] || pin.tr[lang].descriptions[d].trim() == '') {
                            const promise = new TrPromise(res => this.translateOne(lang, pin.descriptions[d]).then(res));
                            pin.tr[lang].descriptions[d] = promise;
                        }
                    }
                }
            }

            for (let i in this.questions) {
                const question = this.questions[i];
                if (!question.tr) question.tr = {};
                for (let lang of otherLangs) {
                    if (!(lang in question.tr)) question.tr[lang] = {};
                    if (!question.tr[lang].question || question.tr[lang].question.trim() == '') {
                        const promise = new TrPromise(res => this.translateOne(lang, question.question).then(res));
                        question.tr[lang].question = promise;
                    }
                    if (!question.tr[lang].answers) question.tr[lang].answers = question.answers.map(answer => '');
                    for (let a = 0; a < question.answers.length; ++a) {
                        if (!question.tr[lang].answers[a] || question.tr[lang].answers[a].trim() == '') {
                            const promise = new TrPromise(res => this.translateOne(lang, question.answers[a]).then(res));
                            question.tr[lang].answers[a] = promise;
                        }
                    }
                }
            }

            await this.awaitDeep([this.pinInfo, this.questions]);
        },
        async submitForm(close=true) {
            let problems = this.validationProblems();
            if (problems) {
                this.problems = problems;
                return;
            }

            await this.ensureTranslated();

            if (!this.hasChanged) {
                if (close) this.$emit('close');
                return;
            }

            if (this.addressChangedTimeout) await this.addressLookup(this.selectedPin);
            let pinsToSave = deepClone(this.pinInfo);

            if (this.wandering) {
                let mainPin = pinsToSave.shift();
                pinsToSave[0].wanderingName = mainPin.name;
                pinsToSave[0].wanderingDescription = (mainPin.descriptions ?? [])[0];
                pinsToSave[0].selectedWanderingImage = mainPin.selectedImage;
                pinsToSave[0].wanderingSources = (mainPin.sources ?? [])[0] ?? '';
                pinsToSave[0].wanderingEstimatedTime = mainPin.wanderingEstimatedTime ?? '';
                pinsToSave[0].wanderingImageDescription = mainPin.imageDescription ?? '';

                if (mainPin.imageUrl) {
                    pinsToSave[0].wanderingImage = mainPin.imageUrl;
                }

                if (mainPin.address != mainPin.originalAddress) {
                    pinsToSave[0].address = mainPin.address;
                }

                if (mainPin.tr) {
                    if (!pinsToSave[0].tr) pinsToSave[0].tr = {};
                    for (let lang in mainPin.tr) {
                        if (!pinsToSave[0].tr[lang]) pinsToSave[0].tr[lang] = {};
                        pinsToSave[0].tr[lang].wanderingName = mainPin.tr[lang].name;
                        pinsToSave[0].tr[lang].wanderingDescription = (mainPin.tr[lang].descriptions ?? [])[0] ?? '';
                        pinsToSave[0].tr[lang].wanderingSources = (mainPin.tr[lang].sources ?? [])[0] ?? '';
                        pinsToSave[0].tr[lang].wanderingEstimatedTime = mainPin.tr[lang].wanderingEstimatedTime ?? '';
                        pinsToSave[0].tr[lang].wanderingImageDescription = mainPin.tr[lang].imageDescription ?? '';
                    }
                }
            }

            pinsToSave[0].questions = this.questions;

            let pinsToRemove = pinsToSave.filter(pin => pin.id && pin.remove);
            pinsToSave = pinsToSave.filter(pin => !pin.remove);

            for (let pin of pinsToSave) {
                // Convert all locations into Parse geopoints
                pin.location = new GeoPoint(pin.location[0], pin.location[1]);
            }

            for (let pin of pinsToSave) {
                // Remove all invalid fields from the data to save
                delete pin.published;
                delete pin.originalAddress;
                delete pin.displayImageUrl;
                delete pin.selectedImageName;
                delete pin.remove;

                pin.image = pin.imageUrl;
                delete pin.imageUrl;
                if (!pin.image) delete pin.image;
            }

            let changedChildPinList = false;

            if (this.wandering && !pinsToSave[0].id) {
                // If this is a new wandering, save the main pin first to ensure everything can refer to it
                let pin = await this.$store.dispatch('Pins/createPin', pinsToSave[0]);
                pinsToSave[0].id = pin.id;
                this.mainPin.id = pin.id; // Teporary workaround to allow save-and-publish: put the ID in the main pin object
            }

            try {
                await Promise.all(pinsToSave.map(async (pin, index) => {
                    if (pin.id) {
                        await this.$store.dispatch('Pins/updatePin', pin);
                    }
                    else {
                        if (this.wandering && pin != pinsToSave[0]) {
                            pin.wanderingParent = pinsToSave[0].id;
                        }
                        changedChildPinList = true;
                        let newPin = await this.$store.dispatch('Pins/createPin', pin);
                        pin.id = newPin.id;
                        if (this.published) await this.$store.dispatch('Pins/publishPin', [pin.id]); // Previously saved children are automatically published; new ones are not
                        if (index == 0) this.mainPin.id = newPin.id; // Teporary workaround to allow save-and-publish: put the ID in the main pin object
                    }
                }));
            } catch (e) {
                console.error(e);
                this.error = e.message; // TODO Parse message and show a better one?
                return;
            }

            if (pinsToRemove.length > 0) changedChildPinList = true;

            if (this.wandering && changedChildPinList) {
                let wanderingChildren = deepClone(pinsToSave);
                let mainPin = wanderingChildren.shift();
                mainPin.wanderingChildren = wanderingChildren.map(child => child.id);
                try {
                    await this.$store.dispatch('Pins/updatePin', mainPin);
                } catch (e) {
                    this.error = e.message; // TODO Parse message and show a better one?
                    return;
                }
            }

            try {
                await Promise.all(pinsToRemove.map(async pin => {
                    await this.$store.dispatch('Pins/deletePin', pin);
                }));
            } catch (e) {
                this.error = e.message; // TODO Parse message and show a better one?
                return;
            }

            // The logic above depends on data being a copy of what was already a copy, to manipulate in place without
            // altering the original or the first copy. Not closing the window leaves "zombie data" in place, which
            // breaks the editing process.
            if (close) this.$emit('close');
        },
        async publish() {
            await this.submitForm(false);
            if (this.problems) return;
            // Publishing the main pin will publish all saved child pins as well, on the backend
            await this.$store.dispatch('Pins/publishPin', this.pinInfo.map(pin => pin.id));
            this.$emit('close');
        },
        unpublish() {
            return this.$store.dispatch('Pins/unpublishPin', this.pinInfo.map(pin => pin.id));
        },
        onPublishError(error) {
            switch(error) {
                case 'limit_reached':
                    this.error = `Du har nådd grensen for hvor mange ${this.wandering ? 'vandringer' : 'skatter'} du kan publisere i din sone. For å øke grensen, ta kontakt med Link Utvikling.`
                    break;
                default:
                    this.error = error;
                    break;
            }
        }
    },
    watch: {
        selectedPinIndex(next, prev) {
            if (this.addressChangedTimeout) this.addressLookup(this.pinInfo[prev]);
        },
    },
    beforeMount() {
        this.selectedLanguage = this.defaultLang;
        if (this.pin) {
            let pin0 = {};
            pin0.id = this.pin.id;
            pin0.published = this.pin.published;
            pin0.name = this.wandering ? this.pin.wanderingName : this.pin.name;
            pin0.descriptions = this.wandering ? [this.pin.wanderingDescription] : deepClone(this.pin.descriptions);
            pin0.address = this.pin.address;
            pin0.location = [this.pin.location.latitude, this.pin.location.longitude];
            pin0.sources = this.wandering ? [this.pin.wanderingSources] : this.pin.sources;
            pin0.image = this.wandering ? this.pin.wanderingImage : this.pin.image;
            pin0.imageDescription = (this.wandering ? this.pin.wanderingImageDescription : this.pin.imageDescription) ?? '';
            pin0.wanderingEstimatedTime = this.pin.wanderingEstimatedTime;
            //pin0.category = this.$store.state.PinCategories.categories.filter(cat => cat.id == this.pin.category.id)[0],
            pin0.category = this.pin.category;
            pin0.link = this.pin.link ?? '';
            pin0.tr = deepClone(this.pin.tr);

            if (this.wandering && pin0.tr) {
                for (let lang in pin0.tr) {
                    pin0.tr[lang].name = pin0.tr[lang].wanderingName;
                    pin0.tr[lang].descriptions = [pin0.tr[lang].wanderingDescription];
                    pin0.tr[lang].sources = [pin0.tr[lang].wanderingSources];
                    pin0.tr[lang].imageDescription = pin0.tr[lang].wanderingImageDescription;
                }
            }

            pin0.originalAddress = this.pin.address;
            pin0.imageUrl = this.wandering ? this.pin.wanderingImage : this.pin.image;
            pin0.displayImageUrl = pin0.imageUrl;
            pin0.selectedImage = null;
            pin0.selectedImageName = null;

            this.pinInfo.push(pin0);

            if (this.wanderingPins) {
                for (let wp of this.wanderingPins) {
                    let pin = {};
                    pin.id = wp.id;
                    pin.name = wp.name;
                    pin.descriptions = deepClone(wp.descriptions);
                    pin.address = wp.address;
                    pin.location = [wp.location.latitude, wp.location.longitude];
                    pin.sources = wp.sources;
                    pin.image = wp.image;
                    pin.imageDescription = wp.imageDescription;
                    pin.category = this.$store.state.PinCategories.categories.filter(cat => cat.iconName == 'adventure')[0].id;
                    pin.link = wp.link;
                    pin.tr = deepClone(wp.tr);

                    pin.originalAddress = wp.address;
                    pin.imageUrl = wp.image;
                    pin.displayImageUrl = pin.imageUrl;
                    pin.selectedImage = null;
                    pin.selectedImageName = null;

                    this.pinInfo.push(pin);
                }
            }

            //this.questions = deepClone(this.pin.questions);
            this.questions = [];
            this.originalQuestions = [];
            this.$store.dispatch('Pins/loadQuestions', this.pin).then(questionsDoc => {
                if (!questionsDoc) return;
                this.questions = questionsDoc.questions;
                this.originalQuestions = deepClone(this.questions);
            });
        } else {
            if (this.wandering) {
                for (let i = 0; i < 3; ++i) {
                    this.pinInfo.push({
                        name: '',
                        descriptions: [''],
                        address: '',
                        location: [0, 0],
                        sources: [''],
                        image: '',
                        imageDescription: '',
                        category: this.$store.state.PinCategories.categories.filter(cat => cat.iconName == 'adventure')[0].id,
                        link: '',
                        tr: {},

                        originalAddress: '',
                        imageUrl: null,
                        displayImageUrl: null,
                        selectedImage: null,
                        selectedImageName: null,
                    });
                }
            } else {
                this.pinInfo.push({
                    name: '',
                    descriptions: [''],
                    address: '',
                    location: [0, 0],
                    sources: [''],
                    image: '',
                    imageDescription: '',
                    category: this.$store.state.PinCategories.categories.filter(cat => cat.iconName == 'attraction')[0].id,
                    link: '',
                    tr: {},

                    originalAddress: '',
                    imageUrl: null,
                    displayImageUrl: null,
                    selectedImage: null,
                    selectedImageName: null,
                });
            }

            this.questions = [];
            this.originalQuestions = [];
        }

        this.originalPinInfo = deepClone(this.pinInfo);
    },

    mounted() {
        this.onMouseMove = (e) => {
            //console.log("onMouseMove", e.clientX, e.clientY)

            this.currentDragPinPosition = {
                x: e.clientX,
                y: e.clientY
            }

            if (this.activeDragPin) {
                this.pinMove(this.activeDragPin)
            }
        }

        
        window.addEventListener("dragstart", (e) => {
            //console.log("dragstart", e.target)
            this.originalDragPinPosition = {
                x: e.clientX,
                y: e.clientY
            }
        })

        window.addEventListener("dragover", this.onMouseMove);
    },

    beforeUnmount() {
        window.removeEventListener("dragover", this.onMouseMove);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.problemList {
    text-align: left;

    h4 {
        margin-top: 10px;
    }

    .problemLine {
        margin-left: 20px;
    }
}

.description-wrapper {
    position: relative;

    .description {
        min-height: 128px;
    }

    .remove-description {
        background: none;
        border: none;
        position: absolute;
        top: 5px;
        right: 15px;
        color: red;
        font-size: 1.3em;
    }
}

.add-button {
    border: none;
    padding: 1rem;
    margin: auto;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: none;
    cursor: pointer;
    background: #F28251;
    color: white;
    border-radius: 10px;
    width: 250px;
}

.popup-menu {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    overflow-y: auto;
    box-sizing: border-box;
    height: 85vh;
    /* test */
    width: 10vw;
    min-width: 100px;
    background: /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
    /* /test */

    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    button {
        padding: 10px;
        border-radius: 10px;
        box-sizing: border-box;
        background: none;
        position: relative;
        cursor: pointer;

        button {
            font-size: 12px;
            border: none;
            background: red;
            border-radius: 100%;
            padding: 0;
            width: 20px;
            height: 20px;
            color: white;
            position: absolute;
            left: -5px;
            top:0;
            transform: translateY(-50%);
        }
    }
    .selected {
        background: #6d6c97;
        border: solid 1px #6d6c97;
        color: white;
        font-weight: bold;
    }

    .unnamed {
        font-style: italic;
    }
}

.new-post-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* justify-content: space-between; */
    padding: 5px 10% 0 10%;
    position: relative;
    width: 50vw;
    height: 85vh;
    min-width: 300px;
    overflow-y: auto;
    overflow-x: hidden;

    .category {
        display: flex;
        gap: 10px;
        justify-content: center;

        .category-option {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90px;
            cursor: pointer;

            img {
                width: 60px;

                &:not(.selected) {
                    opacity: 0.3;
                    filter: grayscale(1);
                }
            }

            span {
                font-size: 0.75em;
            }
        }
    }

    .btns {
        display: flex;
        gap: 10px;

        .publish-button {
            background: none;
            color: black;
            border: 1px solid black;
        }

        .unpublish-button {
            background: #CE6868;
            color: white;
            border: none;
        }
    }

    img.preview {
        width: 150px;
        height: 100px;
        object-fit: cover;

        &.addressMap {
            width: 80%;
            height: 250px;
            border-radius: 30px;
            padding: 15px;
        }
    }

    h4 {
        padding: 10px;
        text-transform: uppercase;
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
        font-size: 18px;
        /* text-align: left; */
    }

    h5 {
        margin-top: 25px;
        font-size: 16px;
        text-align: left;
    }

    .description-explanation {
        font-size: 12px;
        width: 100%;
        text-align: left;
        margin: 10px 0;
    }

    .index-selector {
        margin: 20px 0;
    }

    .select-stop {
        text-align: center;
        border: none;
        font-weight: 700;
        cursor: pointer;
        align-self: center;
        margin-bottom: 15px;
    } 

    div.form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        flex-grow: 1;
        box-sizing: border-box;
        padding-top: 15px;

        .input-div {
            position: relative;
        }

        input, textarea {
            padding: 1em;
            border: 1px lightgrey solid;
            border-radius: 10px;
            box-sizing: border-box;
            width: 100%;
        }
        
        textarea {
            resize: vertical;
            border-radius: 10px 10px 0 10px;
            min-height: 175px;
        }
        .upload-image-div {
            display: block;
            position:relative;
            cursor: pointer;

            input[type="file"] {
                display: none;
            }

            input[type="text"] {
                pointer-events: none;
            }

            svg {
                position:absolute;
                right: 3%;
                top: 50%;
                transform: translateY(-50%)
            }
        }
    }
}
.save-button {
    border: 1px solid #6d6c97;
    background: #6d6c97;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    align-self: center;

    &.processing {
        opacity: 0.4;
    }
}

.message-box {
    max-width: 80vw;
}
</style>