<template>
    <div class="question-component">
        <h4 v-if="descriptions.length > 1">Beskrivelser</h4>
        <h4 v-else>Beskrivelse</h4>
        <p class="explanation" v-if="descriptions.length > 1">
            Legg til minst 3 spørsmål per beskrivelse. Disse vil også rullere, og skape enda større variasjon og
            mer læring i appen. Bytt mellom beskrivelsene under.
        </p>
        <p class="explanation" v-else>
            Legg til minst 3 spørsmål. Disse vil rullere, og skape enda større variasjon og mer læring i appen.
        </p>
        <index-selector v-if="descriptions.length > 1" v-model="selectedDescriptionIndex" @update:modelValue="selectedQuestionIndex = 0" :max="internalDescriptions.length" :disabled="disabled" />
        <div class="description">{{ description }}</div>

        <h4 v-if="descriptions.length > 1">Spørsmål for denne beskrivelsen</h4>
        <h4 v-else>Spørsmål</h4>
        <index-selector v-model="selectedQuestionIndex" :max="visibleQuestions.length" allowAdd allowDelete @add="addQuestion(this.selectedDescriptionIndex)" @delete="removeQuestion($event)" :disabled="disabled" />

        <div v-if="visibleQuestions.length > 0">
            <textarea class="question" type="text" rows="1" placeholder="Spørsmål" :value="questionText" @input="changeQuestion(question, $event.target.value)" :disabled="disabled"></textarea>
            <p class="warning">Husk å markere riktig alternativ!</p>

            <div class="answer-line" v-for="(answer, index) in question.answers" :key="index" >
                <input type="checkbox" :value="index" @click="toggleAnswer(question, index)" :checked="question.correctAnswers.indexOf(index) != -1" :disabled="disabled" />
                <input type="text" class="answer" :placeholder="'Svaralternativ ' + (index + 1)" :value="answerText(index)" @input="changeAnswer(question, index, $event.target.value)" :disabled="disabled" />
                <div class="delete-btn" v-if="question.answers.length > 2 && !disabled" @click="removeAnswer(question, index)">✖</div>
            </div>
            <div class="add-answer" :class="{'delete-button-space': question.answers.length > 2}" @click="addAnswer(question)" v-if="!disabled">
                + Legg til svaralternativ
            </div>
        </div>
        <div class="buttons">
            <slot name="button-slot"></slot>
        </div>
    </div>
</template>

<script>
import IndexSelector from '@/components/IndexSelector.vue'

export default {
    components: {
        IndexSelector,
    },
    emits: ['update:modelValue', 'addQuestion', 'removeQuestion', 'addAnswer', 'removeAnswer'],
    props: {
        wandering: Boolean,
        modelValue: Array,
        descriptions: Array,
        defaultLang: Object,
        lang: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            questions: [],
            internalDescriptions: [],

            selectedDescriptionIndex: 0,
            selectedQuestionIndex: 0,
        }
    },
    computed: {
        description() {
            return this.internalDescriptions[this.selectedDescriptionIndex];
        },
        visibleQuestions() {
            return this.questions.filter(q => q.description == this.selectedDescriptionIndex);
        },
        question() {
            return this.visibleQuestions[this.selectedQuestionIndex];
        },
        questionText() {
            if (this.defaultLang.key == this.lang.key) return this.question.question;
            if (!this.question.tr) return this.question.question;
            return this.question.tr[this.lang.key]?.question ?? this.question.question;
        },
    },
    methods: {
        questionCountFor(descIndex) {
            return this.questions.filter(q => q.description == descIndex).length;
        },
        addQuestion(descIndex) {
            this.$emit('addQuestion', descIndex);
            this.selectedQuestionIndex = this.visibleQuestions.length - 1;
        },
        removeQuestion(i) {
            this.$emit('removeQuestion', this.questions.indexOf(this.visibleQuestions[i]));
            if (i <= this.selectedQuestionIndex && i > 0) this.selectedQuestionIndex--;
        },
        changeQuestion(question, value) {
            if (this.defaultLang.key == this.lang.key) question.question = value;
            else {
                if (!('tr' in question)) question.tr = {};
                if (!(this.lang.key in question.tr)) question.tr[this.lang.key] = {};
                question.tr[this.lang.key].question = value;
            }
            this.$emit('update:modelValue', this.questions);
        },
        answerText(i) {
            if (this.defaultLang.key == this.lang.key) return this.question.answers[i];
            if (!this.question.tr) return this.question.answers[i];
            return this.question.tr[this.lang.key]?.answers[i] ?? this.question.answers[i];
        },
        toggleAnswer(question, i) {
            if (question.correctAnswers.indexOf(i) == -1) {
                question.correctAnswers.push(i);
            } else {
                question.correctAnswers = question.correctAnswers.filter(n => n != i);
            }
            this.$emit('update:modelValue', this.questions);
        },
        changeAnswer(question, i, value) {
            if (this.defaultLang.key == this.lang.key) question.answers[i] = value;
            else {
                if (!('tr' in question)) question.tr = {};
                if (!(this.lang.key in question.tr)) question.tr[this.lang.key] = {};
                if (!('answers' in question.tr[this.lang.key])) question.tr[this.lang.key].answers = [];
                while (question.tr[this.lang.key].answers.length <= i) question.tr[this.lang.key].answers.push(question.answers[question.tr[this.lang.key].answers.length]);
                question.tr[this.lang.key].answers[i] = value;
            }
            this.$emit('update:modelValue', this.questions);
        },
        addAnswer(question) {
            this.$emit('update:modelValue', this.questions);
            this.$emit('addAnswer', this.questions.indexOf(question));
        },
        removeAnswer(question, i) {
            question.correctAnswers = question.correctAnswers.filter(n => n != i).map(n => n > i ? n - 1 : n);
            this.$emit('update:modelValue', this.questions);
            this.$emit('removeAnswer', { questionIndex: this.questions.indexOf(question), index: i });
        },
    },
    watch: {
        modelValue() {
            this.questions = this.modelValue;
        },
        descriptions() {
            this.internalDescriptions = this.descriptions;
        },
    },
    beforeMount() {
        this.questions = this.modelValue;
        this.internalDescriptions = this.descriptions;
    },
}
</script>

<style lang="scss" scoped>
.question-component {
    text-align: left;

    textarea {
        padding: 1em;
        border: 1px solid lightgrey;
        border-radius: 10px 10px 0px 10px;
        box-sizing: border-box;
        width: 100%;
        resize: vertical;
        min-height: 175px;
        font-size: 14px;
    }

    .index-selector {
        margin: 20px 0;
    }

    h4 {
        margin-top: 50px;
    }

    .explanation {
        font-size: 12px;
        width: 100%;
        margin: 10px 0;
    }

    .description {
        border: 1px solid lightgrey;
        border-radius: 10px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 10px;
        white-space: pre-wrap;
    }

    .answer-line {
        margin-top: 20px;
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            margin: 0 25px;
            width: 15px;
            transform: scale(1.2);
            flex-grow: 0;
            flex-shrink: 0;
        }

        input[type="text"] {
            padding: 10px;
            border: 1px solid lightgrey;
            border-radius: 10px;
            outline: none;
            flex-grow: 1;
            flex-shrink: 1;
            font-size: 14px;
        }

        .delete-btn {
            width: 20px;
            height: 20px;
            background: #CE6868;
            line-height: 20px;
            color: white;
            font-size: 0.8em;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            margin: 0 10px;
        }
    }

    .add-answer {
        margin-top: 20px;
        margin-left: 65px;
        padding: 10px;
        border: 1px solid lightgrey;
        border-radius: 10px;
        outline: none;
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &.delete-button-space {
            margin-right: 40px;
        }
    }

    .buttons {
        margin-top: 40px;
    }
}
</style>