<template>
    <div v-pre></div>
</template>

<script>
import { defineComponent } from 'vue'
import Plotly from 'plotly.js-dist-min'

export default defineComponent({
    name: 'NumberQuestion',
    props: {
        question: {
            type: Object,
            required: true,
        },
        answers: {
            type: Object,
            required: true,
        },
    },
    computed: {
        labels() {
            if (this.question.min && this.question.max) {
                return [...new Array(this.question.max - this.question.min + 1)].map((_, i) => i + this.question.min);
            }
            let labels = Object.keys(this.answers).sort((a, b) => a - b);
            if (labels.length == 1) labels = [labels[0] - 1, labels[0], parseFloat(labels[0]) + 1];
            if (labels.length == 2) labels = [labels[0], Math.floor((parseFloat(labels[0]) + parseFloat(labels[1])) / 2), labels[1]];
            return labels;
        },
        data() {
            const labels = this.labels;
            return [
                {
                    x: labels,
                    y: labels.map(label => this.answers[label] ?? 0),
                    type: 'bar',
                },
            ];
        },
    },
    mounted() {
        Plotly.newPlot(this.$el, this.data);
    },
});
</script>