export default function deepClone(obj: any): typeof obj {
    // Primitive and other uncloneable types
    if (typeof obj == 'number') return obj;
    if (typeof obj == 'string') return obj;
    if (typeof obj == 'boolean') return obj;
    if (typeof obj == 'function') return obj;
    if (typeof obj == 'bigint') return obj;
    if (typeof obj == 'symbol') return obj;
    if (obj === null || obj === undefined) return obj;
    if (Object.getPrototypeOf(obj) != Object.getPrototypeOf({}) && Object.getPrototypeOf(obj) != Object.getPrototypeOf([])) return obj; // Don't clone objects created from classes

    // If array, return deepClone map
    if (Array.isArray(obj)) return obj.map(deepClone);

    let clone: typeof obj = {};
    for (let k in obj) {
        clone[deepClone(k)] = deepClone(obj[k]);
    }
    return clone;
}


// @ts-ignore
window.deepClone = deepClone