<template>
    <div class="time-graph">
        <!--Bar class="bar-wrapper" :chartData="chartData" :chartOptions="graphOptions" /-->
        <Line class="line-wrapper" v-if="entries.length > 0" :chartData="chartData" :chartOptions="graphOptions" />
        <div class="no-data-wrapper" v-else>Det er ingen data å vise i denne tidsperioden</div>
    </div>
</template>

<script>
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js'

import { Line } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const months = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];

const actionNames = {
    'pin-view': 'Åpnet',
    'pin-checkin': 'Sjekket inn',
    'adventure-start': 'Startet',
    'adventure-complete': 'Fullført',
};

function ucMonth(mth) {
    const month = months[mth - 1];
    return month.substring(0, 1).toUpperCase() + month.substring(1);
}

const colors = ['#2762FF', '#DE4C8A', '#DC9D00', '#008F39', '#FF7514', '#924E7D'];

function periodString(entry) {
    switch (entry.period) {
        case 'year': return `${entry.year}`;
        case 'month': return `${entry.month}`;
        case 'week': return `Uke ${entry.week}`;
        case 'day': return `${entry.day}`;
        default: return `${entry.hour}`;
    }
}

export default {
    name: 'TimeGraph',
    components: {
        Line,
    },
    props: {
        entries: { // List of Stats objects
            type: Array,
            required: true,
        },
        actions: { // List of actions to show in the graph
            type: Array,
            required: true,
        },
    },
    computed: {
        useRawStats() {
            return this.$store.state.Stats.useRawStats;
        },
        chartData() {
            const level = this.entries[0].period;

            const entries = [];
            let value = null;
            for (let entry of this.entries) {
                if (value === null) {
                    entries.push(entry);
                    value = entry[level];
                    continue;
                }

                // Fill in any gaps with values
                for (let i = value + 1; i < entry[level]; ++i) {
                    entries.push({
                        year: entry.year,
                        month: entry.month,
                        week: entry.week,
                        day: entry.day,
                        hour: entry.hour,
                        [level]: i,
                        actions: {},
                        period: entry.period,
                    });
                }

                entries.push(entry);
                value = entry[level];
            }

            const labels = entries.map(periodString);

            const datasets = this.actions.map((action, idx) => ({
                data: entries.map(entry => this.$store.getters['Stats/countAction'](entry, action)),
                backgroundColor: colors[idx], // TODO More colors
                fill: {
                    target: idx == 0 ? 'origin' : '-1',
                    above: colors[idx] + '50',
                },
            }));

            return {
                labels,
                datasets,
            };
        },
        graphOptions() {
            return {
                scales: {
                    y: {
                        min: 0,
                        max: this.useRawStats ? null : 1,
                        ticks: {
                            callback: (value, index, ticks) => this.useRawStats ? value : `${value*100}%`
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: (data) => {
                                const entry = this.entries[0]; // The label can be built from any entry plus the data label

                                const period = entry.period;
                                switch (period) {
                                    case 'year': return data[0].label; // Should not be possible in this graph
                                    case 'month': return `${ucMonth(parseInt(data[0].label))} ${entry.year}`;
                                    case 'week': return data[0].label; // Should not be possible in this graph
                                    case 'day': return `${data[0].label}. ${months[entry.month-1]} ${entry.year}`;
                                    case 'hour': return `${entry.day}. ${months[entry.month-1]} ${entry.year} ${data[0].label}:00 - ${data[0].label}:59`;
                                }
                            },
                            label: (data) => {
                                const action = this.actions[data.datasetIndex];
                                if (this.useRawStats) return `${actionNames[action]}: ${data.raw}`;
                                return `${actionNames[action]}: ${Math.floor(data.raw * 10000) / 100}%`;
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.time-graph {
    position: relative;
    width: 100%;
    height: 100%;

    .line-wrapper {
        height: 100%;
    }

    .no-data-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>