<template>
    <edit-treasure
        v-if="popup"
        @close="togglePopup"
        :wandering="true"
    ></edit-treasure>
    <edit-treasure
        v-if="editWander"
        @close="editWanderPopup"
        :wandering='true'
        :wanderingPins="editWanderChildren"
        :pin="editWander"
    ></edit-treasure>
    <confirmation-box
        v-if="deletingWandering"
        @close="deletingWandering=null"
        @confirm="confirmDeleteWandering"
        :item="deletingWandering.wanderingName"
    ></confirmation-box>
    <template v-if="notifyingAdventure">
        <confirmation-box
            v-if="!notifyingAdventure.notified && notifyingAdventure.published"
            @close="notifyingAdventure=null"
            @confirm="confirmNotifyAdventure"
            :item="notifyingAdventure.name"
        >
            Vil du sende ut en varsling for denne vandringen? Dette kan bare gjøres en gang.
            <template #confirm>Ja</template>
            <template #cancel>Nei</template>
        </confirmation-box>
        <message-box v-else-if="!notifyingAdventure.published" @close="notifyingAdventure = null">
            Vandringen må være publisert før du sender ut en varsling for den.
        </message-box>
        <message-box v-else @close="notifyingAdventure = null">
            En varsling har allerede blitt sendt ut for denne vandringen.
        </message-box>
    </template>
    <div class="container">
        <header-shell>
            <template #title>Vandringer</template>
            <template #header-buttons v-if="$store.getters['Areas/hasEditorAccess']">
                <span>{{ currentAdventures }} / {{ maxAdventures }} vandringer publisert</span>
                <header-button @click="togglePopup">+ Ny vandring</header-button>
            </template>
        </header-shell>
        <!-- <div class="wanderings"> -->
            <div class="wanderings-inner">
                <item-card 
                    v-for="wander in wanderings" 
                    :key="wander.id" 
                    @delete="deleteWandering(wander)" 
                    :deletable="$store.getters['Areas/hasEditorAccess']"
                    :img-src="wander.wanderingImage"
                    :stops="wander.wanderingChildren.length + 1"
                    :updatedBy="wander.updatedBy?.get('username')"
                    >
                    <template #title>{{ wander.wanderingName }}</template>
                    <template #default>
                    <span class="published" v-if="wander.published && wander.publishedByName">Publisert av {{ wander.publishedByName }}</span>
                    <span class="published" v-else-if="wander.published && wander.publishedBy">
                        <UserLoader :id="wander.publishedBy">
                            <template v-slot="{ user }">Publisert av {{ user.username }}</template>
                            <template #placeholder>Publisert</template>
                        </UserLoader>
                    </span>
                    <span class="published" v-else-if="wander.published">Publisert</span>
                        <span class="unpublished" v-else>Ikke publisert</span>
                    </template>
                    <template #buttons>
                        <button 
                            class="change-btn" 
                            v-if="$store.getters['Areas/hasEditorAccess']" 
                            @click="openEditWander(wander)"
                        >Rediger</button>
                        <button class="stats-btn" @click="$router.push(`/vandringer/${wander.id}`)" v-if="$store.getters['Areas/hasSystemAdminAccess']">Statistikk</button>
                        <button class="notify-btn" :class="{ disabled: wander.notified }" @click="notifyAdventure(wander)">Varsle</button>
                    </template>
                </item-card>
            </div>
        <!-- </div> -->
    </div>
</template>

<script>
import EditTreasure from "./TreasuresView/EditTreasure.vue"
import ConfirmationBox from "../components/ConfirmationBox.vue"
import MessageBox from '@/components/MessageBox.vue'
import UserLoader from '@/components/UserLoader.vue'

export default {
    components: {
        EditTreasure,
        ConfirmationBox,
        MessageBox,
        UserLoader,
    },
    data() {
        return {
            popup: false,
            editWander: null,
            editWanderChildren: null,
            selectedTitle: "",
            selectedDesc: "",
            selectedAddr: "",
            selectedDescSrc: "",
            selectedImgSrc: "",
            selectedQuestions: [],
            selectedWandChildren: [],
            deletingWandering: null,
            notifyingAdventure: null,
        }
    },
    computed: {
        wanderings() {
            return this.$store.getters['Pins/wanderingParents'];
        },
        wanderingStops() {
            return this.$store.state.Pins.pins.filter(pin => pin.wanderingParent)
        },
        currentAdventures() {
            return this.$store.state.Areas.selectedArea?.area.currentAdventures ?? 0;
        },
        maxAdventures() {
            return this.$store.state.Areas.selectedArea?.area.maxAdventures ?? 0;
        },
    },
    methods: {
        togglePopup() {
            this.popup = !this.popup
        },
        openEditWander(wander) {
            let wanderingPins = wander.wanderingChildren.map(child => this.$store.state.Pins.pinsById[child]);
            this.editWander = wander;
            this.editWanderChildren = [wander, ...wanderingPins];
        },
        editWanderPopup() {
            this.editWander = false
        },
        deleteWandering(wandering) {
            this.deletingWandering = wandering;
        },
        async confirmDeleteWandering() {
            console.log(this.deletingWandering.wanderingChildren);
            let wanderingPins = this.deletingWandering.wanderingChildren.map(child => this.$store.state.Pins.pinsById[child]);
            await Promise.all(wanderingPins.map(pin => this.$store.dispatch('Pins/deletePin', pin)));
            await this.$store.dispatch('Pins/deletePin', this.deletingWandering);

            this.deletingWandering = null;
        },
        notifyAdventure(adventure) {
            this.notifyingAdventure = adventure;
        },
        async confirmNotifyAdventure() {
            await this.$store.dispatch('Pins/notifyPin', this.notifyingAdventure.id);
            this.notifyingAdventure = null;
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    gap: 25px;
    text-align: left;
    
    header {
        flex-shrink: 0;
        flex-grow: 0;
    }
    /* .wanderings {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 1px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 25px; */
    
        .wanderings-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;
        
            img {
            object-fit: cover;
            height: 100%;
            width: 175px;
            border-radius: 20px;
            box-sizing: border-box;
            padding: 10px;
            }

            button {
                border-radius: 25px;
                border: none;
                padding: .75em 1.5em;
                font-size: 14px;
                letter-spacing: 0.15em;
                text-transform: capitalize;
                cursor: pointer;
                background: white;
            }
            
            .change-btn, .stats-btn, .notify-btn {
                color: #424242;
                border: solid 1px #BFBFBF;

                &.disabled {
                    opacity: 0.4;
                }
            }

            .published {
                color: green;
                font-weight: bold;
            }

            .unpublished {
                color: red;
            }
        }
    /* } */
}
</style>