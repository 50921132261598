<template>
    <div class="pie-div">
        <Pie :chartData="chartData" :chartOptions="options" />
    </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const dfltColors = ['orange', 'blue', 'darkseagreen', 'dodgerblue', 'darkgreen', 'darkorchid', 'indianred', 'gold', 'lightgrey', 'magenta', 'navy', 'papayawhip'];

export default {
    name: 'GenericPie',
    components: {
        Pie,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
        labels: {
            type: Array,
            required: false,
        },
        colors: {
            type: Array,
            required: false,
        },
        percent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        chartData() {
            return {
                labels: this.labels ?? this.values.map((val, i) => `${i+1}`),
                datasets: [
                    {
                        backgroundColor: this.colors ?? dfltColors,
                        data: this.values,
                    },
                ],
                
            };
        },
        options() {
            return {
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: (data) => {
                                if (this.percent) return `${data.label}: ${Math.floor(data.raw * 10000) / 100}%`;
                                return `${data.label}: ${data.raw}`;
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                cutout: '50%',
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.pie-div {
    position: relative;

    > div {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>