<template>
    <div class="custom-select">
        <select :value="modelValue" @change="input">
            <slot></slot>
        </select>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        modelValue: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
        }
    },
    methods: {
        input(elmt) {
            console.log(elmt.target.value)
            this.$emit('update:modelValue', elmt.target.value);
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-select {
    display: grid;
    grid-template-areas: "select";
    border: 1px solid gray;
    border-radius: 5px;
    padding: 6px 20px;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: #fff;
    align-items: center;

    select {
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: none;
        padding: 0 1.5em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: 16px;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        grid-area: select;
    }
}
.custom-select::after {
    content: "";
    grid-area: select;
    width: 12px;
    height: 7px;
    background-color: darkslategray;
    clip-path: polygon(25% 0, 50% 55%, 75% 0, 100% 0, 50% 100%, 0 0);
    justify-self: end;
}
</style>