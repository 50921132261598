<template>
    <div class="main-wrapper">
        <message-box v-if="error" @close="error = null">{{ error }}</message-box>
        <message-box v-if="termsOpen" @close="termsOpen = false">
            Ved å godkjenne invitasjon godkjenner du at du som representant
            for din kommune tar ansvar for innholdet som publiseres i Kulturjakten.
            Link Utvikling AS er ansvarlig for den teknologiske løsningen,
            og din Kommune er ansvarlig for innholdet som blir delt til brukerne.
        </message-box>
        <div class="login-card" v-if="newAccount === null">
            <h2>Kulturjakten</h2>
            <h3>Administrasjon</h3>
            <button @click.prevent="setNewAccount('newAccount')">Ny bruker</button>
            <button @click.prevent="setNewAccount('login')">Logg inn</button>
        </div>
        <div class="login-card" v-else>
            <h2>Kulturjakten</h2>
            <h3>Administrasjon</h3>
            <form>
                <div v-show="creatingAccount === false" class="input-div">
                    <input type="email" placeholder="E-post" v-model="email" />
                    <input type="text" placeholder="Brukernavn" v-model="username" v-if="newAccount === 'newAccount'">
                    <input type="password" placeholder="Passord" v-model="password" />
                </div>
                <div v-show="newAccount === 'newAccount' && creatingAccount === true" class="input-div">
                    <Dropdown v-model="gender" placeholder="Velg kjønn" :values="['male', 'female', 'other']" style="z-index: 3;">
                        <template v-slot="{ value }">{{ value == 'male' ? 'Mann' : value == 'female' ? 'Kvinne' : 'Annet' }}</template>
                    </Dropdown>
                    <Dropdown v-model="ageGroup" placeholder="Velg aldersgruppe" :values="['0-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80+']" style="z-index: 2;">
                        <template v-slot="{ value }">{{ value }} år</template>
                    </Dropdown>
                    <label class="terms">
                        <input type="checkbox" v-model="terms" />
                        <span>Jeg aksepterer <a @click="termsOpen = true">vilkårene</a> for bruk.</span>
                    </label>
                </div>
                <div v-if="newAccount === 'newAccount' && creatingAccount === false" class="btns-div">
                    <button @click.prevent="newAccount = null" class="back-btn" :disabled="sending" v-if="allowRegister">Tilbake</button>
                    <button @click.prevent="creatingAccount = true">Neste steg</button>
                </div>
                <div v-else-if="newAccount === 'newAccount' && creatingAccount === true" class="btns-div">
                    <button @click.prevent="creatingAccount = false" class="back-btn" :disabled="sending">Tilbake</button>
                    <process-button v-model="sending" :process="clickRegister"><template v-slot="{ processing }">{{ processing ? 'Sender...' : 'Lag bruker' }}</template></process-button>
                </div>
                <div v-else class="btns-div">
                    <button @click.prevent="newAccount = null" class="back-btn" :disabled="sending" v-if="allowRegister">Tilbake</button>
                    <button @click.prevent="clickLogIn">Logg inn</button>
                </div>
            </form>
        </div>
        <div class="login-deco">
            <svg width="110" height="362" viewBox="0 0 110 362" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M108.161 361.543C108.461 362.007 109.08 362.14 109.543 361.839C110.007 361.539 110.14 360.92 109.839 360.457L108.161 361.543ZM75.5457 134.075L74.5641 133.884L74.5569 133.926L75.5457 134.075ZM2.71173 55.096C2.85884 55.6284 3.40963 55.9406 3.94196 55.7935L12.6168 53.3962C13.1491 53.2491 13.4614 52.6983 13.3143 52.1659C13.1672 51.6336 12.6164 51.3213 12.0841 51.4684L4.37309 53.5994L2.24212 45.8884C2.09501 45.3561 1.54419 45.0439 1.01187 45.191C0.479529 45.3381 0.167261 45.8889 0.31437 46.4212L2.71173 55.096ZM75.2106 7.0217L74.6927 7.87714L75.2106 7.0217ZM23.0645 20.6317L23.9344 21.1249L23.0645 20.6317ZM75.2106 7.0217L74.6927 7.87714L75.2106 7.0217ZM64.2852 242.692L63.2876 242.761L64.2852 242.692ZM66.1846 195.931L67.1733 196.08L66.1846 195.931ZM64.2852 242.692L63.2876 242.761L64.2852 242.692ZM109.839 360.457L98.955 343.641L97.276 344.728L108.161 361.543L109.839 360.457ZM76.5272 134.267L93.6126 46.5369L91.6495 46.1546L74.5641 133.884L76.5272 134.267ZM22.1946 20.1385L2.80568 54.3365L4.5455 55.3229L23.9344 21.1249L22.1946 20.1385ZM67.1733 196.08L76.5344 134.225L74.5569 133.926L65.1958 195.781L67.1733 196.08ZM75.7285 6.16629C57.1345 -5.09161 32.9151 1.22957 22.1946 20.1385L23.9344 21.1249C34.0991 3.19638 57.0627 -2.79703 74.6927 7.87714L75.7285 6.16629ZM93.6126 46.5369C96.7044 30.6614 89.5639 14.543 75.7285 6.16629L74.6927 7.87714C87.8107 15.8196 94.5809 31.1023 91.6495 46.1546L93.6126 46.5369ZM65.2828 242.623C64.209 227.086 64.8429 211.479 67.1733 196.08L65.1958 195.781C62.8436 211.324 62.2037 227.078 63.2876 242.761L65.2828 242.623ZM98.955 343.641C79.3562 313.364 67.7698 278.604 65.2828 242.623L63.2876 242.761C65.798 279.08 77.4932 314.166 97.276 344.728L98.955 343.641Z" fill="black"/>
            </svg>
            <div>
                <h3>Er kommunen din allerede <br/> registrert i Kulturjakten?</h3>
                <h1>LOGG INN</h1>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import MessageBox from '@/components/MessageBox.vue'
import Dropdown from '@/components/Dropdown.vue'
import ProcessButton from '@/components/ProcessButton.vue'

export default defineComponent({
    name: 'LoginView',
    components: {
        MessageBox,
        Dropdown,
        ProcessButton,
    },
    data: () => ({
        username: '',
        password: '',
        email: '',
        gender: '',
        ageGroup: '',
        newAccount: 'login',
        terms: false,
        termsOpen: false,
        error: null,
        creatingAccount: false,
        sending: false,
    }),
    computed: {
        allowRegister() {
            return this.$route.meta.allowRegister;
        },
    },
    methods: {
        async clickRegister() {
            if (this.email == '') {
                this.error = 'Du må skrive inn en e-postadresse.';
                return;
            } else if (this.username == '') {
                this.error = 'Du må velge brukernavn.';
                return;
            } else if (this.password == '') {
                this.error = 'Du må velge passord.';
                return;
            } else if (this.gender == '') {
                this.error = 'Du må velge kjønn.';
                return;
            } else if (this.ageGroup == '') {
                this.error = 'Du må velge aldersgruppe.';
                return;
            } else if (this.terms != true){
                this.error = 'Du må akseptere vilkår.'
                return;
            } else {
                try {
                    await this.$store.dispatch('Auth/register', { 
                        username: this.username,
                        password: this.password, 
                        email: this.email, 
                        gender: this.gender,
                        ageGroup: this.ageGroup,
                    });
                } catch (e) {
                    switch (e) {
                        case 'invalid-username':
                            this.error = 'Brukernavnet du skrev inn er ugyldig. Brukernavn må bestå av bokstaver, tall, bindestrek (-) og/eller understrek (_) og være mellom 3 og 20 tegn. Mellomrom kan ikke brukes.';
                            break;
                        case 'username-taken':
                            this.error = 'Brukernavnet er allerede registrert.';
                            break;
                        case 'email-already-used':
                            this.error = 'E-postadressen er allerede registrert.';
                            break;
                        case 'invalid-email':
                            this.error = 'E-postadressen du skrev inn er ugyldig.';
                            break;
                        case 'weak-password':
                            this.error = 'Passordet du skrev er for svakt.';
                            break;
                        case 'too-many-requests':
                            this.error = 'Du har prøvd å registrere for mange ganger. Prøv igjen senere.';
                            break;
                        default:
                            this.error = 'Et ukjent problem oppsto.';
                            console.error(e);
                            break;
                    }
                }
            }
        },
        async clickLogIn() {
            try {
                await this.$store.dispatch('Auth/logIn', { email: this.email, password: this.password });
            } catch (e) {
                switch (e) {
                    case 'invalid-email':
                        this.error = 'E-postadressen du skrev inn er ugyldig.';
                        break;
                    case 'wrong-password':
                        this.error = 'Passordet er feil.';
                        break;
                    case 'no-password':
                        this.error = 'Du må skrive inn et passord.';
                        break;
                    case 'user-not-found':
                        this.error = 'Brukeren finnes ikke.'
                        break;
                    case 'too-many-requests':
                        this.error = 'Du har prøvd å logge inn for mange ganger. Prøv igjen senere.'
                        break;
                    case 'invalid-login-credentials':
                        this.error = 'Kunne ikke logge inn. Skrev du inn riktig e-post og passord?';
                        break;
                    default:
                        this.error = 'Et ukjent problem oppsto.';
                        console.error(e);
                        break;
                }
            }
        },
        setNewAccount(input) {
            this.newAccount = input
        },
    },
    watch: {
        allowRegister: {
            handler() {
                if (this.allowRegister) {
                    this.creatingAccount = false;
                    this.newAccount = null;
                }
            },
            immediate: true,
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    max-width: 90vw;
    color: black;

    .login-card {
        height: auto;
        min-height: 470px;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        background: white;
        padding: 3% 7rem;
        box-shadow: 7px 7px 0px black;
        -webkit-box-shadow: 7px 7px 0px black;
        -moz-box-shadow: 7px 7px 0px black;

        @media (max-width: $mobile-size) {
            width: 100%;
            padding: 15vw;
            justify-content: center;
        }

        button {
            background: #6D6C97;
            border-radius: 43px;
            color: white;
            border: none;
            padding: 1rem;
            margin-top: 15px;
            font-weight: 600;
        }

        button:hover {
            cursor: pointer;
        }

        h2 {
            text-transform: uppercase;
            font-size: 30px;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            padding: 20px;
        }

        input {
            /* max-height: 3rem; */
            padding: 16px;
            border-radius: 20px;
            border: solid 1px black;
            /* margin: 10px 0; */
        }

        input::placeholder {
            color: black;
        }

        form {
            max-width: 250px;

            .input-div {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            > button {
                width: 100%;
            }

            select, .dropdown {
                /* max-height: 3rem; */
                padding: 16px;
                border-radius: 20px;
                border: solid 1px black;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
            }

            .terms {
                display: flex;
                gap: 10px;
                max-width: 240px;

                span {
                    text-align: left;

                    a {
                        color: #6D6C97;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }

            div {
                display: flex;
                flex-direction: row;
                gap: 10px;

                button {
                    flex-grow: 1;

                    &.processing {
                        opacity: 0.6;
                    }
                }

                .back-btn {
                    background: none;
                    color: black;
                    border: solid 1px #d1d1d1;
                    flex-grow: 0;
                }
            }
        }
    }

    .login-deco {
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.155em;

        @media (max-width: $tablet-size) {
            display: none;
        }

        h3 {
            margin: 0;
            line-height: 22px;
            font-weight: 100; 
        }

        div {
            max-width: 30vw;
            min-width: 375px;
            /* margin-left: 2vw; */
            text-align: right;
        }

        svg {
            margin-bottom: 14vh;
            width: 7vw;
            min-width: 130px;
        }

        h1 {
            font-size: 60px;
            font-weight: 600;
            line-height: 73px;
        }
    }
}
</style>