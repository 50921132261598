<template>
    <Suspense v-if="$route.name == `submission`">
        <router-view></router-view>
    </Suspense>
    <login-view v-else-if="$store.state.Auth.user === null && !bypassAuth" />
    <div v-else-if="$store.state.Auth.user || bypassAuth" class="app-wrapper">
        <the-nav />
        <div id="main-content">
            <router-view />
            <the-footer v-if="$store.state.Auth.user" />
        </div>
    </div>
    <div v-else></div>
    <dropdown-overlay />
</template>

<script>
import TheNav from './components/TheNav.vue'
import LoginView from './views/LoginView.vue'
import DropdownOverlay from '@/components/DropdownOverlay.vue'
import Submission from './views/Submission.vue';
export default {
    components: {
    TheNav,
    LoginView,
    DropdownOverlay,
    Submission
},
    computed: {
        bypassAuth() {
            return this.$route?.meta.bypassAuth && !this.$route.query.login;
        },
    },
    mounted() {
        this.$store.dispatch('init');
    },
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&family=Quicksand:wght@500;700&display=swap');
@import '@/assets/vars.scss';

* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%; 
}

body {
    height: 100%;
    margin: 0;
    background: #F9F9FD;
    overflow: hidden;
    
    #app {
        height: 100%; 
        /* font-family: Avenir, Helvetica, Arial, sans-serif; */
        background: #F9F9FD;
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: black;
        display: grid;  

        input, textarea, button {
            font-family: 'Montserrat', sans-serif;
        }

        .app-wrapper {  
            height: 100vh;
            display: flex;
            flex-direction: column;

            #main-content {
                flex-grow: 1;
                flex-shrink: 1;
                padding-top: 30px;
                overflow-y: auto;
                overflow-x: hidden;
                display: grid;
                grid-template-rows: 1fr auto;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
}
/* ::-webkit-scrollbar-track {
  box-shadow: 0 0px 2px 2px rgba(0,0,0,0.1) inset;
} */
::-webkit-scrollbar-thumb {
    background: #6d6c97;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #4f4f6d;
}
</style>
