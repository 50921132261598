<template>
    <div class="invite-row">
        <confirmation-box v-if="rescinding" @close="rescinding = false" @confirm="confirmRescind">
            Vil du trekke tilbake invitasjonen til {{ email }}?
            <template #confirm>Ja</template>
            <template #cancel>Nei</template>
        </confirmation-box>
        <div class="email">{{ email }}</div>
        <div class="role">{{ roleName }}</div>
        <div class="invited-by">Invitert av {{ inviterName }}</div>
        <div class="controls">
            <div class="button rescind-button" @click="rescind">Fjern</div>
        </div>
    </div>
</template>

<script>
import ConfirmationBox from '@/components/ConfirmationBox.vue'

export default {
    name: 'InviteRow',
    components: {
        ConfirmationBox,
    },
    props: ['id', 'email', 'role', 'invitedBy'],
    data: () => ({
        rescinding: false,
    }),
    computed: {
        roleName() {
            if (this.role == 'admin') return 'Admin';
            if (this.role == 'editor') return 'Redaktør';
            if (this.role == 'viewer') return 'Leser';
        },
        inviterName() {
            return this.$store.state.Users.users[this.invitedBy]?.username ?? '';
        },
    },
    methods: {
        rescind() {
            this.rescinding = true;
        },
        confirmRescind() {
            this.rescinding = false;
            this.$store.dispatch('Roles/rescind', this.id);
        },
    },
    watch: {
        invitedBy: {
            handler() {
                this.$store.dispatch('Users/loadUser', this.invitedBy);
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.invite-row {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #D1D1D1;

    .email {
        width: 300px;
        text-align: left;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .role {
        width: 300px;
        text-align: left;
        color: red;
        font-weight: bold;
    }

    .invited-by {
        text-align: left;
        width: 300px;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .controls {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;

        .button {
            color: #424242;
            background: white;
            border: 1px solid #BFBFBF;
            border-radius: 25px;
            padding: .75em 1.5em;
            font-size: 14px;
            letter-spacing: 0.15em;
            text-transform: capitalize;
            cursor: pointer;
            margin-left: 10px;

            &.rescind-button {
                color: white;
                background: #CE6868;
                border: none;
            }
        }
    }
}
</style>