<template>
    <div class="greyout">
        <div class="message-container">
            <h3 v-if="$slots.title"><slot name="title"></slot></h3>
            <p><slot></slot></p>
            <div class="buttons">
                <button @click="$emit('close')" class="ok-btn">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:[ 'message' ],
    emits:[ 'close' ]
}
</script>

<style lang="scss" scoped>
.greyout {
    background: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; 
    z-index: 105;

    .message-container {
        display: flex;
        flex-direction: column;
        background: white;
        padding: 30px 20px;
        height: auto;
        gap: 20px;
        max-width: 500px;

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 15px;

            button {
                border-radius: 25px;
                border: none;
                padding: .75rem 2rem;
                font-size: 14px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                cursor: pointer;
            }

            .ok-btn {
                background: maroon;
                color: white;
            }
        }
    }
}
</style>