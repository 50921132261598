<template>
    <div class="answer-line">{{ answer.value }}</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TextQuestion',
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.answer-line {
    padding: 10px;
    border: 1px solid #DDD;
    border-radius: 10px;
}
</style>