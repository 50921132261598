import { Timestamp, GeoPoint } from 'firebase/firestore'

import FirestoreType from './FirestoreType'

export interface PinQuestion {
    question: string
    answers: string[]
    description: number
    //tr: Translatable<PinQuestion>
}

export interface PinQuestionWithAnswer extends PinQuestion {
    correctAnswers: number[]
}

export default interface Pin extends FirestoreType {
    // Editable by area editor
    name: string
    descriptions: string[]
    image: string
    imageAlt: string
    address: string
    sources: string[]
    location: GeoPoint
    category: string
    wanderingParent?: string
    wanderingChildren: string[]
    wanderingName?: string
    wanderingDescription?: string
    wanderingSources?: string
    wanderingImage?: string
    wanderingImageAlt?: string
    wanderingEstimatedTime?: string
    origin?: string
    link?: string
    published: boolean
    publishedBy?: string|null
    publishedByName?: string|null
    //tr: Translations<IPin>

    createdAt: Timestamp
    updatedAt: Timestamp
    deletedAt: Timestamp|null

    // System only
    updatedBy?: string
    notified: boolean
    questions: PinQuestion[] // Set by on-update function call when question document is updated

    // Read only
    area: string

    /*get isWanderingParent(): boolean {
        return this.data!.wanderingChildren && this.data!.wanderingChildren.length > 0;
    }

    get isWanderingChild(): boolean {
        return !!this.data!.wanderingParent;
    }*/
}

export interface PinQuestions extends FirestoreType {
    questions: PinQuestionWithAnswer[]
}

export function isWanderingParent(pin: Pin): boolean {
    return pin.wanderingChildren && pin.wanderingChildren.length > 0;
}

export function isWanderingChild(pin: Pin): boolean {
    return !!pin.wanderingParent;
}

export function isWanderingPin(pin: Pin) {
    return isWanderingParent(pin) || isWanderingChild(pin);
}