<template>
    <div class="ratios">
        <div class="circles">
            <div class="gender-circle circle">
                <h4>Kjønnsfordeling</h4>
                <generic-pie
                    :labels="['Menn', 'Kvinner', 'Andre']"
                    :values="[ ratios.gender.male, ratios.gender.female, ratios.gender.other ]"
                    :percent="!$store.state.Stats.useRawStats"
                />
            </div>
            <div class="age-group-circle circle">
                <h4>Turist/innbygger</h4>
                <generic-pie
                    :labels="['Innbygger', 'Turist']"
                    :values="[ ratios.userType.resident, ratios.userType.tourist ]"
                    :percent="!$store.state.Stats.useRawStats"
                />
            </div>
        </div>
        <div class="bar">
            <h4>Aldersfordeling</h4>
            <age-group-graph :values="[
                ratios.ageGroup['0-19'],
                ratios.ageGroup['20-29'],
                ratios.ageGroup['30-39'],
                ratios.ageGroup['40-49'],
                ratios.ageGroup['50-59'],
                ratios.ageGroup['60-69'],
                ratios.ageGroup['70-79'],
                ratios.ageGroup['80+'],
            ]" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import GenericPie from '@/components/GenericPie.vue'
import AgeGroupGraph from './RatiosDisplay/AgeGroupGraph.vue'

export default defineComponent({
    name: 'RatiosDisplay',
    components: {
        GenericPie,
        AgeGroupGraph,
    },
    props: {
        ratios: {
            type: Object,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.ratios {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .circles {
        flex-grow: 1;
        display: flex;
        gap: 30px;
        height: 1px;
        max-height: 275px;

        .circle {
            width: 1px;
            flex-grow: 1;
            background: white;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding: 10px;

            .pie-div {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .bar {
        flex-grow: 1;
        background: white;
        border-radius: 10px;
        height: 1px;
        padding: 10px;
        display: flex;
        flex-direction: column;

        .age-group-graph {
            height: 1px;
            flex-grow: 1;
        }
    }
}
</style>