<template>
    <div class="greyout" @click.self="$emit('close')">
        <h1 class="head">Slik vil det se ut i app</h1>
        <p class="close" @click.self="$emit('close')">✖</p>
        <div class="new-container">
            <div v-if="pin" class="treasure">
                <img :src="pin.image" />
                <button class="close-icon" @click="$emit('close')">✖</button>
                <div class="text-cont">
                    <div class="title">
                        <h2>{{ pin.name }}</h2>
                        <h3>{{ pin.address }}</h3>
                    </div>
                    <p>{{ pin.descriptions[0] }}</p>
                </div>
                <button class="unlock">Lås opp for å få mynt</button>
            </div>
            <div v-else class="reward">
                <img :src="reward.imageUrl" />
                <button class="close-icon" @click="$emit('close')">✖</button>
                <div class="text-cont">
                    <div class="title">
                        <h2>{{ reward.title }}</h2>
                        <h3>{{ reward.remaining }} billetter igjen</h3>
                        <div class="price">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 20.7082C16.1379 20.7082 20.7083 16.1377 20.7083 10.4999C20.7083 4.86197 16.1379 0.291565 10.5 0.291565C4.86211 0.291565 0.291702 4.86197 0.291702 10.4999C0.291702 16.1377 4.86211 20.7082 10.5 20.7082Z" fill="#FFEB5A"/>
                                <path d="M10.5 21C4.71023 21 0 16.2898 0 10.5C0 4.71023 4.71023 0 10.5 0C16.2898 0 21 4.71023 21 10.5C21 16.2898 16.2898 21 10.5 21ZM10.5 0.583406C5.0318 0.583406 0.583406 5.0318 0.583406 10.5C0.583406 15.9682 5.0318 20.4166 10.5 20.4166C15.9682 20.4166 20.4166 15.968 20.4166 10.5C20.4166 5.03196 15.9682 0.583406 10.5 0.583406ZM10.5 2.36775C14.984 2.36775 18.6321 6.01584 18.6321 10.5C18.6321 14.9843 14.984 18.6322 10.5 18.6322C6.01601 18.6322 2.36791 14.9842 2.36791 10.4998C2.36791 6.01568 6.01617 2.36775 10.5 2.36775ZM10.5 1.71281C5.64687 1.71281 1.71298 5.64687 1.71298 10.4998C1.71298 15.353 5.64687 19.287 10.5 19.287C15.3531 19.287 19.287 15.353 19.287 10.4998C19.287 5.64687 15.3531 1.71281 10.5 1.71281ZM12.0071 8.1206C11.5695 7.71865 11.203 7.49405 10.6357 7.49405C9.99748 7.49405 9.60783 7.82529 9.60783 8.4397C9.60783 9.85802 12.8226 9.57436 12.8226 11.962C12.8226 13.0851 12.16 13.9123 10.9907 14.1259V15.3415H10.0452V14.1607C9.32334 14.09 8.5324 13.7594 7.99969 13.2626L8.61459 12.3403C9.17044 12.7777 9.72612 13.0722 10.3643 13.0722C11.1323 13.0722 11.5106 12.6947 11.5106 12.0565C11.5106 10.4967 8.29566 10.7212 8.29566 8.49876C8.29566 7.39938 8.98144 6.6191 10.0454 6.42994V5.22457H10.9909V6.4068C11.7474 6.5013 12.2671 6.86733 12.7043 7.32851L12.0071 8.1206Z" fill="#D69500"/>
                            </svg>
                            <h4>{{ reward.price }}</h4>
                        </div>
                    </div>
                    <p>{{ reward.description }}</p>
                </div>
                <button class="unlock">Kjøp premie for {{reward.price}} mynter</button>
            </div>
        </div>
    </div>
</template>

<script>
import Pin from '@/types/Pin'

export default {
    props: {
        pin: {
            type: Pin,
            required: false,
        },
    },
    data() {
        return {
            test: null,
        }
    },
    methods: {
        print(t) {
            console.log(t)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';
.greyout {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    gap: 10px;

    .head, .close {
        color: white;
        position: absolute;
        top: 4.5vh;

        @media (max-width: $tablet-size) {
            display: none;
        }
    }
    .close {
        right: 34%; 
        font-size: 36px;  
    }
    .head {
        left: 50%;
        transform: translateX(-50%);
    }

    .new-container {
        background: white;
        z-index: 100;
        /* min-width: 400px; */
        min-width: 349px;
        margin-top: 11vh;
        width: auto;
        height: auto;
        box-sizing: border-box;
        /* padding: 20px; */
        cursor: initial;
        position: relative;
        border-radius: 40px;

        @media (max-width: $tablet-size){
            margin-top: 0;
        }

        .close-icon {
            display: none;
            position: absolute;
            border: none;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            z-index: 101;
            right: 15px;
            top: 10px;
            cursor: pointer;
            font-size: 24px;

            @media (max-width: $mobile-size) {
                display: initial;
            }
        }
        .unlock {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            padding: 1.25em;
            border-radius: 25px;
            border: none;
            text-transform: uppercase;
            letter-spacing: 0.02em;
            background: #6D6C97;
            color: white;
        }
        .treasure {
            width: 23vw;
            min-width: 349px;
            height: 85vh;
            position: relative;
            
            @media (max-width: $mobile-size) {
                width: 100vw;
                height: 100vh;
            }
            .text-cont {
                position: absolute;
                bottom: 0;
                background: white;
                width: 100%;
                height: 50vh;
                border-radius: 25px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                padding: 30px;

                .title {
                    align-self: flex-end;
                    text-align: right;
                    padding-bottom: 25px;

                    h2 {
                        font-size: 24px;
                        letter-spacing: .12em;
                        text-transform: uppercase;
                    }
                    h3 {
                        font-size: 12px;
                    }
                }
                p {
                    align-self: flex-start;
                    text-align: left;
                    font-size: 12px;
                }
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 50vh;
                border-radius: 25px 25px 0 0;
            }
        }
        .reward {
            width: 23vw;
            min-width: 349px;
            height: 85vh;
            position: relative;

            @media (max-width: $mobile-size) {
                width: 100vw;
            }
            .text-cont {
                position: absolute;
                bottom: 0;
                background: white;
                width: 100%;
                height: 35vh;
                border-radius: 25px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                padding: 30px;

                .title {
                    align-self: flex-start;
                    text-align: left;
                    padding-bottom: 25px;

                    h2 {
                        font-size: 16px;
                        letter-spacing: .12em;
                        text-transform: uppercase;
                    }
                    h3 {
                        font-size: 12px;
                        color: #1B1B34;
                        margin: 5px 0;
                    }
                    .price {
                        position:absolute;
                        display: flex;
                        gap: 5px;
                        right: 10%;
                        top: 15%;
                    }
                }
                p {
                    align-self: flex-start;
                    text-align: left;
                    color: #1B1B34;
                    font-size: 11px;
                }
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 50vh;
                border-radius: 25px;
                position: relative;
                z-index: 1;
            }

        }
    }
}


</style>