<template>
    <popup-shell @close="$emit('close')">
        <message-box v-if="confirmMessage" @close="confirmDone">{{ confirmMessage }}</message-box>
        <div class="access-popup-container">
            <header>
                <h4 v-if="user">Rediger bruker</h4>
                <h4 v-else>Inviter bruker</h4>
            </header>
            <form>
                <input v-if="!user" type="email" placeholder="Epost" v-model="email" />
                <div class="roles">
                    <label>
                        <input type="radio" value="admin" name="role" v-model="selectedRole">
                        Admin
                        <p class="description">
                            Admin-brukere har tilgang til å redigere alt innhold som din kommune er ansvarlig for i Kulturjakten,
                            samt å administrere tilganger for andre brukere.
                        </p>
                    </label>
                    <label>
                        <input type="radio" value="editor" name="role" v-model="selectedRole">
                        Redaktør
                        <p class="description">
                            Redaktører har tilgang til å redigere alt innhold som din kommune er ansvarlig for i Kulturjakten.
                        </p>
                    </label>
                    <label>
                        <input type="radio" value="viewer" name="role" v-model="selectedRole">
                        Leser
                        <p class="description">
                            Lesere har ikke tilgang til å redigere innhold i Kulturjakten, men har tilgang til å se innhold og
                            statistikk tilknyttet alle typer innhold som din kommune er ansvarlig for i Kulturjakten.
                        </p>
                    </label>
                </div>
                <div class="btns">
                    <button type="submit" @click.prevent="save">Lagre</button>
                </div>
            </form>
        </div>
    </popup-shell>
</template>

<script>
import User from '../../types/User'
import MessageBox from '@/components/MessageBox.vue'

export default {
    emits: ['close'],
    components: {
        MessageBox,
    },
    props: {
        user: {
            type: User,
            required: false,
        },
        role: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        email: '',
        selectedRole: 'none',
        confirmMessage: null,
        closeAfterConfirm: false,
    }),
    methods: {
        save() {
            if (this.user) return this.change();
            return this.invite();
        },
        async invite() {
            if (this.email.trim() == '') {
                this.confirmMessage = 'Du må skrive inn en e-postadresse.';
                return;
            }
            if (this.selectedRole == 'none') {
                this.confirmMessage = 'Du må velge en rolle.';
                return;
            }
            await this.$store.dispatch('Roles/invite', { email: this.email, role: this.selectedRole });
            this.confirmMessage = 'Invitasjonen har blitt sendt.';
            this.closeAfterConfirm = true;
        },
        async change() {
            if (this.selectedRole != this.role) await this.$store.dispatch('Roles/changeRole', { user: this.user, role: this.selectedRole });
            this.$emit('close');
        },
        confirmDone() {
            this.confirmMessage = null;
            if (this.closeAfterConfirm) this.$emit('close');
        },
    },
    mounted() {
        if (this.role) this.selectedRole = this.role;
    },
}
</script>

<style lang="scss" scoped>
.access-popup-container {
    /* height: 60vh; */
    width: 25vw;
    min-width: 400px;
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: space-around;

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        height: 85%;
        gap: 30px;

        .roles {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            /* height: 100px; */
        }
        
        .btns {
            display: flex;
            width: 100%;
            gap: 20px;
        }
        header {
            height: 15%;
            h4 {
                margin: 0;
            }
        }
        p {
            text-align: left;
        }
        input[type=email], button {
            width: 100%;
        }

        input[type=email] {
            border-radius: 20px;
            border: 1px black solid;
            padding: 1rem;
            box-sizing: border-box;
        }
        label {
            text-align: left;
        }
        input[type=radio] {
            margin-top: 20px;
        }
        .description {
            font-size: 0.8em;
            color: grey;
        }
        button {
            border: none;
            background: #6d6c97;
            padding: 1rem;
            border-radius: 30px;
            color: white;
            text-transform: uppercase;
            cursor: pointer;
            font-family: "Montserrat";
        }
        .remove-btn {
            background: #D28484;
        }
    }
}
</style>