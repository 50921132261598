import FirestoreType from "./FirestoreType";
import { Gender, AgeGroup } from "./User";

export type StatsPeriod = 'year'
    |'month'
    |'week'
    |'day'
    |'hour'

export type ActionType = 'app-open'
    |'user-active'
    |'pin-view'
    |'pin-checkin'
    |'adventure-start'
    |'adventure-complete'

export type UserType = 'resident'|'tourist'

export type ActionStats = Partial<{
    [action in ActionType]: Partial<{
        [gender in Gender]: Partial<{
            [ageGroup in AgeGroup]: Partial<{
                [userType in UserType]: number
            }>
        }>
    }>
}>

// This represents the recorded stats for an object for a specific period, with no information about which users are included in these stats
export default interface Stats extends FirestoreType {
    period: StatsPeriod
    year: number
    month?: number
    week?: number
    day?: number
    hour?: number
    actions: ActionStats
    percentFactor?: number
}

export function countStats(stats: Stats|null, action: ActionType, genderFilter: Record<Gender, boolean>, ageGroupFilter: Record<AgeGroup, boolean>, userTypeFilter: Record<UserType, boolean>): number {
    if (!stats) return 0;
    if (!stats.actions[action]) return 0;

    let cnt = 0;
    for (let gender in stats.actions[action]) {
        if (!genderFilter[gender as Gender]) continue;
        for (let ageGroup in stats.actions[action]![gender as Gender]) {
            if (!ageGroupFilter[ageGroup as AgeGroup]) continue;
            for (let userType in stats.actions[action]![gender as Gender]![ageGroup as AgeGroup]) {
                if (!userTypeFilter[userType as UserType]) continue;
                cnt += stats.actions[action]![gender as Gender]![ageGroup as AgeGroup]![userType as UserType]!;
            }
        }
    }
    return cnt;
}

/*import C from '@/const'
import Type, { Pointer } from './Type'

export type StatsLevel = 'all'|'year'|'month'|'week'|'day';

export type StatsGender = 'male'|'female'|'other';
export type StatsUserType = 'tourist'|'local';
export type StatsAgeGroup = '0-19'|'20-29'|'30-39'|'40-49'|'50-59'|'60-69'|'70-79'|'80+';

export type StatsItem = {
    [gender in StatsGender]?: {
        [userType in StatsUserType]?: {
            [ageGroup in StatsAgeGroup]?: number
        }
    }
}

export function sumOf(item: StatsItem): number {
    let sum = 0;
    for (let k1 in item) {
        for (let k2 in item[k1 as StatsGender]) {
            for (let k3 in item[k1 as StatsGender]![k2 as StatsUserType]) {
                sum += item[k1 as StatsGender]![k2 as StatsUserType]![k3 as StatsAgeGroup]!;
            }
        }
    }
    return sum;
}

export function userTypeSums(item: StatsItem) {
    let tourists = 0;
    let locals = 0;
    for (let gender in item) {
        for (let userType in item[gender as StatsGender]) {
            let sum = 0;
            for (let ageGroup in item[gender as StatsGender]![userType as StatsUserType]) {
                sum += item[gender as StatsGender]![userType as StatsUserType]![ageGroup as StatsAgeGroup]!;
            }
            if (userType == 'tourist') tourists += sum;
            if (userType == 'local') locals += sum;
        }
    }
    return { tourists, locals };
}

export function genderSums(item: StatsItem) {
    let sums: { [gender: typeof C.genders[number]]: number } = {
        male: 0,
        female: 0,
        other: 0,
    };
    for (let gender in item) {
        for (let userType in item[gender as StatsGender]) {
            for (let ageGroup in item[gender as StatsGender]![userType as StatsUserType]) {
                sums[gender as StatsGender] += item[gender as StatsGender]![userType as StatsUserType]![ageGroup as StatsAgeGroup]!;
            }
        }
    }
    return sums;
}

export function ageGroupSums(item: StatsItem) {
    let sums: { [group: typeof C.ageGroups[number]]: number } = {
        '0-19': 0,
        '20-29': 0,
        '30-39': 0,
        '40-49': 0,
        '50-59': 0,
        '60-69': 0,
        '70-79': 0,
        '80+': 0,
    };
    for (let gender in item) {
        for (let userType in item[gender as StatsGender]) {
            for (let ageGroup in item[gender as StatsGender]![userType as StatsUserType]) {
                sums[ageGroup as StatsAgeGroup] += item[gender as StatsGender]![userType as StatsUserType]![ageGroup as StatsAgeGroup]!;
            }
        }
    }
    return sums;
}

export default abstract class Stats<T extends Type> extends Type {
    item!: Pointer
    level!: StatsLevel
    year!: number
    month!: number
    week!: number
    day!: number
}*/