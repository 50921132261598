<template>
    <div class="single-form-view" v-if="form || !id">
        <h1>Skjema: {{ title }}</h1>
        <language-btns v-model="selectedLang"></language-btns>
        <div class="general">
            <div class="line">
                <span>Tittel</span>
                <input type="text" v-model="trTitle" />
            </div>
            <div class="line">
                <span>Beskrivelse</span>
                <input type="text" v-model="trDescription" />
            </div>
        </div>

        <div class="elements">
            <draggable v-model="elementOrder" :item-key="key => key">
                <template #item="{element}">
                    <div class="element">
                        <div class="add-row">
                            <span>Legg til:</span>
                            <button @click="add('text', element)">Tekstlinje</button>
                            <button @click="add('q-text', element)">Tekstspørsmål</button>
                            <button @click="add('q-number', element)">Tallspørsmål</button>
                            <button @click="add('q-multiple', element)">Flervalg</button>
                            <button @click="add('q-multiple-grid', element)">2D flervalg</button>
                            <button @click="add('q-area', element)">Sonevalg</button>
                        </div>
                        <div class="element-row">
                            <div class="element-header">
                                <label class="element-title-wrapper">
                                    <span v-if="elements[element].type == 'text'">Tekstlinje</span>
                                    <span v-if="elements[element].type == 'q-text'">Tekstspørsmål</span>
                                    <span v-if="elements[element].type == 'q-number'">Tallspørsmål</span>
                                    <span v-if="elements[element].type == 'q-multiple'">Flervalg</span>
                                    <span v-if="elements[element].type == 'q-multiple-grid'">2D flervalg</span>
                                    <span v-if="elements[element].type == 'q-area'">Sonevalg</span>
                                    <input type="text" class="element-title" :value="elementText(element)" @input="updateTranslatedElement(element, { text: $event.target.value })" />
                                </label>
                            </div>
                            <div class="question-details" v-if="elements[element].type != 'text'">
                                <QuestionWrapper :language="selectedLang" :element="elements[element]" @update-raw="updateRawElement(element, $event)" @update-tr="updateTranslatedElement(element, $event)" />
                            </div>
                            <div class="element-management">
                                <div class="element-options">
                                    <span @click="remove(element)" class="element-option">Fjern</span>
                                </div>
                                <div class="element-required-container" v-if="elements[element].type != 'text'">
                                    <toggle-switch v-model="elements[element].required" /> Påkrevd
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
            <div class="add-row">
                <span>Legg til:</span>
                <button @click="add('text')">Tekstlinje</button>
                <button @click="add('q-text')">Tekstspørsmål</button>
                <button @click="add('q-number')">Tallspørsmål</button>
                <button @click="add('q-multiple')">Flervalg</button>
                <button @click="add('q-multiple-grid')">2D flervalg</button>
                <button @click="add('q-area')">Sonevalg</button>
            </div>
        </div>

        <button class="save-button" @click="save" :disabled="saving">{{ saving ? 'Lagrer...' : 'Lagre' }}</button>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'

import deepClone from '@/util/deepClone'

import ToggleSwitch from '@/components/ToggleSwitch.vue'
import QuestionWrapper from './EditFormView/QuestionWrapper.vue'

export default defineComponent({
    name: 'EditFormView',
    components: {
        draggable,
        ToggleSwitch,
        QuestionWrapper,
    },
    props: {
        id: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        form: null,
        title: '',
        description: '',
        tr: {},
        elements: {},
        elementOrder: [],

        saving: false,

        selectedLang: null,
    }),
    computed: {
        trTitle: {
            get() {
                if (this.selectedLang && this.selectedLang.key != 'no') {
                    if (this.tr[this.selectedLang.key] && this.tr[this.selectedLang.key].title) return this.tr[this.selectedLang.key].title;
                    return '';
                }
                return this.title;
            },
            set(val) {
                if (this.selectedLang && this.selectedLang.key != 'no') {
                    if (!this.tr[this.selectedLang.key]) this.tr[this.selectedLang.key] = {};
                    this.tr[this.selectedLang.key].title = val;
                } else {
                    this.title = val;
                }
            },
        },
        trDescription: {
            get() {
                if (this.selectedLang && this.selectedLang.key != 'no') {
                    if (this.tr[this.selectedLang.key] && this.tr[this.selectedLang.key].description) return this.tr[this.selectedLang.key].description;
                    return '';
                }
                return this.description;
            },
            set(val) {
                if (this.selectedLang && this.selectedLang.key != 'no') {
                    if (!this.tr[this.selectedLang.key]) this.tr[this.selectedLang.key] = {};
                    this.tr[this.selectedLang.key].description = val;
                } else {
                    this.description = val;
                }
            },
        },
    },
    methods: {
        elementText(element) {
            if (this.selectedLang && this.selectedLang.key != 'no') {
                if (this.elements[element].tr && this.elements[element].tr[this.selectedLang.key]) {
                    return this.elements[element].tr[this.selectedLang.key].text;
                }
                return '';
            }
            return this.elements[element].text;
        },
        updateRawElement(element, values) {
            Object.assign(this.elements[element], values);
        },
        updateTranslatedElement(element, values) {
            if (this.selectedLang && this.selectedLang.key != 'no') {
                if (!this.elements[element].tr) this.elements[element].tr = {};
                if (!this.elements[element].tr[this.selectedLang.key]) this.elements[element].tr[this.selectedLang.key] = {};
                Object.assign(this.elements[element].tr[this.selectedLang.key], values);
            } else {
                Object.assign(this.elements[element], values);
            }
        },
        add(type, beforeId) {
            const data = {
                type,
                text: '',
                required: false,
            };
            switch (type) {
                case 'q-text':
                    data.placeholder = '';
                    break;
                case 'q-number':
                    data.presentation = 'input';
                    data.min = null;
                    data.max = null;
                    break;
                case 'q-multiple':
                    data.multipleSelection = false;
                    data.presentation = 'radio';
                    data.options = {};
                    data.optionsOrder = [];
                    break;
                case 'q-multiple-grid':
                    data.multipleSelection = false;
                    data.rows = {};
                    data.rowsOrder = [];
                    data.columns = {};
                    data.columnsOrder = [];
                    break;
            }

            let newId = this.elementOrder.length;
            while (this.elements[`e${newId}`]) ++newId;

            this.elements[`e${newId}`] = data;
            if (beforeId !== undefined) {
                const idx = this.elementOrder.indexOf(beforeId);
                this.elementOrder.splice(idx, 0, `e${newId}`);
            } else {
                this.elementOrder.push(`e${newId}`);
            }
        },
        moveUp(id) {
            const idx = this.elementOrder.indexOf(id);
            if (idx < 1) return;
            const temp = this.elementOrder[idx];
            this.elementOrder[idx] = this.elementOrder[idx-1];
            this.elementOrder[idx-1] = temp;
        },
        moveDown(id) {
            const idx = this.elementOrder.indexOf(id);
            if (idx < 0 || idx >= this.elementOrder.length) return;
            const temp = this.elementOrder[idx];
            this.elementOrder[idx] = this.elementOrder[idx+1];
            this.elementOrder[idx+1] = temp;
        },
        remove(id) {
            delete this.elements[id];
            this.elementOrder = this.elementOrder.filter(i => i != id);
        },
        async save() {
            this.saving = true;
            const formData = {
                title: this.title,
                description: this.description,
                elements: this.elements,
                elementOrder: this.elementOrder,
                tr: deepClone(this.tr),
            };
            this.form = await this.$store.dispatch('Forms/saveForm', {
                id: this.form ? this.form.id : null,
                formData,
            });
            this.saving = false;
        },
    },
    async mounted() {
        if (this.id) {
            //this.form = await this.$store.dispatch('Forms/fetchForm', this.id);
            this.form = this.$store.state.Forms.byId[this.id];
            while (!this.form) {
                await new Promise(res => setTimeout(res, 250));
                this.form = this.$store.state.Forms.byId[this.id];
            }
            this.title = this.form.title;
            this.description = this.form.description;
            this.tr = deepClone(this.form.tr) || {};
            this.elements = this.form.elements;
            this.elementOrder = this.form.elementOrder;
        }

        this.selectedLang = this.$store.state.Languages.languages.filter(lang => lang.key == 'no')[0];
    },
});
</script>

<style lang="scss" scoped>
.single-form-view {
    text-align: left;
    padding: 0 7vw;

    .general {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 25px;

        .line {
            display: flex;

            span {
                width: 200px;
            }

            input {
                flex-grow: 1;
            }
        }
    }

    .elements {
        display: flex;
        flex-direction: column;
        align-items: center;

        .element {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 80vw;

            .element-row {
                border: 1px solid lightgrey;
                border-radius: 10px;
                padding: 20px;

                .element-header {
                    display: flex;
                    flex-direction: column;

                    .element-title-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        gap: 10px;

                        button {
                            width: 50px;
                            margin-right: 10px;
                        }

                        span {
                            width: 140px;
                            font-weight: bold;
                        }

                        input {
                            flex-grow: 1;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid darkgrey;
                            background: #EEE;
                            padding: 5px 15px;
                        }
                    }
                }

                .question-details {
                    border-top: 1px solid lightgrey;
                    padding-top: 20px;
                    margin-top: 20px;
                }

                .element-management {
                    border-top: 1px solid lightgrey;
                    padding-top: 20px;
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .element-options {
                        display: flex;
                        gap: 10px;

                        .element-option {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .element-required-container {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        border-left: 1px solid lightgrey;
                        padding-left: 20px;
                    }
                }
            }
        }
    }

    .save-button {
        margin-top: 20px;
        padding: 20px 0;
        width: 100%;
    }

    .add-row {
        margin: 30px 0;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;

        button {
            border: 1px solid grey;
            background: #EEE;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background: white;
            }
        }
    }
}
</style>