<template>
    <div class="index-selector">
        <div v-for="i in max" :class="{ selected: i - 1 == modelValue }" @click="$emit('update:modelValue', i - 1)">
            <div class="delete-btn" v-if="allowDelete && !disabled" @click.stop="$emit('delete', i - 1)">✖</div>
            <span>{{ i }}</span>
        </div>
        <div v-if="allowAdd && !disabled" @click="$emit('add')"><span>+</span></div>
    </div>
</template>

<script>
export default {
    name: 'IndexSelector',
    emits: ['update:modelValue', 'delete', 'add'],
    props: {
        max: {
            type: Number,
            required: true,
        },
        modelValue: {
            type: Number,
            required: true,
        },
        allowAdd: {
            type: Boolean,
            default: false,
        },
        allowDelete: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.index-selector {
    display: flex;
    gap: 10px;
    text-align: center;
    flex-wrap: wrap;

    > div {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        font-weight: bold;
        line-height: 60px;
        cursor: pointer;
        border: 1px solid grey;
        background: white;
        color: #6d6c97;

        &.selected {
            border: 1px solid #6d6c97;
            background: #6d6c97;
            color: white;
        }

        .delete-btn {
            position: absolute;
            width: 20px;
            height: 20px;
            background: #CE6868;
            line-height: 20px;
            color: white;
            top: -8px;
            right: -8px;
            font-size: 0.8em;
            border-radius: 10px;
        }
    }
}
</style>