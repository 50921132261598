import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import EmptyView from '../views/EmptyView.vue'
import PinStatsView from '../views/PinStatsView.vue'
import SuggestionsComp from '../components/treasures/SuggestionsComp.vue'
import TreasuresView from '../views/TreasuresView.vue'
import WanderingsView from '../views/WanderingsView.vue'
import AccessView from '../views/AccessView.vue'
import InviteView from '../views/InviteView.vue'
import AlertView from '../views/AlertView.vue'
import FormsView from '../views/FormsView.vue'
import EditFormView from '../views/EditFormView.vue'
import FormResponseView from '../views/FormResponseView.vue'
import FormStatsView from '../views/FormStatsView.vue'
import AreasView from '../views/AreasView.vue'
import DeleteUserView from '../views/DeleteUserView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView
    },
    {
        path: '/skatter',
        name: 'skatter',
        component: EmptyView,
        children: [
            {
                path: '',
                component: TreasuresView,
            },
            {
                path: 'forslag',
                component: SuggestionsComp,
            },
            {
                name: 'pinStatsView',
                path: ':id',
                component: PinStatsView,
                props: true,
            }
        ]
    },
    {
        path: '/vandringer',
        name: 'vandringer',
        component: EmptyView,
        children: [
            {
                path: '',
                component: WanderingsView,
            },
            {
                name: 'adventureStatsView',
                path: ':id',
                component: PinStatsView,
                props: true,
            }
        ]
    },
    {
        path: '/tilganger',
        name: 'tilganger',
        component: AccessView,
    },
    {
        path: '/invite',
        name: 'invite',
        component: InviteView,
        meta: { allowRegister: true, },
        props: route => ({ token: route.query.token, sim: route.query.sim }),
    },
    {
        path: '/varsling',
        name: 'varsling',
        component: AlertView,
    },
    {
        path: '/skjemaer',
        component: EmptyView,
        children: [
            {
                path: '',
                name: 'skjemaer',
                component: FormsView,
            },
            {
                path: 'nyttskjema',
                name: 'nyttskjema',
                component: EditFormView,
            },
            {
                path: ':id',
                component: EmptyView,
                children: [
                    {
                        path: '',
                        name: 'redigerskjema',
                        component: EditFormView,
                        props: true,
                    },
                    {
                        path: 'svar',
                        name: 'skjemasvar',
                        component: FormResponseView,
                        props: true,
                    },
                    {
                        path: 'statistikk',
                        name: 'skjemastatistikk',
                        component: FormStatsView,
                        props: true,
                    },
                ],
            },
        ],
    },
    {
        path: '/soner',
        component: EmptyView,
        children: [
            {
                path: '',
                name: 'soner',
                component: AreasView,
            },
        ],
    },


    {
        path: '/submission',
        name: 'submission',
        component: () => import('../views/Submission.vue'),
    },
    {
        path: '/slettbruker',
        component: DeleteUserView,
        meta: { bypassAuth: true },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
