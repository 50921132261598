<template>
    <StatsView :stats="stats" :main-action="isAdventure ? 'adventure-start' : 'pin-checkin'" back-button>
        <template #title>{{ name }}</template>
        <template #left>
            <div class="left-side" v-if="pin">
                <div class="time-graph-wrapper">
                    <h4>Antall besøkende</h4>
                    <time-graph :entries="subPeriods" :actions="actions" />
                </div>
                <div class="bottom-section">
                    <img :src="pin.wanderingImage" :alt="pin.wanderingImageAlt" v-if="isAdventure" />
                    <img :src="pin.image" :alt="pin.imageAlt" v-else />
                    <div class="stops" v-if="isAdventure">
                        <h4>Stopp i {{ name }}</h4>
                        <div class="stop-row header">
                            <span class="stop">Stopp</span>
                            <span class="progress">Nådd</span>
                        </div>
                        <div class="stop-row" v-for="stop in stops">
                            <span class="stop">{{ stop.pin.name }}</span>
                            <span class="progress" v-if="useRawStats">{{ stop.progress }}</span>
                            <span class="progress" v-else>{{ stop.progress }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </StatsView>
</template>

<script>
import C from '@/const'
import { isWanderingParent } from '@/types/Pin'
import StatsView from './StatsView.vue'
import TimeGraph from './PinStatsView/TimeGraph.vue'

const genders = { male: 'Menn', female: 'Kvinner', other: 'Andre' };

export default {
    components: {
        StatsView,
        TimeGraph,
    },
    props: {
        id: {
            type: String,
            required: false,
        },
    },
    computed: {
        useRawStats() {
            return this.$store.state.Stats.useRawStats;
        },
        pin() {
            return this.$store.state.Pins.pinsById[this.id];
        },
        isAdventure() {
            return this.pin && isWanderingParent(this.pin);
        },
        area() {
            return this.$store.state.Areas.selectedArea?.area;
        },
        name() {
            if (!this.pin) return '';
            if (isWanderingParent(this.pin)) return this.pin.wanderingName;
            return this.pin.name;
        },
        stats() {
            return this.$store.state.Stats.main;
        },
        subPeriods() {
            return this.$store.state.Stats.subPeriods;
        },
        actions() {
            if (isWanderingParent(this.pin)) return ['adventure-complete', 'adventure-start', 'pin-view'];
            return ['pin-checkin', 'pin-view'];
        },
        periodFilter() {
            return this.$store.state.Stats.periodFilter;
        },
        stops() {
            const count = this.$store.getters['Stats/countAction'](this.$store.state.Stats.main, 'pin-checkin');
            return [
                    { pin: this.pin, progress: this.useRawStats ? count : Math.floor(count*10000)/100 },
                    ...this.pin.wanderingChildren
                    .map(id => this.$store.state.Pins.pinsById[id])
                    .map(pin => {
                        const count = this.$store.getters['Stats/countAction'](this.$store.state.Stats.pinStats.filter(p => p.pin.id == pin.id)[0]?.stats, 'pin-checkin');
                        return {
                            pin,
                            progress: this.useRawStats ? count : Math.floor(count*10000)/100
                        };
                })
                ]
            ;
        },
    },
    methods: {
        async load() {
            this.$store.dispatch('Stats/load', 'Pin/' + this.pin.id);
            this.$store.dispatch('Stats/loadSubPeriods', 'Pin/' + this.pin.id);
            await this.$store.dispatch('Pins/ensureLoaded');
            const children = this.$store.state.Pins.pins.filter(pin => pin.wanderingParent == this.pin.id);
            this.$store.dispatch('Stats/loadPinStats', children);
        },
    },
    watch: {
        pin: {
            async handler(_, old) {
                if (!this.pin) return;
                if (old && this.pin.id == old.id) return;
                this.load();
            },
            immediate: true,
        },
        periodFilter() {
            this.load();
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.left-side {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .time-graph-wrapper {
        height: 1px;
        flex-grow: 1;
        background: white;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .time-graph {
            height: 1px;
            flex-grow: 1;
        }
    }

    .bottom-section {
        height: 1px;
        flex-grow: 1;
        display: flex;
        gap: 30px;

        img {
            height: 100%;
            width: 1px;
            object-fit: cover;
            border-radius: 10px;
            flex-grow: 1;
        }

        .stops {
            width: 1px;
            flex-grow: 1;
            background: white;
            border-radius: 10px;
            padding: 10px;
            box-sizing: border-box;
            overflow-y: auto;

            .stop-row {
                display: flex;
                text-align: left;
                gap: 10px;
                margin-bottom: 5px;

                &.header {
                    font-weight: bold;
                }

                .stop {
                    width: 1px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    flex-grow: 3;
                }

                .progress {
                    width: 1px;
                    flex-grow: 1;
                }
            }
        }
    }
}
</style>