import { Module } from 'vuex'
import { DateTime } from 'luxon'

import Stats, { ActionType, StatsPeriod, UserType, countStats } from '@/types/Stats'
import { Gender, AgeGroup } from '@/types/User';
import Firestore from '@/util/FirestoreHelper';
import { QueryCompositeFilterConstraint, QueryFilterConstraint, and, collection, getFirestore, or, query, where } from 'firebase/firestore';
import Pin, { isWanderingParent, isWanderingPin } from '@/types/Pin';

function getSubPeriod(mainPeriod: StatsPeriod): StatsPeriod {
    if (mainPeriod == 'year') return 'month';
    if (mainPeriod == 'month') return 'day';
    if (mainPeriod == 'week') return 'day';
    return 'hour';
}

function buildFilters(state: StatsState, subPeriod: boolean = false): QueryCompositeFilterConstraint {
    const mainPeriod = state.periodFilter.period;
    const selectedPeriod = subPeriod ? getSubPeriod(mainPeriod) : mainPeriod;
    //if (!period) period = state.periodFilter.period;
    const filters: (QueryFilterConstraint|QueryCompositeFilterConstraint)[] = [
        where('period', '==', selectedPeriod),
        where('year', '==', state.periodFilter.year),
    ];
    if ((mainPeriod == 'month' || mainPeriod == 'day') && state.periodFilter.month !== undefined) filters.push(where('month', '==', state.periodFilter.month));
    //if (mainPeriod == 'week' && state.periodFilter.week !== undefined) filters.push(where('week', '==', state.periodFilter.week));
    if (mainPeriod == 'week' && state.periodFilter.week !== undefined) {
        if (selectedPeriod == 'week') filters.push(where('week', '==', state.periodFilter.week));
        else {
            const date = DateTime.fromObject({ weekYear: state.periodFilter.year, weekNumber: state.periodFilter.week });
            const startOfWeek = date.startOf('week');
            const endOfWeek = date.endOf('week');
            if (startOfWeek.month != endOfWeek.month) {
                filters.push(or(
                    and(where('month', '==', startOfWeek.month), where('day', '>=', startOfWeek.day)),
                    and(where('month', '==', endOfWeek.month), where('day', '<=', endOfWeek.day)),
                ));
            } else {
                filters.push(where('day', '>=', startOfWeek.day));
                filters.push(where('day', '<=', endOfWeek.day));
            }
        }
    }
    if (mainPeriod == 'day' && state.periodFilter.day !== undefined) filters.push(where('day', '==', state.periodFilter.day));

    return and(...filters);
}

function sortStats(a: Stats, b: Stats): number {
    if (a.year != b.year) return a.year - b.year;
    if (a.month !== undefined && b.month !== undefined && a.month != b.month) return a.month - b.month;
    if (a.week  !== undefined && b.week  !== undefined && a.week  != b.week)  return a.week  - b.week;
    if (a.day   !== undefined && b.day   !== undefined && a.day   != b.day)   return a.day   - b.day;
    if (a.hour  !== undefined && b.hour  !== undefined && a.hour  != b.hour)  return a.hour  - b.hour;
    return 0;
}

interface PinWithStats {
    pin: Pin
    stats: Stats|null
}

interface StatsState {
    main: Stats|null
    subPeriods: Stats[]
    pinStats: PinWithStats[]
    genderFilter: Record<Gender, boolean>
    ageGroupFilter: Record<AgeGroup, boolean>
    userTypeFilter: Record<UserType, boolean>
    periodFilter: {
        period: Exclude<StatsPeriod, 'hour'>
        year: number
        month?: number
        week?: number
        day?: number
    }
    
    useRawStats: boolean
    statsBasis: {
        mainPath: string|null
        subPath: string|null
        pins: Pin[]|null
    }
}

const now = new Date();

const statsModule: Module<StatsState, void> = {
    namespaced: true,
    state: {
        main: null,
        subPeriods: [],
        pinStats: [],
        genderFilter: {
            male: true,
            female: true,
            other: true,
        },
        ageGroupFilter: {
            '0-19': true,
            '20-29': true,
            '30-39': true,
            '40-49': true,
            '50-59': true,
            '60-69': true,
            '70-79': true,
            '80+': true,
        },
        userTypeFilter: {
            resident: true,
            tourist: true,
        },
        periodFilter: { // TODO Better default
            period: 'month',
            year: now.getFullYear(),
            month: now.getMonth() + 1,
        },
        useRawStats: false,
        statsBasis: {
            mainPath: null,
            subPath: null,
            pins: null,
        },
    },
    mutations: {
        storeStatsBasis(state, basis: Partial<StatsState['statsBasis']>) {
            Object.assign(state.statsBasis, basis);
        },
        storeMainStats(state, stats: Stats|null) {
            state.main = stats;
        },
        storeSubPeriodStats(state, stats: Stats[]) {
            state.subPeriods = stats;
        },
        storePinStats(state, stats: PinWithStats[]) {
            state.pinStats = stats;
        },
        storePeriodFilter(state, filter: StatsState['periodFilter']) {
            state.periodFilter = filter;
        },
        storeGenderFilter(state, filter: StatsState['genderFilter']) {
            state.genderFilter = filter;
        },
        storeAgeGroupFilter(state, filter: StatsState['ageGroupFilter']) {
            state.ageGroupFilter = filter;
        },
        storeUserTypeFilter(state, filter: StatsState['userTypeFilter']) {
            state.userTypeFilter = filter;
        },
        storeUseRawStats(state, useRawStats: boolean) {
            state.useRawStats = useRawStats;
        },
    },
    actions: {
        async load({ commit, state }, path: string) {
            // Load the main period stats for an object (area or pin, at time of writing)
            commit('storeStatsBasis', { mainPath: path });
            commit('storeMainStats', null);
            const filters = buildFilters(state);

            const mainDoc = await Firestore.get<Stats>(query(
                collection(getFirestore(), `${path}/${state.useRawStats ? 'Stats' : 'PercentStats'}`),
                filters,
            ));
            commit('storeMainStats', mainDoc[0]);
        },
        async loadPinStats({ commit, state }, pins: Pin[]) {
            commit('storePinStats', []);
            commit('storeStatsBasis', { pins });
            const filters = buildFilters(state);

            const statDocs = await Promise.all(pins.map(async pin => (await Firestore.get<Stats>(query(
                collection(getFirestore(), `Pin/${pin.id}/${state.useRawStats ? 'Stats' : 'PercentStats'}`),
                filters,
            )))[0]));

            const stats: PinWithStats[] = pins.map((pin, idx) => ({
                pin,
                stats: statDocs[idx] ?? null,
            }));

            commit('storePinStats', stats);
        },
        async loadSubPeriods({ commit, state }, path: string) {
            // Load the sub-period stats for an object (area or pin, at time of writing)
            // For main period "year", subperiod is "month"
            // For main period "month", subperiod is "day"
            // For main period "week", subperiod is "day"
            // For main period "day", subperiod is "hour"
            commit('storeSubPeriodStats', []);
            commit('storeStatsBasis', { subPath: path });
            const filters = buildFilters(state, true);

            const docs = await Firestore.get<Stats>(query(
                collection(getFirestore(), `${path}/${state.useRawStats ? 'Stats' : 'PercentStats'}`),
                filters,
            ));
            docs.sort(sortStats);
            commit('storeSubPeriodStats', docs);
        },
        setPeriodFilter({ commit }, filter: StatsState['periodFilter']) {
            commit('storePeriodFilter', filter);
        },
        setGenderFilter({ commit }, filter: StatsState['genderFilter']) {
            commit('storeGenderFilter', filter);
        },
        setAgeGroupFilter({ commit }, filter: StatsState['ageGroupFilter']) {
            commit('storeAgeGroupFilter', filter);
        },
        setUserTypeFilter({ commit }, filter: StatsState['userTypeFilter']) {
            commit('storeUserTypeFilter', filter);
        },
        setUseRawStats({ state, commit, dispatch }, useRawStats: boolean) {
            commit('storeUseRawStats', useRawStats);
            const promises: Promise<void>[] = [];

            if (state.statsBasis.mainPath) promises.push(dispatch('load', state.statsBasis.mainPath));
            if (state.statsBasis.pins) promises.push(dispatch('loadPinStats', state.statsBasis.pins));
            if (state.statsBasis.subPath) promises.push(dispatch('loadSubPeriods', state.statsBasis.subPath));

            return Promise.all(promises);
        },
    },
    getters: {
        counts: state => (actionType: ActionType) => {
            const counts: {
                gender: Record<Gender, number>
                ageGroup: Record<AgeGroup, number>
                userType: Record<UserType, number>
            } = {
                gender: {
                    male: 0,
                    female: 0,
                    other: 0,
                },
                ageGroup: {
                    '0-19': 0,
                    '20-29': 0,
                    '30-39': 0,
                    '40-49': 0,
                    '50-59': 0,
                    '60-69': 0,
                    '70-79': 0,
                    '80+': 0,
                },
                userType: {
                    resident: 0,
                    tourist: 0,
                },
            };

            if (!state.main) return counts;
            if (!state.main.actions[actionType]) return counts;

            for (let gender in state.main.actions[actionType]) {
                if (!state.genderFilter[gender as Gender]) continue;
                for (let ageGroup in state.main.actions[actionType]![gender as Gender]) {
                    if (!state.ageGroupFilter[ageGroup as AgeGroup]) continue;
                    for (let userType in state.main.actions[actionType]![gender as Gender]![ageGroup as AgeGroup]) {
                        const count = state.main.actions[actionType]![gender as Gender]![ageGroup as AgeGroup]![userType as UserType]!;
                        counts.gender[gender as Gender] += count;
                        counts.ageGroup[ageGroup as AgeGroup] += count;
                        counts.userType[userType as UserType] += count;
                    }
                }
            }

            return counts;
        },
        ratios: state => (actionType: ActionType) => {
            const ratios: {
                gender: Record<Gender, number>
                ageGroup: Record<AgeGroup, number>
                userType: Record<UserType, number>
            } = {
                gender: {
                    male: 0,
                    female: 0,
                    other: 0,
                },
                ageGroup: {
                    '0-19': 0,
                    '20-29': 0,
                    '30-39': 0,
                    '40-49': 0,
                    '50-59': 0,
                    '60-69': 0,
                    '70-79': 0,
                    '80+': 0,
                },
                userType: {
                    resident: 0,
                    tourist: 0,
                },
            };

            let total = 0;

            if (!state.main) return ratios;
            if (!state.main.actions[actionType]) return ratios;

            for (let gender in state.main.actions[actionType]) {
                if (!state.genderFilter[gender as Gender]) continue;
                for (let ageGroup in state.main.actions[actionType]![gender as Gender]) {
                    if (!state.ageGroupFilter[ageGroup as AgeGroup]) continue;
                    for (let userType in state.main.actions[actionType]![gender as Gender]![ageGroup as AgeGroup]) {
                        const count = state.main.actions[actionType]![gender as Gender]![ageGroup as AgeGroup]![userType as UserType]!;
                        ratios.gender[gender as Gender] += count;
                        ratios.ageGroup[ageGroup as AgeGroup] += count;
                        ratios.userType[userType as UserType] += count;
                        total += count;
                    }
                }
            }

            for (let gender in ratios.gender) {
                ratios.gender[gender as Gender] /= total;
            }

            for (let ageGroup in ratios.ageGroup) {
                ratios.ageGroup[ageGroup as AgeGroup] /= total;
            }

            for (let userType in ratios.userType) {
                ratios.userType[userType as UserType] /= total;
            }

            return ratios;
        },
        topTreasures(state) {
            const withCounts = state.pinStats.filter(pin => pin.pin.published && !isWanderingPin(pin.pin)).map(pin => {
                let active;
                if (state.useRawStats) {
                    active = 1;
                } else {
                    active = countStats(pin.stats, 'user-active', state.genderFilter, state.ageGroupFilter, state.userTypeFilter);
                    if (active == 0) return {
                        treasure: pin.pin.id,
                        checkins: 0,
                    }
                }
                return {
                    treasure: pin.pin.id,
                    checkins: countStats(pin.stats, 'pin-checkin', state.genderFilter, state.ageGroupFilter, state.userTypeFilter) / active,
                };
            });
            withCounts.sort((a, b) => b.checkins - a.checkins);
            return withCounts.slice(0, 5);
        },
        topAdventures(state) {
            const withCounts = state.pinStats.filter(pin => pin.pin.published && isWanderingParent(pin.pin)).map(pin => {
                let active;
                if (state.useRawStats) {
                    active = 1;
                } else {
                    active = countStats(pin.stats, 'user-active', state.genderFilter, state.ageGroupFilter, state.userTypeFilter);
                    if (active == 0) return {
                        adventure: pin.pin.id,
                        started: 0,
                        completed: 0,
                    }
                }
                return {
                    adventure: pin.pin.id,
                    started: countStats(pin.stats, 'adventure-start', state.genderFilter, state.ageGroupFilter, state.userTypeFilter) / active,
                    completed: countStats(pin.stats, 'adventure-complete', state.genderFilter, state.ageGroupFilter, state.userTypeFilter) / active,
                };
            });
            withCounts.sort((a, b) => b.started - a.started);
            return withCounts.slice(0, 5);
        },
        countAction: state => (stats: Stats, action: ActionType) => {
            if (state.useRawStats) return countStats(stats, action as ActionType, state.genderFilter, state.ageGroupFilter, state.userTypeFilter);
            const active = countStats(stats, 'user-active', state.genderFilter, state.ageGroupFilter, state.userTypeFilter);
            if (active == 0) return 0;
            return countStats(stats, action as ActionType, state.genderFilter, state.ageGroupFilter, state.userTypeFilter) / active;
        },
    },
}

export default statsModule;