<template>
    <message-box v-if="terms" @close="terms = false">
        <template #title>Vilkår</template>
        <template #default>
            Ved å bruke denne siden godkjenner du at du som representant for
            kommunen tar ansvar for innholdet som du og dine representanter som
            du inviterer inn i løsningen publiserer i Kulturjakten. Link
            Utvikling AS er ansvarlig for den teknologiske løsningen.
        </template>
    </message-box>
    <footer>
        <a @click="terms = true">Vilkår for bruk</a>
        <p>Support - mail@linkutvikling.no</p>
        <p>Copyright © {{ year }} Link Utvikling AS</p>
    </footer>
</template>

<script>
import MessageBox from "./MessageBox.vue"

export default {
    components: { MessageBox, },
    data() {
        return {
            terms: false,
        }
    },
    computed: {
        area() {
            return this.$store.state.Areas.selectedArea?.area;
        },
        year() {
            return new Date().getFullYear();
        },
    }
}
</script>


<style scoped> 
footer {
    padding: 25px;
}
a {
    font-size: 13px;
    cursor: pointer;
    /* color: #6D6C97; */
    text-decoration: underline;
}
p {
    font-size: 13px;
    color: #161616;
}
</style>