import {
    CollectionReference,
    DocumentReference,
    DocumentSnapshot,
    Query,
    Unsubscribe,
    Transaction,
    FieldValue,
    DocumentData,

    getFirestore,

    collection,
    doc,
    onSnapshot,
    getDoc,
    getDocs,
    addDoc,
    setDoc,
    updateDoc,
    getCountFromServer,
    documentId,
    query,
    where,
    deleteDoc,
} from 'firebase/firestore'

import FirestoreType from '@/types/FirestoreType'

const PROMISE = Symbol();
const RESOLVE = Symbol();
const RESOLVED = Symbol();
const LISTENERS = Symbol();
const ONVALUE = Symbol();

function toPlain<T extends FirestoreType>(snapshot: DocumentSnapshot): T|null {
    if (!snapshot.exists()) return null;
    return Object.assign({ id: snapshot.id, path: snapshot.ref.path }, snapshot.data()) as T;
}

export abstract class Listener<T> implements PromiseLike<T> {
    abstract unsubscribe: Unsubscribe;
    value?: T;
    [PROMISE]: Promise<T>;
    [RESOLVE]!: (val: T|PromiseLike<T>) => void;
    [RESOLVED]: boolean = false;
    [LISTENERS]: Set<(val: T) => void> = new Set();

    constructor() {
        this[PROMISE] = new Promise(res => {
            this[RESOLVE] = res;
        });
    }

    then(): Promise<void>
    then<U>(onfulfilled: ((value: T) => U|PromiseLike<U>)|null): Promise<U>
    then<U, V>(onfulfilled?: ((value: T) => U|PromiseLike<U>)|null, onrejected?: ((reason: any) => V|PromiseLike<V>)|null): Promise<U|V>
    then<U, V>(onfulfilled?: ((value: T) => U|PromiseLike<U>)|null, onrejected?: ((reason: any) => V|PromiseLike<V>)|null): Promise<U|V> {
        return this[PROMISE].then(onfulfilled, onrejected);
    }

    onValue(callback: (val: T) => void) {
        this[LISTENERS].add(callback);
    }

    [ONVALUE](val: T) {
        for (let listener of this[LISTENERS]) {
            listener(val);
        }
        if (!this[RESOLVED]) {
            this[RESOLVED] = true;
            this[RESOLVE](val);
        }
    }
}

class DocumentListener<T extends FirestoreType> extends Listener<T|null> {
    unsubscribe: Unsubscribe;

    constructor(ref: DocumentReference) {
        super();

        this.unsubscribe = onSnapshot(ref, snapshot => {
            this.value = toPlain(snapshot);
            this[ONVALUE](this.value);
        });
    }
}

class QueryListener<T extends FirestoreType> extends Listener<T[]> {
    unsubscribe: Unsubscribe;

    constructor(q: Query) {
        super();

        this.unsubscribe = onSnapshot(q, querySnapshot => {
            this.value = querySnapshot.docs.map<T>(toPlain as (snapshot: DocumentSnapshot) => T);
            this[ONVALUE](this.value);
        });
    }
}

/*export type UpdateType<T> = {
    [k in keyof T as Exclude<k, 'id'|'path'>]: T[k]|FieldValue
}*/

type UpdateObjectType<T> = T extends {} ? { [key in keyof T]: T[key]|FieldValue } : T

/**
 * Types of values allowed to be used for updates
 */
export type UpdateType<T> = {
    [k in keyof T as Exclude<k, 'id'|'path'>]: UpdateObjectType<T[k]>|FieldValue
}

export default class Firestore {
    /**
     * Get a listener for a single document. A callback may be provided with the call, or may be attached later with
     * listener.onValue(callback). If the document does not exist, a null value is provided to the callback.
     */
    static listen<T extends FirestoreType>(arg: string|DocumentReference, callback?: (val: T|null) => void): Listener<T|null>

    /**
     * Get a listener for a query result. A callback may be provided with the call, or may be attached later with
     * listener.onValue(callback).
     */
    static listen<T extends FirestoreType>(arg: Query, callback?: (val: T[]) => void): Listener<T[]>
    static listen<T extends FirestoreType>(arg: string|DocumentReference|Query, callback?: (val: T|null|T[]) => void): Listener<T|null>|Listener<T[]> {
        if (arg instanceof Query) return this.listenToQuery<T>(arg, callback);
        return this.listenToDocument<T>(arg, callback);
    }

    /**
     * Get a listener for a single document. A callback may be provided with the call, or may be attached later with
     * listener.onValue(callback). If the document does not exist, a null value is provided to the callback.
     */
    static listenToDocument<T extends FirestoreType>(arg: string|DocumentReference, callback?: (val: T|null) => void): Listener<T|null> {
        const db = getFirestore();
        const ref = arg instanceof DocumentReference ? arg : doc(db, arg);
        const listener = new DocumentListener<T>(ref);
        if (callback) listener.onValue(callback);
        return listener;
    }
    /**
     * Get a listener for a query result. A callback may be provided with the call, or may be attached later with
     * listener.onValue(callback).
     */
    static listenToQuery<T extends FirestoreType>(arg: Query, callback?: (val: T[]) => void): Listener<T[]> {
        const listener = new QueryListener<T>(arg);
        if (callback) listener.onValue(callback);
        return listener;
    }

    /**
     * Check whether a document exists or not. This is a helper method that calls countOne() and checks the result, but
     * which has a more semantically appropriate name for most cases where you'd want to do this. By doing a count query
     * instead of a get query, this avoids fetching the contents of the document, which may matter if the document is
     * potentially large.
     */
    static async exists(arg: string|DocumentReference): Promise<boolean> {
        const count = await this.countOne(arg);
        return count == 1;
    }

    /**
     * Count documents. For a string (document path) or document reference, this is either 0 or 1. For an array of such,
     * this will count how many of those documents exist. For a collection reference or query, this will count the
     * documents in the collection or query, respectively. Note that for an array of strings or references, this will
     * run one query for each entry, which may save on data transfer for just counting but will not save on document
     * read costs like a single count query would. For collection reference or query counts, this will run a single
     * count query, which costs exactly what you would expect.
     */
    static count(arg: string|DocumentReference|(string|DocumentReference)[]|CollectionReference|Query): Promise<number> {
        if (Array.isArray(arg)) return this.countMany(arg);
        if (arg instanceof Query) return this.countForQuery(arg);
        if (arg instanceof CollectionReference) return this.countCollection(arg);
        return this.countOne(arg);
    }

    /**
     * Count one document. This is in practice an existence check by another name, but has been given this name for
     * consistency purposes.
     */
    static async countOne(arg: string|DocumentReference): Promise<number> {
        const db = getFirestore();
        const ref = arg instanceof DocumentReference ? arg : doc(db, arg);
        const count = await getCountFromServer(query(ref.parent, where(documentId(), '==', ref.id)));
        return count.data().count;
    }

    /**
     * Count how many documents from an array exist. Note that this will run a count query for each, which may save on
     * data transfer but will not have the reduced document read costs of single count queries. Prefer counting with a
     * collection reference or query if possible.
     */
    static async countMany(arg: (string|DocumentReference)[]): Promise<number> {
        const counts = await Promise.all(arg.map(this.countOne));
        return counts.reduce((a, n) => a + n);
    }

    /**
     * Count the number of documents in a collection. This is a count query, which will save on both data transfer costs
     * and document read costs.
     */
    static async countCollection(arg: string|CollectionReference): Promise<number> {
        const ref = arg instanceof CollectionReference ? arg : collection(getFirestore(), arg);
        const count = await getCountFromServer(query(ref));
        return count.data().count;
    }

    /**
     * Count the number of documents in a query. This is a count query, which will save on both data transfer costs and
     * document read costs.
     */
    static async countForQuery(arg: Query): Promise<number> {
        const count = await getCountFromServer(arg);
        return count.data().count;
    }

    static get<T extends FirestoreType>(arg: string|DocumentReference): Promise<T|null>
    static get<T extends FirestoreType>(arg: (string|DocumentReference)[]): Promise<(T|null)[]>
    static get<T extends FirestoreType>(arg: CollectionReference|Query): Promise<T[]>
    static get<T extends FirestoreType>(arg: string|DocumentReference|(string|DocumentReference)[]|CollectionReference|Query): Promise<T|null>|Promise<(T|null)[]>|Promise<T[]> {
        if (Array.isArray(arg)) return this.getMany(arg);
        if (arg instanceof Query) return this.getForQuery(arg) as Promise<T[]>;
        if (arg instanceof CollectionReference) return this.getFromCollection(arg) as Promise<T[]>;
        return this.getOne<T>(arg);
    }

    static async getOne<T extends FirestoreType>(arg: string|DocumentReference): Promise<T|null> {
        const db = getFirestore();
        const ref = arg instanceof DocumentReference ? arg : doc(db, arg);
        const snapshot = await getDoc(ref);
        if (!snapshot.exists()) return null;
        return toPlain<T>(snapshot);
    }

    static async getMany<T extends FirestoreType>(arg: (string|DocumentReference)[]): Promise<(T|null)[]> {
        const db = getFirestore();
        const refs = arg.map(val => val instanceof DocumentReference ? val : doc(db, val));
        const snapshots = await Promise.all(refs.map(ref => getDoc(ref)));
        return snapshots.map<T|null>(toPlain);
    }

    static async getFromCollection<T extends FirestoreType>(arg: string|CollectionReference): Promise<T[]> {
        const ref = arg instanceof CollectionReference ? arg : collection(getFirestore(), arg);
        const querySnapshot = await getDocs(ref);
        return querySnapshot.docs.map<T>(toPlain as (snapshot: DocumentSnapshot) => T);
    }

    static async getForQuery<T extends FirestoreType>(arg: Query): Promise<T[]> {
        const querySnapshot = await getDocs(arg);
        return querySnapshot.docs.map<T>(toPlain as (snapshot: DocumentSnapshot) => T);
    }

    static async add<T extends FirestoreType>(arg: string|CollectionReference, data: UpdateType<T> & DocumentData, tr?: Transaction): Promise<T> {
        if (tr) {
            const ref = doc(arg instanceof CollectionReference ? arg : collection(getFirestore(), arg));
            tr.set(ref, data);
            return Object.assign({ id: ref.id, path: ref.path }, data) as unknown as T;
        } else {
            const ref = arg instanceof CollectionReference ? arg : collection(getFirestore(), arg);
            let doc = await addDoc(ref, data);
            return Object.assign({ id: doc.id, path: doc.path }, data) as unknown as T;
        }
    }

    static set<T extends FirestoreType>(arg: string|DocumentReference|T, data: UpdateType<T>, tr?: Transaction): Promise<T> {
        return this.setRaw(arg, data, tr);
    }

    static update<T extends FirestoreType>(arg: string|DocumentReference, data: Partial<UpdateType<T>>, tr?: Transaction): Promise<FirestoreType>
    static update<T extends FirestoreType>(arg: T, data: Partial<UpdateType<T>>, tr?: Transaction): Promise<T>
    static update<T extends FirestoreType>(arg: string|DocumentReference|T, data: Partial<UpdateType<T>>, tr?: Transaction): Promise<T|FirestoreType> {
        return this.updateRaw(arg, data, tr);
    }

    static merge<T extends FirestoreType>(arg: string|DocumentReference, data: Partial<UpdateType<T>>, tr?: Transaction): Promise<FirestoreType>
    static merge<T extends FirestoreType>(arg: T, data: Partial<UpdateType<T>>, tr?: Transaction): Promise<T>
    static merge<T extends FirestoreType>(arg: string|DocumentReference|T, data: Partial<UpdateType<T>>, tr?: Transaction): Promise<T|FirestoreType> {
        return this.mergeRaw(arg, data, tr);
    }

    static async delete<T extends FirestoreType>(arg: string|DocumentReference|T, tr?: Transaction): Promise<void> {
        const ref = arg instanceof DocumentReference ? arg : typeof arg == 'string' ? doc(getFirestore(), arg) : doc(getFirestore(), arg.path);
        await (tr ? tr!.delete(ref) : deleteDoc(ref));
    }

    static async setRaw<T extends FirestoreType>(arg: string|DocumentReference|T, data: { [key: string]: any }, tr?: Transaction): Promise<T> {
        const ref = arg instanceof DocumentReference ? arg : typeof arg == 'string' ? doc(getFirestore(), arg) : doc(getFirestore(), arg.path);
        await (tr ? tr!.set(ref, data) : setDoc(ref, data));
        return Object.assign({ id: ref.id, path: ref.path }, data) as unknown as T;
    }

    static updateRaw<T extends FirestoreType>(arg: string|DocumentReference, data: { [key: string]: any }, tr?: Transaction): Promise<FirestoreType>
    static updateRaw<T extends FirestoreType>(arg: T, data: { [key: string]: any }, tr?: Transaction): Promise<T>
    static updateRaw<T extends FirestoreType>(arg: string|DocumentReference|T, data: { [key: string]: any }, tr?: Transaction): Promise<T|FirestoreType>
    static async updateRaw<T extends FirestoreType>(arg: string|DocumentReference|T, data: { [key: string]: any }, tr?: Transaction): Promise<T|FirestoreType> {
        const ref = arg instanceof DocumentReference ? arg : typeof arg == 'string' ? doc(getFirestore(), arg) : doc(getFirestore(), arg.path);
        await (tr ? tr!.update(ref, data) : updateDoc(ref, data));
        if (typeof arg != 'string' && !(arg instanceof DocumentReference)) {
            return Object.assign({ id: ref.id, path: ref.path }, arg, data) as unknown as T;
        } else {
            return { id: ref.id, path: ref.path };
        }
    }

    static mergeRaw<T extends FirestoreType>(arg: string|DocumentReference, data: { [key: string]: any }, tr?: Transaction): Promise<FirestoreType>
    static mergeRaw<T extends FirestoreType>(arg: T, data: { [key: string]: any }, tr?: Transaction): Promise<T>
    static mergeRaw<T extends FirestoreType>(arg: string|DocumentReference|T, data: { [key: string]: any }, tr?: Transaction): Promise<T|FirestoreType>
    static async mergeRaw<T extends FirestoreType>(arg: string|DocumentReference|T, data: { [key: string]: any }, tr?: Transaction): Promise<T|FirestoreType> {
        const ref = arg instanceof DocumentReference ? arg : typeof arg == 'string' ? doc(getFirestore(), arg) : doc(getFirestore(), arg.path);
        await (tr ? tr!.set(ref, data, { merge: true }) : setDoc(ref, data, { merge: true }));
        if (typeof arg != 'string' && !(arg instanceof DocumentReference)) {
            return Object.assign({ id: ref.id, path: ref.path }, arg, data) as unknown as T;
        } else {
            return { id: ref.id, path: ref.path };
        }
    }
}
