<template>
    <TextQuestion v-if="element.type == 'q-text'" :element="element" :language="language" @update-raw="$emit('update-raw', $event)" @update-tr="$emit('update-tr', $event)" />
    <NumberQuestion v-if="element.type == 'q-number'" :element="element" :language="language" @update-raw="$emit('update-raw', $event)" @update-tr="$emit('update-tr', $event)" />
    <MultipleChoiceQuestion v-if="element.type == 'q-multiple'" :element="element" :language="language" @update-raw="$emit('update-raw', $event)" @update-tr="$emit('update-tr', $event)" />
    <MultipleChoiceGridQuestion v-if="element.type == 'q-multiple-grid'" :element="element" :language="language" @update-raw="$emit('update-raw', $event)" @update-tr="$emit('update-tr', $event)" />
    <AreaQuestion v-if="element.type == 'q-area'" :element="element" :language="language" @update-raw="$emit('update-raw', $event)" @update-tr="$emit('update-tr', $event)" />
</template>

<script>
import { defineComponent } from 'vue'

import TextQuestion from './TextQuestion.vue'
import NumberQuestion from './NumberQuestion.vue'
import MultipleChoiceQuestion from './MultipleChoiceQuestion.vue'
import MultipleChoiceGridQuestion from './MultipleChoiceGridQuestion.vue'
import AreaQuestion from './AreaQuestion.vue'

export default defineComponent({
    name: 'QuestionWrapper',
    emits: ['update-raw', 'update-tr'],
    components: {
        TextQuestion,
        NumberQuestion,
        MultipleChoiceQuestion,
        MultipleChoiceGridQuestion,
        AreaQuestion,
    },
    props: {
        element: {
            type: Object,
            required: true,
        },
        language: {
            type: Object,
            required: false,
        },
    },
});
</script>