<template>
    <div class="multiple-selection">
        <toggle-switch :modelValue="element.multipleSelection" @update:modelValue="update('multipleSelection', $event)"></toggle-switch>
        <span>Tillat valg av flere</span>
    </div>

    <table>
        <tr>
            <td></td>
            <th v-for="col in element.columnsOrder">
                <div class="tbl-header">
                    <input type="text" :value="columns[col]" @input="updateOption('columns', col, $event.target.value)" />
                    <div class="remove-button" @click="removeOption('columns', col)"><div></div></div>
                </div>
            </th>
            <td>
                <div class="add-button" @click="addOption('columns')"><div></div></div>
            </td>
        </tr>
        <tr v-for="(row, x) in element.rowsOrder">
            <td>
                <div class="tbl-header">
                    <input type="text" :value="rows[row]" @input="updateOption('rows', row, $event.target.value)" />
                    <div class="remove-button" @click="removeOption('rows', row)"><div></div></div>
                </div>
            </td>
            <td v-for="(col, y) in element.columnsOrder">
                <div class="radio-cell">
                    <input type="checkbox" disabled :checked="(x+1)%(y+1)==0" v-if="element.multipleSelection" />
                    <input type="radio" disabled :checked="x == 0 && y == 0" v-else />
                </div>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <div class="add-row-wrapper">
                    <div class="add-button add-row" @click="addOption('rows')"><div></div></div>
                </div>
            </td>
            <td v-for="(col, y) in element.columnsOrder"></td>
            <td></td>
        </tr>
    </table>
</template>

<script>
import { defineComponent } from 'vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default defineComponent({
    name: 'MultipleChoiceGridQuestion',
    components: {
        ToggleSwitch,
    },
    emits: ['update-raw', 'update-tr'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        language: {
            type: Object,
            required: false,
        },
    },
    computed: {
        rows() {
            if (this.language && this.language.key != 'no') {
                if (this.element.tr && this.element.tr[this.language.key] && this.element.tr[this.language.key].rows) {
                    return this.element.tr[this.language.key].rows;
                }
                return [];
            }
            return this.element.rows;
        },
        columns() {
            if (this.language && this.language.key != 'no') {
                if (this.element.tr && this.element.tr[this.language.key] && this.element.tr[this.language.key].columns) {
                    return this.element.tr[this.language.key].columns;
                }
                return [];
            }
            return this.element.columns;
        },
    },
    methods: {
        update(name, value) {
            this.$emit('update-raw', { [name]: value });
        },
        addOption(type) {
            let len = this.element[`${type}Order`].length;
            while (this[type][`o${len}`]) ++len;
            //const copy = {};
            //for (let k in this[type]) copy[k] = this.element[type][k];
            this.$emit('update-raw', { [`${type}Order`]: this.element[`${type}Order`].concat([`o${len}`]) });
        },
        removeOption(type, id) {
            //const copy = {};
            //for (let k in this.element[type]) if (k != id) copy[k] = this.element[type][k];
            this.$emit('update-raw', { [`${type}Order`]: this.element[`${type}Order`].filter(opt => opt != id) });
        },
        updateOption(type, id, value) {
            const copy = {};
            for (let k in this[type]) copy[k] = this[type][k];
            copy[id] = value;
            //this.update(type, copy);
            this.$emit('update-tr', { [type]: copy })
        },
    },
});
</script>

<style lang="scss" scoped>
.multiple-selection {
    display: flex;
    align-items: center;
    gap: 10px;
}

table {
    margin-top: 20px;
}

.tbl-header {
    display: flex;
    gap: 5px;
    margin: 10px 30px 10px 0;

    input {
        width: 100px;
    }
}

.radio-cell {
    width: 100px;
    display: flex;
    justify-content: center;
}

.add-row-wrapper {
    margin-top: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
}

.remove-button, .add-button {
    background: lightgrey;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: red;
    }

    > div {
        background-color: white;
        clip-path: polygon(25% 0, 50% 25%, 75% 0, 100% 25%, 75% 50%, 100% 75%, 75% 100%, 50% 75%, 25% 100%, 0 75%, 25% 50%, 0 25%);
        width: 10px;
        height: 10px;
    }
}

.add-button {
    transform: rotate(45deg);

    &:hover {
        background: #6d6c97;
    }
}
</style>