export default function hasChanged(from: any, to: any): boolean {
    if (typeof from != typeof to) return true;
    if (typeof from != 'object') return from != to;
    if (from === null) return to !== null;

    if (Array.isArray(from)) {
        if (!Array.isArray(to)) return true;
        if (from.length != to.length) return true;
        for (let i = 0; i < from.length; ++i) {
            if (hasChanged(from[i], to[i])) return true;
        }
        return false;
    }

    if (hasChanged(Object.keys(from).sort(), Object.keys(to).sort())) return true;
    for (let k in from) {
        if (hasChanged(from[k], to[k])) return true;
    }

    return false;
}