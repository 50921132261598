<template>
    <div class="toggle-switch" :class="{ toggled: modelValue }" @click="$emit('update:modelValue', !modelValue)">
        <div class="enabled-background">
            <div class="button"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ToggleSwitch',
    props: {
        modelValue: {
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.toggle-switch {
    $width: 60px;
    $height: 25px;

    display: inline-block;
    position: relative;
    height: $height;
    width: $width;
    background: lightgrey;
    border-radius: 1000px;
    cursor: pointer;

    .enabled-background {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: $height;
        background: #6d6c97;
        border-radius: 1000px;
        transition: width 0.2s;

        .button {
            position: absolute;
            width: $height;
            height: $height;
            background: white;
            right: 0px;
            top: 0;
            border-radius: 100%;
            border: 1px solid darkgrey;
            box-sizing: border-box;
            transform: scale(1.1);
        }
    }

    &.toggled .enabled-background {
        width: $width;
    }
}
</style>