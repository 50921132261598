<template>
    <NumberQuestion v-if="question.type == 'q-number'" :question="question" :answers="answers" />
    <MultipleChoiceQuestion v-if="question.type == 'q-multiple'" :question="question" :answers="answers" />
    <MultipleChoiceGridQuestion v-if="question.type == 'q-multiple-grid'" :question="question" :answers="answers" />
</template>

<script>
import { defineComponent } from 'vue'

import NumberQuestion from './NumberQuestion.vue'
import MultipleChoiceQuestion from './MultipleChoiceQuestion.vue'
import MultipleChoiceGridQuestion from './MultipleChoiceGridQuestion.vue'

export default defineComponent({
    name: 'QuestionWrapper',
    components: {
        NumberQuestion,
        MultipleChoiceQuestion,
        MultipleChoiceGridQuestion,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        answers: {
            type: Object,
            required: true,
        },
    },
});
</script>