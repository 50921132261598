<template>
    <main-wrapper>
        <div class="invite-info" v-if="inviteId && area">
            <div class="expired" v-if="expired">Denne invitasjonen har utløpt.</div>
            <div v-else>
                <h2>Du har blitt invitert til å administrere {{ area.fullName }}</h2>
                <table>
                    <tr>
                        <th>Rolle</th>
                        <td>{{ roleName }}</td>
                    </tr>
                    <tr>
                        <th>Invitert av</th>
                        <td>{{ invitedBy }}</td>
                    </tr>
                </table>
                <div class="disclaimer">
                    Ved å godkjenne invitasjon godkjenner du at du som representant for {{ area.fullName }} tar ansvar
                    for innholdet som publiseres i Kulturjakten. Link Utvikling AS er ansvarlig for den teknologiske
                    løsningen, og du er ansvarlig for innholdet som blir delt med brukerne.
                </div>
                <process-button v-model="accepting" :process="accept" :class="{ saving: saving }"><template v-slot="{ processing }">{{ processing ? 'Sender...' : 'Godta' }}</template></process-button>
                <process-button v-model="declining" :process="decline" :class="{ saving: saving }"><template v-slot="{ processing }">{{ processing ? 'Sender...' : 'Avvis' }}</template></process-button>
            </div>
        </div>
    </main-wrapper>
</template>

<script>
import Parse from 'parse'
import jwtdecode from 'jwt-decode'

import Area from '../types/Area'

import ProcessButton from '@/components/ProcessButton.vue'

export default {
    name: 'InviteView',
    components: {
        ProcessButton,
    },
    props: {
        token: {
            type: String,
            required: false,
        },
        sim: { // Used to simulate an invite without a token, to avoid having to create a token to look at the page
            default: null,
        },
    },
    data: () => ({
        inviteId: null,
        inviteeEmail: null,
        invitedBy: null,
        role: null,
        area: null,
        expired: false,
        accepting: false,
        declining: false,
    }),
    computed: {
        roleName() {
            if (this.role == 'admin') return 'Admin';
            if (this.role == 'editor') return 'Redaktør';
            if (this.role == 'viewer') return 'Leser';
            return '';
        },
        saving() {
            return this.accepting || this.declining;
        },
    },
    methods: {
        async accept() {
            if (this.saving) return;
            if (this.sim) {
                await new Promise(res => setTimeout(res, 2000));
                alert('Simulated: You have accepted the role.');
            } else {
                await this.$store.dispatch('Roles/accept', this.token);
                window.location.href = '/'; // Force a proper reload
            }
        },
        async decline() {
            if (this.saving) return;
            if (this.sim) {
                await new Promise(res => setTimeout(res, 2000));
                alert('Simulated: You have declined the role.');
            } else {
                await this.$store.dispatch('Roles/decline', this.token);
                window.location.href = '/'; // Force a proper reload
            }
        },
    },
    async mounted() {
        if (this.token) {
            let {
                invite,
                inviteeEmail,
                invitedBy,
                role,
                areaId,
                exp,
            } = jwtdecode(this.token);
            this.inviteId = invite;
            this.inviteeEmail = inviteeEmail;
            this.invitedBy = invitedBy;
            this.role = role;

            this.area = await this.$store.dispatch('Areas/reloadArea', areaId);

            if (exp * 1000 < new Date().getTime()) this.expired = true;
        } else if (this.sim) {
            this.inviteId = 'sim';
            this.inviteeEmail = 'test@example.com';
            this.invitedBy = 'sim';
            this.role = 'admin';

            this.area = await this.$store.dispatch('Areas/randomArea');
        } else {
            console.log('No token.');
        }
    },
}
</script>

<style lang="scss" scoped>
table {
    margin: 20px auto;

    th {
        padding: 5px 20px;
        text-align: left;
    }

    td {
        padding: 5px 20px;
        text-align: right;
    }
}

button {
    border-radius: 25px;
    border: none;
    padding: .75em 1.5em;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: capitalize;
    cursor: pointer;
    background: #6d6c97;
    color: white;
    margin: 0 10px;

    &.saving {
        opacity: 0.6;
    }
}

.disclaimer {
    max-width: 600px;
    margin: 20px auto;
}
</style>