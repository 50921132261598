<template>
    <span 
        class="test-info" 
        :data-hover="info ? info : showText"
    >?</span>
</template>
<script>
export default {
    props: ['info', 'helpType', 'what'],
    computed: {
        showText() {
            if(this.helpType === 'title') {
                return `Her skriver du tittelen på ${this.what}.`;
            } else if(this.helpType === 'description') {
                return `Her skriver du en beskrivelse om ${this.what}.`;
            } else if(this.helpType === 'address') {
                return `Her skriver du inn en adresse eller plusskode for ${this.what}. Du kan også trykke på kartet for å justere plasseringen.`;
            } else if(this.helpType === 'uploadImg') {
                return `Her kan du laste opp et bilde (anbefalt horisontalt bilde) for ${this.what}.`;
            } else if(this.helpType === 'linkImg') {
                return `Her kan du skrive inn en lenke til et bilde (anbefalt horisontalt bilde) for ${this.what}.`;
            } else if(this.helpType === 'sources') {
                return `Her skriver du inn kilder til informasjonen brukt i ${this.what}.`;
            } else {
                return "whoops"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.test-info {
    font-style: italic;
    //text-decoration: underline dotted;
    /* border: dotted 1px black; */
    /* padding: 2px 4px 2px 0; */
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}
.test-info::after, .test-info::before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    font-size: 16px;
    width: max-content;
    max-width: 400px;
    text-align: center;
    transition: opacity .2s ease-in-out;
    clip-path: polygon(98% 0, 100% 50%, 98% 100%, 0 100%, 0 0);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}
.test-info::after {
    color: gray;
    background-color: gray;
    padding: 6px 15px 6px 17px;
    z-index: 1;
    right: 10px;
}
.test-info::before {
    background-color: white;
    color: gray;
    padding: 5px 15px 5px 15px;
    z-index: 2;
    right: 11px;
}
.test-info:hover::before, .test-info:hover::after {
    opacity: 1;
    visibility: visible;
}
</style>