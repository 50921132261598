import { Module } from 'vuex'
import Form, { FormStats } from '@/types/Form'
import FormResponse from '@/types/FormResponse'
import { AuthState } from './Auth'
import { AreasState, AreaInfo } from './Areas'
import Firestore, { Listener, UpdateType } from '@/util/FirestoreHelper'
import { QueryConstraint, getFirestore, query, collection, where, orderBy, startAfter, limit, serverTimestamp } from 'firebase/firestore'
import FirestoreHelper from '@/util/FirestoreHelper'

const PAGE_SIZE = 25;

export interface FormsState {
    forms: Form[]
    byId: { [formId: string]: Form }
    responses: { [formId: string]: { list: FormResponse[], hasMore: boolean } }
    totalUnreadCount: number
}

let formsSubscription: Listener<Form[]>|null = null;

const formsModule: Module<FormsState, { Auth: AuthState, Areas: AreasState }> = {
    namespaced: true,
    state: {
        forms: [],
        byId: {},
        responses: {},
        totalUnreadCount: 0,
    },
    mutations: {
        storeForms(state, forms: Form[]) {
            state.forms = forms;
            state.byId = {};
            for (let form of forms) state.byId[form.id] = form;
            state.totalUnreadCount = forms.reduce((a, n) => a + n.unreadResponseCount, 0);
        },
        storeForm(state, form: Form) {
            state.byId[form.id] = form;
            const idx = state.forms.findIndex(f => f.id == form.id);
            if (idx != -1) state.forms[idx] = form;
            state.totalUnreadCount = state.forms.reduce((a, n) => a + n.unreadResponseCount, 0);
        },
        storeResponses(state, { form, responses }: { form: Form, responses: FormResponse[] }) {
            if (!state.responses[form.id]) state.responses[form.id] = { list: [], hasMore: true };
            if (responses.length > 0) state.responses[form.id].list.push(...responses);
            state.responses[form.id].hasMore = responses.length >= PAGE_SIZE;
        },
        storeResponse(state, response: FormResponse) {
            const formId = response.path.match(/^Form\/([^/]+)/)![1];
            if (!state.responses[formId]) return;
            state.responses[formId].list = state.responses[formId].list.map(resp => resp.id == response.id ? response : resp);
        },
        removeResponse(state, response: FormResponse) {
            const formId = response.path.match(/^Form\/([^/]+)/)![1];
            if (!state.responses[formId]) return;
            state.responses[formId].list = state.responses[formId].list.filter(resp => resp.id != response.id);
        },
        clearResponses(state, form: Form) {
            delete state.responses[form.id];
        },
    },
    actions: {
        async onAreaChanged({ commit }, area?: AreaInfo) {
            formsSubscription?.unsubscribe();
            formsSubscription = null;

            commit('storeForms', []);
            if (!area) return;

            formsSubscription = Firestore.listen<Form>(query(
                collection(getFirestore(), 'Form'),
                where('deletedAt', '==', null),
                orderBy('createdAt', 'desc'),
            ));
            formsSubscription.onValue(forms => commit('storeForms', forms));
        },

        async fetchResponses({ state, commit, rootGetters, rootState }, { form, reload }: { form: Form, reload?: boolean }) {
            // Fetch one page of responses for a form.
            // By default, fetches the next 25 responses.
            // To override this and reload from the start, pass the reload parameter.
            if (!reload && state.responses[form.id] && !state.responses[form.id].hasMore) return;
            if (reload) commit('clearResponses', form);
            let last: FormResponse|null = null;
            if (!reload && state.responses[form.id]) last = state.responses[form.id].list[state.responses[form.id].list.length-1];

            const constraints: QueryConstraint[] = [
                where('valid', '==', true),
            ];
            const fetchConstraints: QueryConstraint[] = [];
            while (!rootState.Areas.selectedArea) await new Promise(res => setTimeout(res, 100));
            if (!rootGetters['Areas/hasSystemAdminAccess']) constraints.push(where('area', '==', rootState.Areas.selectedArea!.area.id));
            if (last) fetchConstraints.push(startAfter(last.createdAt));
            const responseQuery = query(
                collection(getFirestore(), `Form/${form.id}/Response`),
                ...constraints,
                orderBy('createdAt', 'desc'),
                ...fetchConstraints,
                limit(PAGE_SIZE),
            );
            const responses = await Firestore.get<FormResponse>(responseQuery);
            commit('storeResponses', { form, responses });

            return responses;
        },

        async fetchStats({}, form: Form) {
            const stats = await Firestore.get<FormStats>(`${form.path}/Internal/stats`);
            return stats!.stats;
        },

        async saveForm({}, { id, formData }: { id?: string, formData: UpdateType<Form> }) {
            const data: UpdateType<Form> = Object.assign({ updatedAt: serverTimestamp() }, formData);
            let form: Form;
            if (id) {
                await Firestore.update<Form>('Form/' + id, data);
                form = (await Firestore.get<Form>('Form/' + id))!;
            } else {
                data.unreadResponseCount = 0;
                data.createdAt = serverTimestamp();
                data.deletedAt = null;
                form = await Firestore.add<Form>('Form', data);
            }
            return form;
        },

        async deleteForm({}, form: Form) {
            await FirestoreHelper.merge(form, { deletedAt: serverTimestamp() });
        },

        async markResponseAsRead({ commit }, response: FormResponse) {
            response = await FirestoreHelper.merge(response, { read: true });
            commit('storeResponse', response);
        },

        async deleteResponse({ commit }, response: FormResponse) {
            await FirestoreHelper.delete(response);
            commit('removeResponse', response);
        },
    },
}

export default formsModule;