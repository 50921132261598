<template>
    <div class="access-row">
        <confirmation-box v-if="removing" @close="removing = false" @confirm="comfirmRemove">
            Vil du fjerne brukertilgangen til {{ user.username }}?
            <template #confirm>Ja</template>
            <template #cancel>Nei</template>
        </confirmation-box>
        <edit-access v-if="editing" @close="editing = false" :user="user" :role="role" />
        <div class="username">{{ user.username }}</div>
        <div class="role">{{ roleName }}</div>
        <div class="invite" v-if="invitedBy">
            <user-loader :id="invitedBy">
                <template v-slot="{ user }">Invitert av {{ user.username }}</template>
            </user-loader>
        </div>
        <div class="controls">
            <div class="button edit-button" @click="editing = true">Endre</div>
            <div class="button remove-button" @click="remove">Fjern</div>
        </div>
    </div>
</template>

<script>
import ConfirmationBox from '@/components/ConfirmationBox.vue'
import EditAccess from '@/components/access/EditAccess.vue'
import UserLoader from '@/components/UserLoader.vue'

export default {
    name: 'AccessRow',
    components: {
        ConfirmationBox,
        EditAccess,
        UserLoader,
    },
    props: ['user', 'role'],
    data: () => ({
        editing: false,
        removing: false,
    }),
    computed: {
        roleName() {
            if (this.role == 'admin') return 'Admin';
            if (this.role == 'editor') return 'Redaktør';
            if (this.role == 'viewer') return 'Leser';
        },
        invitedBy() {
            if (!this.user.rolesInvited) return null;
            const areaId = this.$store.state.Areas.selectedArea?.area.id;
            if (!areaId) return null;
            return this.user.rolesInvited[areaId];
        },
    },
    methods: {
        remove() {
            this.removing = true;
        },
        comfirmRemove() {
            this.removing = false;
            this.$store.dispatch('Roles/changeRole', { user: this.user, role: 'none' });
        },
    },
}
</script>

<style lang="scss" scoped>
.access-row {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #D1D1D1;

    .username {
        width: 300px;
        text-align: left;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .role {
        width: 200px;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: left;
        color: red;
        font-weight: bold;
    }

    .invite {
        width: 200px;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: left;
    }

    .controls {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;

        .button {
            color: #424242;
            background: white;
            border: 1px solid #BFBFBF;
            border-radius: 25px;
            padding: .75em 1.5em;
            font-size: 14px;
            letter-spacing: 0.15em;
            text-transform: capitalize;
            cursor: pointer;
            margin-left: 10px;

            &.remove-button {
                color: white;
                background: #CE6868;
                border: none;
            }
        }
    }
}
</style>