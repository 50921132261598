<template>
    <div>
        <header-shell>
            <template #title>Varsling</template>
        </header-shell>

        <div class="contain">
            <div class="inputText">
                <p class="text">Her kan du sende ut en melding til alle brukere i valgt/valgte kommuner. Skriv inn tittel og en melding.</p>
                <input type="text" placeholder="Tittel" v-model="title" :disabled="sending" />
                <textarea placeholder="Melding" rows="10" v-model="body" :disabled="sending" ></textarea>
                <p class="bold">Husk å velge kommuner for utsendelse!</p>
                <process-button v-model="sending" :process="sendAlert"><template v-slot="{ processing }">{{ processing ? 'Sender...' : 'Send' }}</template></process-button>
            </div>
            <div class="divider"></div>
            <div class="selectAreas">
                <p class="bold">Velg kommuner</p>
                <div>
                    <label>
                        <input type="checkbox" name="all" :checked="allChecked" @input="checkAll" />
                        Alle kommuner
                    </label>
                </div>
                <div v-for="area in areas" :key="area.id">
                    <label>
                        <input type="checkbox" :name="area.fullName" v-model="selectedAreas[area.id]" />
                        {{ area.fullName }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProcessButton from '@/components/ProcessButton.vue'

export default {
    name: 'AlertView',
    components: {
        ProcessButton,
    },
    data() {
        return {
            title: '',
            body: '',
            selectedAreas: {},
            sending: false,
        }
    },
    computed: {
        areas() {
            return this.$store.state.Areas.areas.map(area => area.area);
        },
        allChecked() {
            return Object.values(this.selectedAreas).every(v=>v);
        },
    },
    methods: {
        checkAll(event) {
            let checked = event.target.checked;
            this.areas.forEach(area => this.selectedAreas[area.id] = checked);
        },
        async sendAlert() {
            const areaIds = this.areas.filter(area => this.selectedAreas[area.id]).map(area => area.id);
            if (areaIds.length == 0) {
                alert('Du må velge minst ett område.');
                return;
            }
            await this.$store.dispatch('Areas/sendAdminNotification', {
                title: this.title,
                body: this.body,
                areaIds: this.areas.filter(area => this.selectedAreas[area.id]).map(area => area.id),
            })

            this.title = '';
            this.body = '';
            this.areas.forEach(area => this.selectedAreas[area.id] = false);
        },
    },
    watch: {
        areas: {
            handler() {
                for (let area of this.areas) this.selectedAreas[area.id] = this.selectedAreas[area.id] ?? false;
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>


<style lang="scss" scoped>
p, 
h1, 
button, 
input, 
textarea, 
label {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
}

button {
    cursor: pointer;
}

h1 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: .15em;
    text-align: left;
}
.bold {
    font-weight: bold;
}
.contain {
    display: flex;;
    gap: 6vw;
    padding: 2rem 5vw;

    
    .inputText {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 55%;
        /* align-items: center; */
        input[type=text], textarea{
            /* font-size: 16px; */
            border: 1px solid #ACACAC;
            border-radius: 10px;
            padding: .5em;
            resize: none;
        }
        button {
            background: #6d6c97;
            border-radius: 25px;
            padding: .5em 5em;
            align-self: flex-start;
            border: none;
            color: white;

            &.processing {
                opacity: 0.6;
            }
        }
        p {
            text-align: left;
        }
    }
    .selectAreas {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        min-width: 151px;

        p {
            margin-bottom: 10px;
        }
        div {
            input[type=checkbox] {
                margin-right: 10px;
            }
        }
    }
}
.divider {
    content: "";
    width: 1px;
    background: lightgray;
    height: 500px;
    align-self: center;
}
</style>