import { Module } from 'vuex'
import User from '@/types/User'
import Firestore from '@/util/FirestoreHelper'

const promises: { [uid: string]: Promise<User> } = {}

interface UsersState {
    users: { [uid: string]: User }
}

const usersModule: Module<UsersState, void> = {
    namespaced: true,
    state: {
        users: {},
    },
    mutations: {
        cacheUser(state, user) {
            state.users[user.id] = user;
        },
    },
    actions: {
        async _loadUser({ commit }, uid: string): Promise<User> {
            const user = await Firestore.get<User>('Userdata/' + uid);
            commit('cacheUser', user);
            if (!user) {
                delete promises[uid];
            }
            return user!;
        },
        async reloadUser({ dispatch }, uid: string): Promise<User> {
            const promise = dispatch('_loadUser', uid);
            promises[uid] = promise;
            return promise;
        },
        async loadUser({ dispatch }, uid: string): Promise<User> {
            if (uid in promises) return promises[uid];
            return dispatch('reloadUser', uid);
        },
    },
}

export default usersModule;