<template>
<div class="container">
    <slot name="header-slot"></slot>
    <div class="wrapper">
        <slot></slot>
        <!-- <the-footer /> -->
    </div>
</div>
</template>
<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .wrapper {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 1px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 25px;
    }
}
</style>