<template>
    <div class="number-question">
        <radio-group :values="['input', 'slider', 'options']" :modelValue="element.presentation" @update:modelValue="update('presentation', $event)">
            <template #default="{value}">
                <span v-if="value == 'input'">Tekstboks</span>
                <span v-if="value == 'slider'">Slider</span>
                <span v-if="value == 'options'">Flervalg</span>
            </template>
        </radio-group>
        <div class="limits" v-if="element.presentation != 'input'">
            <input type="number" :value="element.min" @input="update('min', parseFloat($event.target.value))" placeholder="Fra" />
            <span>-</span>
            <input type="number" :value="element.max" @input="update('max', parseFloat($event.target.value))" placeholder="Til" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import RadioGroup from '@/components/RadioGroup.vue'

export default defineComponent({
    name: 'NumberQuestion',
    components: {
        RadioGroup,
    },
    emits: ['update-raw', 'update-tr'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        language: {
            type: Object,
            required: false,
        },
    },
    methods: {
        update(name, value) {
            this.$emit('update-raw', { [name]: value === '' || (typeof value == 'number' && isNaN(value)) ? null : value });
        },
    },
});
</script>

<style lang="scss" scoped>
.number-question {
    display: flex;
    align-items: center;
    gap: 30px;

    .limits {
        display: flex;
        gap: 10px;

        input {
            width: 75px;
        }
    }
}
</style>