<template>
    <div class="container">
        <edit-treasure
            v-if="newTreasure" 
            @close="closeNewTreasure"
        ></edit-treasure>
        <edit-treasure 
            v-if="editTreasure" 
            @close="closeEditTreasure"
            :pin="editTreasure"
        ></edit-treasure>
        <preview-comp 
            v-if="previewTreasure" 
            @close="closePreview"
            :pin="previewTreasure"
        ></preview-comp>
        <confirmation-box
            v-if="deletingTreasure"
            @close="deletingTreasure=null"
            @confirm="confirmDeleteTreasure"
            :item="deletingTreasure.name"
        ></confirmation-box>
        <template v-if="notifyingTreasure">
            <confirmation-box
                v-if="!notifyingTreasure.notified && notifyingTreasure.published"
                @close="notifyingTreasure=null"
                @confirm="confirmNotifyTreasure"
                :item="notifyingTreasure.name"
            >
                Vil du sende ut en varsling for denne kulturskatten? Dette kan bare gjøres en gang.
                <template #confirm>Ja</template>
                <template #cancel>Nei</template>
            </confirmation-box>
            <message-box v-else-if="!notifyingTreasure.published" @close="notifyingTreasure = null">
                Kulturskatten må være publisert før du sender ut en varsling for den.
            </message-box>
            <message-box v-else @close="notifyingTreasure = null">
                En varsling har allerede blitt sendt ut for denne kulturskatten.
            </message-box>
        </template>
        <header-shell>
            <template #title>Skatter</template>
            <template #header-buttons v-if="$store.getters['Areas/hasEditorAccess']">
                <span>Sorter:</span>
                <dropdown :values="sortOptions" v-model="sortOption">
                    <template #default="{value}">{{ value.name }}</template>
                </dropdown>
                <checkbox-dropdown :values="categoryFilterOptions" v-model="categoryFilter" style="min-width: 100px;">
                    <template #default="{value}">Kategori</template>
                    <template #value="{value}">{{ categoryById(value).name }}</template>
                </checkbox-dropdown>
                <checkbox-dropdown :values="userFilterOptions" v-model="userFilter" style="min-width: 100px;">
                    <template #default="{value}">Publisert av</template>
                    <template #value="{value}">{{ nameFromUserValue(value) }}</template>
                </checkbox-dropdown>
                <span>{{ currentTreasures }} / {{ maxTreasures }} skatter publisert</span>
                <header-button @click="openNewTreasure">+ Ny kulturskatt</header-button>
            </template>
        </header-shell>
        <div class="inner-treasures">
            <item-card 
                v-for="treasure in treasures" 
                :key="treasure.id" 
                @delete="deleteTreasure(treasure)" 
                :deletable="$store.getters['Areas/hasEditorAccess']"
                :img-src="treasure.image"
                :updatedBy="treasure.updatedBy?.get('username')"
                @img-click="openPreview(treasure)">
                <template #title>{{ treasure.name }}</template>
                <template #default>
                    <span class="published" v-if="treasure.published && treasure.publishedByName">Publisert av {{ treasure.publishedByName }}</span>
                    <span class="published" v-else-if="treasure.published && treasure.publishedBy">
                        <UserLoader :id="treasure.publishedBy">
                            <template v-slot="{ user }">Publisert av {{ user.username }}</template>
                            <template #placeholder>Publisert</template>
                        </UserLoader>
                    </span>
                    <span class="published" v-else-if="treasure.published">Publisert</span>
                    <span class="unpublished" v-else>Ikke publisert</span>
                </template>
                <template #buttons>
                    <button 
                        class="change-btn" 
                        v-if="$store.getters['Areas/hasEditorAccess']" 
                        @click="openEditTreasure(treasure)">Rediger</button>
                    <button class="stats-btn" @click="$router.push(`/skatter/${treasure.id}`)" v-if="$store.getters['Areas/hasSystemAdminAccess']">Statistikk</button>
                    <button class="notify-btn" :class="{ disabled: treasure.notified }" @click="notifyTreasure(treasure)" v-if="$store.getters['Areas/hasEditorAccess']">Varsle</button>
                </template>
            </item-card>
        </div>
    </div>
</template>

<script>
import EditTreasure from "./TreasuresView/EditTreasure.vue"
import PreviewComp from "@/components/PreviewComp.vue"
import ConfirmationBox from "@/components/ConfirmationBox.vue"
import MessageBox from '@/components/MessageBox.vue'
import Dropdown from '@/components/Dropdown.vue'
import CheckboxDropdown from '@/components/CheckboxDropdown.vue'
import UserLoader from '@/components/UserLoader.vue'

const sortOptions = [
    { key: 'createdAt', name: 'Nyeste opprettet' },
    { key: 'updatedAt', name: 'Siste oppdaterte' },
    { key: 'name', name: 'Alfabetisk' },
    { key: 'published', name: 'Publisert' },
];

export default {
    components: {
        EditTreasure,
        PreviewComp,
        ConfirmationBox,
        MessageBox,
        Dropdown,
        CheckboxDropdown,
        UserLoader,
    },
    data() {
        return {
            newTreasure: false,
            editTreasure: null,
            previewTreasure: null,
            confirm: false,
            deletingTreasure: null,
            notifyingTreasure: null,
            selectedCategoryFilter: null,
            selectedUserFilter: [],
        };
    },
    computed: {
        treasures() {
            const userFilter = this.selectedUserFilter?.filter(val => val).map(JSON.parse);
            const userIdFilter = userFilter?.filter(val => val.type == 'id').map(val => val.value);
            const userNameFilter = userFilter?.filter(val => val.type == 'name').map(val => val.value);

            return this.$store.getters['Pins/treasurePins']
                .filter(treasure => (!userFilter) || (userFilter.length == 0) || userIdFilter.indexOf(treasure.publishedBy) != -1 || userNameFilter.indexOf(treasure.publishedByName) != -1)
                .filter(treasure => this.selectedCategoryFilter == null || this.selectedCategoryFilter.indexOf(treasure.category) != -1);
        },
        currentTreasures() {
            return this.$store.state.Areas.selectedArea?.area.currentTreasures ?? [];
        },
        maxTreasures() {
            return this.$store.state.Areas.selectedArea?.area.maxTreasures ?? [];
        },
        sortOptions() {
            return sortOptions;
        },
        sortOption: {
            get() {
                return sortOptions.filter(opt => opt.key == this.$store.state.Pins.sortBy)[0];
            },
            set(val) {
                this.$store.dispatch('Pins/sortPins', val.key);
            },
        },
        categoryFilterOptions() {
            return this.$store.state.PinCategories.categories.filter(cat => cat.name != 'Vandringer').map(cat => cat.id);
        },
        categoryFilter: {
            get() {
                if (!this.selectedCategoryFilter) return this.categoryFilterOptions;
                return this.selectedCategoryFilter;
            },
            set(val) {
                this.selectedCategoryFilter = val;
            },
        },
        userFilterOptions() {
            const options = [
                ...new Set(this.$store.getters['Pins/treasurePins']
                    .filter(pin => pin.published && pin.publishedBy)
                    .map(pin => JSON.stringify(pin.publishedByName ? { type: 'name', value: pin.publishedByName } : { type: 'id', value: pin.publishedBy }))
                ),
            ];
            options.forEach(_option => {
                const option = JSON.parse(_option);
                if (option.type == 'name') return;
                this.$store.dispatch('Users/loadUser', option.value);
            });
            return options;
        },
        userFilter: {
            get() {
                if (!this.selectedUserFilter) return this.userFilterOptions;
                return this.selectedUserFilter;
            },
            set(val) {
                this.selectedUserFilter = val;
            },
        },
    },
    methods: {
        openPreview(treasure) {
            this.previewTreasure = treasure
        },
        closePreview() {
            this.previewTreasure = null
        },
        openNewTreasure() {
            this.newTreasure = true
        },
        closeNewTreasure() {
            this.newTreasure = false
        },
        openEditTreasure(treasure) {
            this.editTreasure = treasure
        },
        closeEditTreasure() {
            this.editTreasure = null
        },
        deleteTreasure(treasure) {
            this.deletingTreasure = treasure;
        },
        async confirmDeleteTreasure() {
            await this.$store.dispatch('Pins/deletePin', this.deletingTreasure);
            this.deletingTreasure = null;
        },
        notifyTreasure(treasure) {
            this.notifyingTreasure = treasure;
        },
        async confirmNotifyTreasure() {
            await this.$store.dispatch('Pins/notifyPin', this.notifyingTreasure.id);
            this.notifyingTreasure = null;
        },
        categoryById(id) {
            return this.$store.state.PinCategories.byId[id];
        },
        nameFromUserValue(val) {
            const data = JSON.parse(val);
            if (data.type == 'name') return data.value;

            const user = this.$store.state.Users.users[data.value];
            if (!user) return 'Ukjent bruker';
            return user.username;
        },
    }
}
</script>


<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: left;
}

.back-btn {
    position: absolute;
    top: 18%;
    left: 1.5%;
    background: none;
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.15em;
    font-family: 'Quicksand', sans-serif;
    text-transform: capitalize;
    text-align: left;
    padding: 0 5vw;
}

.dropdown {
    width: 200px;
}

.checkbox-dropdown {
    width: 120px;
}

header {
    a{
        background: #6d6c97;
        color: white;
        border-radius: 40px;
        border: none;
        padding: 1rem 2rem;
        font-size: 14px;
        letter-spacing: 0.15em;
        text-transform: capitalize;
        cursor: pointer;
        margin-right: 10px;
        text-decoration: none;
    }
}

.inner-treasures {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    button {
        border-radius: 25px;
        border: none;
        padding: .75em 1.5em;
        font-size: 14px;
        letter-spacing: 0.15em;
        text-transform: capitalize;
        cursor: pointer;
        background: white;
    }

    .published {
        color: green;
        font-weight: bold;
    }

    .unpublished {
        color: red;
    }

    .change-btn, .stats-btn, .notify-btn {
        color: #424242;
        border: solid 1px #BFBFBF;

        &.disabled {
            opacity: 0.4;
        }
    }
}
</style>
