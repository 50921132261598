import { Module } from 'vuex'
import { getAuth, onAuthStateChanged, signOut, signInWithEmailAndPassword, getIdTokenResult, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'

import Firestore, { Listener } from '@/util/FirestoreHelper'
import User, { AreaRole, UserPrivateData, Gender, AgeGroup } from '@/types/User'
import { runTransaction, getFirestore } from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'

export interface AuthState {
    user?: User|null
}

let registerPromise: Promise<void>|null = null
let userListener: Listener<User|null> | undefined

interface Claims {
    admin: boolean
    areaRoles: { [areaId: string]: AreaRole }
}

function rolesChanged(claims: Claims, user: User) {
    // If system admin status changed, return true
    if (user.isAdmin != claims.admin) return true;

    // If the number of roles is different, return true
    if (Object.keys(user.roles).length != Object.keys(claims.areaRoles).length) return true;

    // If one of the roles is different, return true (this will necessarily happen if the *length* of roles is the same but one has been replaced)
    for (let areaId in user.roles) if (claims.areaRoles[areaId] != user.roles[areaId]) return true;

    // Otherwise, nothing changed
    return false;
}

const authModule: Module<AuthState, void> = {
    namespaced: true,
    state: {
        user: undefined,
    },
    mutations: {
        setUser(state: AuthState, user: User|null) {
            state.user = user;
        },
    },
    actions: {
        async init({ commit, dispatch }) {
            const auth = getAuth();
            onAuthStateChanged(auth, async user => {
                if (user) {
                    if (registerPromise) await registerPromise; // If this was mid-register, wait for register to finish
                    userListener = Firestore.listen<User>(`Userdata/${user.uid}`, async _user => {
                        commit('setUser', _user);
                        if (!_user) return;

                        // Check whether any roles changed - if they did, set ID token claims
                        const idToken = await getIdTokenResult(user);
                        if (rolesChanged(idToken.claims as unknown as Claims, _user)) {
                            const functions = getFunctions(undefined, 'europe-west1');
                            const setclaims = httpsCallable(functions, 'user-setclaims');
                            await setclaims();
                            await getIdTokenResult(user, true);
                        }
                    });
                    await userListener;
                    dispatch('moduleEvent', 'signIn', { root: true });
                } else {
                    commit('setUser', null);
                }
            });
        },
        async register({}, { username, password, email, gender, ageGroup }: { username: string, password: string, email: string, gender: Gender, ageGroup: AgeGroup }) {
            // [-_A-ZÆØÅa-zæøå0-9]{3,20}

            // Check the username for validity
            if (!/^[-_A-ZÆØÅa-zæøå0-9]{3,20}$/.exec(username)) throw 'invalid-username';

            // Check if the username has already been used
            if (await Firestore.exists(`Usernames/${username}`)) throw 'username-taken';

            // Create the user
            registerPromise = new Promise(async (resolve, reject) => {
                try {
                    const auth = getAuth();
                    const credential = await createUserWithEmailAndPassword(auth, email, password);

                    // Wait for both public and private data docs to exist (we need to write to both)
                    let exists = await Promise.all([
                        Firestore.exists(`Userdata/${credential.user.uid}`),
                        Firestore.exists(`Userdata/${credential.user.uid}/Private/data`),
                    ]);
                    while (!(exists[0] && exists[1])) {
                        await new Promise(res => setTimeout(res, 250));
                        exists = await Promise.all([
                            Firestore.exists(`Userdata/${credential.user.uid}`),
                            Firestore.exists(`Userdata/${credential.user.uid}/Private/data`),
                        ]);
                    }

                    // Write to the user documents
                    await runTransaction(getFirestore(), async tr => {
                        Firestore.update<User>(`Userdata/${credential.user.uid}`, {
                            username,
                        }, tr),
                        Firestore.set(`Usernames/${username}`, {
                            uid: credential.user.uid,
                        }, tr);
                    });

                    await Firestore.update<UserPrivateData>(`Userdata/${credential.user.uid}/Private/data`, {
                        gender,
                        ageGroup,
                    });

                    // Set the user's display name to the username
                    await updateProfile(getAuth().currentUser!, {
                        displayName: username,
                    });

                    resolve();
                } catch (e) {
                    if (e instanceof FirebaseError) {
                        switch (e.code) {
                            case 'auth/email-already-in-use': return reject('email-already-used');
                            case 'auth/invalid-email': return reject('invalid-email');
                            case 'auth/weak-password': return reject('weak-password');
                            case 'auth/too-many-requests': return reject('too-many-requests');
                            default: {
                                console.log('Firebase error code:', e.code);
                                return reject('unknown-error');
                            }
                        }
                    }
                    console.error(e);
                    reject('unknown-error');
                }
            });

            return registerPromise;
        },
        async logIn({}, { email, password }: { email: string, password: string }) {
            try {
                const auth = getAuth();
                await signInWithEmailAndPassword(auth, email, password);
            } catch (e) {
                if (e instanceof FirebaseError) {
                    switch (e.code) {
                        case 'auth/invalid-email': throw 'invalid-email';
                        case 'auth/wrong-password': throw 'wrong-password';
                        case 'auth/missing-password': throw 'no-password';
                        case 'auth/user-not-found':
                        case 'auth/user-disabled':
                            throw 'user-not-found';
                        case 'auth/too-many-requests': throw 'too-many-requests';
                        case 'auth/invalid-login-credentials': throw 'invalid-login-credentials';
                        default:
                            console.log('Firebase error code:', e.code);
                            throw 'unknown-error';
                    }
                }
                console.error(e);
                throw 'unknown-error';
            }
            // wrong-password
            // user-not-found
            // user-disabled
        },
        async logOut({ dispatch, commit }) {
            userListener?.unsubscribe();
            dispatch('moduleEvent', 'signOut', { root: true });
            commit('setUser', null);
            await signOut(getAuth());
        },

        async deleteAccount({ dispatch }) {
            const functions = getFunctions(undefined, 'europe-west1');
            const deleteme = httpsCallable(functions, 'user-deleteme');

            await deleteme();
            return dispatch('logOut');
        },
    },
}

export default authModule;