import { Module } from 'vuex'
import { getFirestore, query, collection, where, orderBy, serverTimestamp, runTransaction, doc } from 'firebase/firestore'
import Pin, { PinQuestions, PinQuestionWithAnswer } from '@/types/Pin'
import { AreaInfo } from './Areas'
import Firestore, { Listener } from '@/util/FirestoreHelper'
import ImageUploader from '../../util/ImageUploader'
import callFirebase from '@/util/callFirebase'
import { AuthState } from './Auth'

type SortMethod = 'createdAt'|'updatedAt'|'name'|'visits'|'published'

interface PinsState {
    pins: Pin[]
    pinsById: { [key: string]: Pin }
    //stats: { [id: string]: PinStats }
    sortBy: SortMethod
}

function sortPins(state: PinsState) {
    state.pins.sort((a, b) => {
        switch (state.sortBy) {
            case 'createdAt':
            case 'updatedAt':
                return (b[state.sortBy]?.toMillis() ?? 0) - (a[state.sortBy]?.toMillis() ?? 0);
            case 'name':
                return a.name.localeCompare(b.name);
            case 'visits':
            case 'published':
                if (a.published && !b.published) return -1;
                if (b.published && !a.published) return 1;
                return 0;
        }
    });
}

let pinSubscription: Listener<Pin[]>|null = null;

const pinsModule: Module<PinsState, { Auth: AuthState }> = {
    namespaced: true,
    state: {
        pins: [],
        pinsById: {},
        //stats: {},
        sortBy: 'createdAt',
    },
    mutations: {
        savePins(state, pins: Pin[]) {
            state.pins = pins;
            state.pinsById = {};
            for (let pin of pins) {
                state.pinsById[pin.id] = pin;
            }
            sortPins(state);
        },
        addPinToMap(state, pin: Pin) {
            state.pinsById[pin.id] = pin;
        },
        saveSinglePin(state, pin: Pin) {
            state.pinsById[pin.id] = pin;
            state.pins = state.pins.map(p => p.id == pin.id ? pin : p);
            sortPins(state);
        },
        removePin(state, pin: Pin) {
            delete state.pinsById[pin.id];
            state.pins = state.pins.filter(p => p.id != pin.id);
        },
        sortPins(state, sortMethod?: SortMethod) {
            if (sortMethod) state.sortBy = sortMethod;
            sortPins(state);
        },
    },
    actions: {
        async onAreaChanged({ commit }, area?: AreaInfo) {
            pinSubscription?.unsubscribe();
            pinSubscription = null;

            commit('savePins', []);
            if (!area) return;

            pinSubscription = Firestore.listen<Pin>(query(
                collection(getFirestore(), 'Pin'),
                where('area', '==', area.area.id),
                where('deletedAt', '==', null),
                orderBy('updatedAt', 'desc'),
            ));
            pinSubscription.onValue(pins => commit('savePins', pins));
        },
        async ensureLoaded() {
            if (!pinSubscription) return;
            await pinSubscription;
        },
        async loadQuestions({}, pin: Pin) {
            let questions = await Firestore.get<PinQuestions>(`Pin/${pin.id}/Internal/questions`);
            return questions;
        },
        async _uploadImages({ rootState }, data: { id: string, selectedImage?: File, selectedWanderingImage?: File, image?: string, wanderingImage?: string }) {
            let uploaders: ImageUploader[] = [];

            let image = data.selectedImage;
            delete data.selectedImage;
            if (image) {
                let uploader = new ImageUploader(image);
                uploaders.push(uploader);
                await uploader.resize(400);
                let imageUrl = await uploader.upload('pin', (rootState as any).Areas.selectedArea.area.id, data.id);
                data.image = imageUrl;
            }

            let wImage = data.selectedWanderingImage;
            delete data.selectedWanderingImage;
            if (wImage) {
                let uploader = new ImageUploader(wImage);
                uploaders.push(uploader);
                await uploader.resize(400);
                let imageUrl = await uploader.upload('pin', (rootState as any).Areas.selectedArea.area.id, `adventure-${data.id}`);
                data.wanderingImage = imageUrl;
            }

            return uploaders;
        },
        async createPin({ dispatch, rootState }, data: Pin & { selectedImage?: File, selectedWanderingImage?: File, questions: PinQuestionWithAnswer[] }) {
            const pinDoc = doc(collection(getFirestore(), 'Pin'));
            data.id = pinDoc.id; // Set the ID for the image upload
            const uploaders: ImageUploader[] = await dispatch('_uploadImages', data);

            const questions = data.questions!;

            data = { ...data };

            // The data that needs to be sent to Firestore is not quite the same as the data type on this end.
            // The ts-ignore'd lines are necessary to do this, and make for better code than what would be needed to
            // placate Typescript.
            // @ts-ignore
            delete data.id;
            // @ts-ignore
            delete data.path;

            data.area = (rootState as any).Areas.selectedArea.area.id;

            // @ts-ignore
            for (let k in data) if (data[k] === undefined) delete data[k];

            // @ts-ignore
            data.updatedAt = serverTimestamp();

            data.questions = [];
            data.published = false;
            data.wanderingChildren = [];
            // @ts-ignore
            data.createdAt = serverTimestamp();
            data.deletedAt = null;

            const pin = await Firestore.set<Pin>(pinDoc, data);
            await Promise.all(uploaders.map(ul => ul.finalize()));

            if (questions) {
                await Firestore.set<PinQuestions>(`${pin.path}/Internal/questions`, {
                    questions,
                });
            }

            return pin;
        },
        async updatePin({ state, dispatch }, data: Partial<Pin> & { selectedImage?: File, selectedWanderingImage?: File, questions?: PinQuestionWithAnswer[] }) {
            const uploaders: ImageUploader[] = await dispatch('_uploadImages', data);

            data = { ...data };

            const pin = state.pinsById[data.id!];

            const questions = data.questions!;

            delete data.id;
            delete data.path;
            delete data.questions;

            // @ts-ignore
            for (let k in data) if (data[k] === undefined) delete data[k];

            // @ts-ignore
            data.updatedAt = serverTimestamp();

            await Firestore.update(pin, data);
            await Promise.all(uploaders.map(ul => ul.finalize()));

            if (questions) {
                await Firestore.merge<PinQuestions>(`${pin.path}/Internal/questions`, {
                    questions,
                });
            }
        },
        async deletePin({}, pin: Pin) {
            await Firestore.update(`Pin/${pin.id}`, {
                deletedAt: serverTimestamp(),
            });
        },
        async publishPin({ rootState }, pinIds: string[]) {
            await runTransaction(getFirestore(), async tr => {
                for (let pinId of pinIds) {
                    Firestore.update<Pin>(`Pin/${pinId}`, {
                        published: true,
                        publishedBy: rootState.Auth.user?.id,
                        publishedByName: rootState.Auth.user?.isAdmin ? 'Link Utvikling' : null,
                        updatedAt: serverTimestamp(),
                    }, tr);
                }
            });
        },
        async unpublishPin({}, pinIds: string[]) {
            await runTransaction(getFirestore(), async tr => {
                for (let pinId of pinIds) {
                    Firestore.update<Pin>(`Pin/${pinId}`, {
                        published: false,
                        publishedBy: null,
                        publishedByName: null,
                        updatedAt: serverTimestamp(),
                    }, tr);
                }
            });
        },
        async notifyPin({ commit }, pinId: string) {
            await callFirebase('pin-notify', { pinId });
            const newPin = await Firestore.get<Pin>('Pin/' + pinId);
            commit('saveSinglePin', newPin);
        },
        sortPins({ commit }, sortMethod?: SortMethod) {
            commit('sortPins', sortMethod);
        },



        async scrapeBergen() {
            //let response = await Parse.Cloud.run('admin-kmsscrapebergen');
        },



        /*async loadOldJSON({}, data: OldJSONData[]) {
            const pins: { [id: string]: Pin } = {};

            let count = 0;

            // 1. Create all individual pins (ignoring adventure relations for now)
            for (let old of data) {
                if (old.deletedAt) {
                    continue;
                }

                const id = (await Parse.Cloud.run('pin-create', { areaId: 'UHDFKmHnqN' })).value.id;
                const pin = await Pin.fetch(id);
                pin.set('published', false);
                pin.set('name', old.name);
                pin.set('location', new Parse.GeoPoint([parseFloat(old.location[1].$numberDouble), parseFloat(old.location[0].$numberDouble)]));
                pin.set('questions', old.questions.map(q => ({
                    question: q.question,
                    answers: q.answers,
                    correctAnswers: q.correctAnswers.map(a => parseInt(a.$numberInt)),
                    description: parseInt(q.description.$numberInt),
                })));
                pin.set('tr', old.tr);
                pin.set('address', old.address);
                pin.set('image', old.image);
                pin.set('descriptions', old.descriptions);
                pin.set('sources', old.sources);
                if (old.imageDescription) pin.set('imageDescription', old.imageDescription);
                if (old.wanderingDescription) pin.set('wanderingDescription', old.wanderingDescription);
                if (old.wanderingName) pin.set('wanderingName', old.wanderingName);
                if (old.wanderingImage) pin.set('wanderingImage', old.wanderingImage);

                pins[old._id] = pin;
                await pin.save();
                ++count;
            }

            //await Promise.all(Object.keys(pins).map(id => pins[id].save()));
            console.log(`${count} pins created`);

            // 2. Set up adventure relations
            await Promise.all(data.map(old => {
                const pin = pins[old._id];
                if (!pin) return;

                let updated = false;

                if (old._p_wanderingParent) {
                    const id = old._p_wanderingParent.split('$')[1];
                    pin.set('wanderingParent', pins[id].object);
                    updated = true;
                }

                if (old.wanderingChildren && old.wanderingChildren.length > 0) {
                    pin.set('wanderingChildren', old.wanderingChildren.map(ch => pins[ch.objectId].object!));
                    updated = true;
                }

                if (updated) return pin.save();
            }));

            console.log('Adventure relations set up');
        },*/
    },
    getters: {
        //totalVisits: (state) => (pin: Pin) => 0,
        treasurePins: (state) => state.pins.filter(pin => (!pin.wanderingChildren || pin.wanderingChildren.length == 0) && !pin.wanderingParent),
        wanderingPins: (state) => state.pins.filter(pin => (pin.wanderingChildren && pin.wanderingChildren.length > 0) || !!pin.wanderingParent),
        wanderingParents: (state) => state.pins.filter(pin => pin.wanderingChildren && pin.wanderingChildren.length > 0),
    },
}

interface OldJSONData {
    _id: string
    name: string
    location: [{ $numberDouble: string }, { $numberDouble: string }]
    questions: {
        question: string
        answers: string[]
        correctAnswers: { $numberInt: string }[]
        description: { $numberInt: string }
    }[]
    wanderingChildren: {
        __type: 'Pointer'
        className: 'Pin'
        objectId: string
    }[]
    tr: {} // TODO
    address: string
    image: string
    descriptions: string[]
    sources: string[]
    imageDescription?: string
    wanderingDescription?: string
    wanderingName?: string
    wanderingImage?: string
    _p_wanderingParent?: string
    deletedAt?: {}
    published?: boolean
}

export default pinsModule;