<template>
    <div class="element-line">
        <div>Placeholder</div>
        <input type="text" :value="placeholder" @input="update('placeholder', $event.target.value)" />
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TextQuestion',
    emits: ['update-raw', 'update-tr'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        language: {
            type: Object,
            required: false,
        },
    },
    computed: {
        placeholder() {
            if (this.language && this.language.key != 'no') {
                if (this.element.tr && this.element.tr[this.language.key] && this.element.tr[this.language.key].placeholder) {
                    return this.element.tr[this.language.key].placeholder;
                }
                return '';
            }
            return this.element.placeholder;
        },
    },
    methods: {
        update(name, value) {
            this.$emit('update-tr', { [name]: value });
        },
    },
});
</script>

<style lang="scss" scoped>
.element-line {
    display: flex;
    gap: 10px;

    div {
        width: 140px;
    }

    input {
        flex-grow: 1;
        border: none;
        outline: none;
        border-bottom: 1px solid darkgrey;
        background: #EEE;
        padding: 5px 15px;
    }
}
</style>