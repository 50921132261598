<template>
    <div class="radio-group">
        <div class="value" v-for="value in values">
            <div class="value-inner" :class="{ selected: value == modelValue }" @click="$emit('update:modelValue', value)">
                <slot :value="value">{{ value }}</slot>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'RadioGroup',
    props: {
        values: {
            type: Array,
            required: true,
        },
        modelValue: {
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.radio-group {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px 0;

    .value {
        padding: 0 10px;

        &:not(:first-child) {
            border-left: 1px solid lightgrey;
        }

        .value-inner {
            border-radius: 1000px;
            padding: 5px 15px;
            cursor: pointer;

            &.selected {
                background: #6d6c97;
                color: white;
            }
        }
    }
}
</style>