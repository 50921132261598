<template>
    <div class="greyout">
        <div class="confirm-container">
            <p><slot>Er du sikker på at du vil slette {{ item }}?</slot></p>
            <div class="buttons">
                <button @click="$emit('confirm')" class="confirm-btn"><slot name="confirm">Jeg er sikker</slot></button>
                <button @click="$emit('close')" class="cancel-btn"><slot name="cancel">Avbryt</slot></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:[ 'item' ],
    emits:[ 'confirm', 'close' ]
}
</script>

<style lang="scss" scoped>
.greyout {
    background: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; 
    z-index: 105;  
    .confirm-container {
        display: flex;
        flex-direction: column;
        background: white;
        padding: 30px 20px;
        height: auto;
        gap: 40px;
        max-width: 350px;

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 15px;

            button {
                border-radius: 25px;
                border: none;
                padding: .75rem 2rem;
                font-size: 14px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                cursor: pointer;
            }
            .confirm-btn {
            background: maroon;
            color: white;
            }
            .cancel-btn {
                background: #CFCEEA;
                color: #424242;
            }

        }
    }
}
</style>