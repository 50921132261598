<template>
    <div class="greyout">
        <div class="new-container">
            <div class="close-icon" @click="$emit('close')">✖</div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['close'],
}
</script>


<style lang="scss" scoped>
.greyout {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    gap: 10px;

    .new-container {
        background: white;
        z-index: 100;
        min-width: 400px;
        width: auto;
        height: auto;
        box-sizing: border-box;
        padding: 40px 10px;
        cursor: initial;
        position: relative;
        display: flex;
        flex-direction: row;

        .close-icon {
            position: absolute;
            z-index: 101;
            right: 15px;
            top: 10px;
            cursor: pointer;
            font-size: 24px;
        }

        .questions-page {
            width: 27vw;
        }
    }
}


</style>