<template>
    <confirmation-box
        v-if="deletingForm"
        @close="deletingForm=null"
        @confirm="confirmDeleteForm"
        :item="deletingForm.title"
    ></confirmation-box>
    <div class="forms-view">
        <header-shell>
            <template #title>Skjemaer</template>
            <template #header-buttons>
                <router-link to="/skjemaer/nyttskjema" v-if="$store.getters['Areas/hasSystemAdminAccess']"><header-button>+ Nytt skjema</header-button></router-link>
            </template>
        </header-shell>
        <div class="forms">
            <item-card v-for="form in forms" @delete="deletingForm=form" :deleteButton="$store.getters['Areas/hasSystemAdminAccess']" no-content>
                <!--span v-if="$store.getters['Areas/hasSystemAdminAccess']">{{ form.responseCount }} svar</span-->
                <template #title>
                    <div>{{ form.title }}</div>
                    <div class="response-count" :class="{ nonzero: form.unreadResponseCount > 0 }"><span>{{ form.unreadResponseCount }} uleste svar</span></div>
                </template>
                <template #buttons>
                    <router-link v-if="$store.getters['Areas/hasSystemAdminAccess']" :to="{ name: 'redigerskjema', params: { id: form.id } }"><button class="btn">Rediger</button></router-link>
                    <router-link v-if="$store.getters['Areas/hasSystemAdminAccess']" :to="{ name: 'skjemastatistikk', params: { id: form.id } }"><button class="btn">Statistikk</button></router-link>
                    <router-link :to="{ name: 'skjemasvar', params: { id: form.id } }"><button class="btn">Vis svar</button></router-link>
                </template>
            </item-card>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import ConfirmationBox from '@/components/ConfirmationBox.vue'

export default defineComponent({
    name: 'FormsView',
    components: {
        ConfirmationBox,
    },
    data: () => ({
        deletingForm: null,
    }),
    computed: {
        forms() {
            // For system admin: show all forms
            // For area admin: show only forms with area fields that cause sharing
            if (this.$store.getters['Areas/hasSystemAdminAccess']) return this.$store.state.Forms.forms;
            return this.$store.state.Forms.forms.filter(form => form.elementOrder.filter(eid => form.elements[eid].type == 'q-area' && form.elements[eid].shareWithArea).length > 0);
        },
    },
    methods: {
        async confirmDeleteForm() {
            await this.$store.dispatch('Forms/deleteForm', this.deletingForm);
            this.deletingForm = null;
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    display: flex;
}

.forms {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    border-radius: 25px;
    padding: .75em 1.5em;
    font-size: 14px;
    letter-spacing: 0.15em;
    cursor: pointer;
    background: white;
    color: #424242;
    border: solid 1px #BFBFBF;

    &.disabled {
        opacity: 0.4;
    }
}

@keyframes pulse {
    from { transform: scale(1); opacity: 0.7; }
    to { transform: scale(1.1); opacity: 1; }
}

.response-count {
    font-size: 0.75em;
    color: grey;
    text-align: left;

    &.nonzero {
        color: red;
        font-weight: bold;

        span {
            display: inline-block;
            animation-name: pulse;
            animation-iteration-count: infinite;
            animation-duration: 1s;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }
    }
}
</style>