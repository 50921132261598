<template>
    <confirmation-box
        v-if="deletingResponse"
        @close="deletingResponse=null"
        @confirm="confirmDeleteResponse"
        item="dette svaret"
    ></confirmation-box>
    <div class="form-response-view" v-if="form && responses">
        <div class="breadcrumbs">
            <button v-if="showingResponse" @click="showingResponse=null">&lt;</button>
            <span>Skjema</span>
            <span v-for="crumb in breadcrumbs">
                <span class="separator">></span>
                <span class="breadcrumb">{{ crumb }}</span>
            </span>
            <span v-if="showingResponse">
                <span class="separator">></span>
                <span class="breadcrumb"><user-loader :id="showingResponse.user">
                    <template #default="{ user }">Svar fra {{ user.username }}</template>
                    <template #placeholder>...</template>
                </user-loader></span>
            </span>
        </div>

        <div class="response-list" v-if="!showingResponse">
            <div class="response-line" v-for="(response, idx) in responses">
                <span class="username"><user-loader :id="response.user">
                    <template #default="{ user }">{{ user.username }}</template>
                    <template #placeholder>...</template>
                </user-loader></span>
                <span class="time">{{ timeStr(response.createdAt) }}</span>
                <div class="response-end">
                    <span class="unread-mark" v-if="!response.read">Ulest</span>
                    <button @click="showResponse(response, idx)">Se svar</button>
                    <button @click="deleteResponse(response)">Fjern</button>
                </div>
            </div>
            <button v-if="hasMoreResponses" @click="loadMore">Last flere</button>
        </div>

        <div class="response" v-if="showingResponse">
            <div class="control-line">
                <a href="" @click.prevent="previousResponse" v-if="hasPreviousResponse">Forrige svar</a>
                <span v-else></span>
                <a href="" @click.prevent="nextResponse" v-if="hasNextResponse">Neste svar</a>
                <span v-else></span>
            </div>
            <div class="question" v-for="questionId in questions">
                <div class="question-text">{{ form.elements[questionId].text }}</div>
                <div class="question-response" v-if="showingResponse.answers[questionId]">
                    <question-wrapper :question="form.elements[questionId]" :answer="showingResponse.answers[questionId]" />
                </div>
                <div class="question-no-response" v-else>Ikke besvart</div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import QuestionWrapper from './FormResponseView/QuestionWrapper.vue'
import UserLoader from '@/components/UserLoader.vue'
import ConfirmationBox from '@/components/ConfirmationBox.vue'

const months = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];

export default defineComponent({
    name: 'FormResponseView',
    components: {
        QuestionWrapper,
        UserLoader,
        ConfirmationBox,
    },
    props: {
        id: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        showingResponse: null,
        showingResponseIndex: 0,
        deletingResponse: null,
    }),
    computed: {
        breadcrumbs() {
            const crumbs = [`${this.form.title} (${this.responseCount})`];
            return crumbs;
        },
        form() {
            return this.$store.state.Forms.byId[this.id];
        },
        responses() {
            if (!this.$store.state.Forms.responses[this.id]) return null;
            return this.$store.state.Forms.responses[this.id].list;
        },
        responseCount() {
            return this.form.responseCount;
        },
        hasMoreResponses() {
            if (!this.$store.state.Forms.responses[this.id]) return true;
            return this.$store.state.Forms.responses[this.id].hasMore;
        },
        questions() {
            return this.form.elementOrder.filter(elementId => this.form.elements[elementId].type.startsWith('q-'));
        },
        hasPreviousResponse() {
            return this.showingResponseIndex > 0;
        },
        hasNextResponse() {
            return this.showingResponseIndex < this.responseCount - 1 || this.hasMoreResponses;
        },
    },
    methods: {
        timeStr(timestamp) {
            const date = timestamp.toDate();
            const zp = val => `${val}`.length >= 2 ? val : `0${val}`;
            return `${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}, kl. ${zp(date.getHours())}:${zp(date.getMinutes())}`
        },
        loadMore() {
            return this.$store.dispatch('Forms/fetchResponses', { form: this.form, reload: false });
        },
        showResponse(response, idx) {
            this.showingResponse = response;
            this.showingResponseIndex = idx;
        },
        previousResponse() {
            --this.showingResponseIndex;
            this.showingResponse = this.responses[this.showingResponseIndex];
        },
        async nextResponse() {
            if (this.showingResponseIndex >= this.responses.length - 1) {
                await this.loadMore();
            }
            if (this.showingResponseIndex >= this.responses.length - 1) {
                return;
            }
            ++this.showingResponseIndex;
            this.showingResponse = this.responses[this.showingResponseIndex];
        },
        deleteResponse(response) {
            this.deletingResponse = response;
        },
        confirmDeleteResponse() {
            this.$store.dispatch('Forms/deleteResponse', this.deletingResponse);
            this.deletingResponse = null;
        },
    },
    watch: {
        showingResponse: {
            handler() {
                if (!this.showingResponse) return;
                this.$store.dispatch('Forms/markResponseAsRead', this.showingResponse);
            },
            immediate: true,
        },
        form: {
            async handler(newValue, oldValue) {
                if (!this.form) return;
                if (!newValue) return;
                if (oldValue && oldValue.id == newValue.id) return;
                await this.$store.dispatch('Forms/fetchResponses', { form: this.form, reload: true });
            },
            immediate: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.form-response-view {
    text-align: left;
    padding: 0 7vw;

    .breadcrumbs {
        font-weight: bold;
        margin-bottom: 20px;

        button {
            width: 40px;
            height: 40px;
            border: none;
            background: #EEE;
            cursor: pointer;
            border-radius: 5px;
            margin-right: 15px;

            &:hover {
                background: #DDD;
            }
        }

        .separator {
            margin: 0 15px;
        }
    }

    .response-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .response-line {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 20px;
            background: white;
            border: 1px solid #DDD;
            border-radius: 10px;

            .username {
                width: 400px;
            }

            .time {
                flex-grow: 1;
                font-weight: bold;
            }

            .response-end {
                display: flex;
                gap: 20px;
                align-items: center;

                .unread-mark {
                    color: red;
                    font-weight: bold;
                }

                button {
                    background: white;
                    font-weight: bold;
                    border: 1px solid lightgrey;
                    padding: 10px 30px;
                    border-radius: 1000px;
                    cursor: pointer;
                }
            }
        }
    }

    .response {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .control-line {
            display: flex;
            justify-content: space-between;
            width: 800px;

            a {
                color: black;
            }
        }

        .question {
            width: 800px;
            padding: 20px;
            border: 1px solid #DDD;
            border-radius: 20px;
            background: white;

            .question-text {
                margin-bottom: 20px;
            }

            .question-no-response {
                font-weight: bold;
            }
        }
    }
}
</style>