<template>
    <text-question v-if="question.presentation == 'input'" :question="question" :answer="answer" />
    <div class="answer-line" v-else>
        <div class="slider" v-if="question.presentation == 'slider'">
            <div class="slider-track"></div>
            <div class="slider-value" :style="{ left: sliderHandlePos }">
                <span>{{ answer.value }}</span>
                <div class="slider-handle"></div>
            </div>
        </div>
        <div class="options" v-if="question.presentation == 'options'">
            <div class="option" v-for="i in (question.max - question.min + 1)"><input type="radio" :checked="question.min + i - 1 == answer.value" disabled /><span>{{ question.min + i - 1 }}</span></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import TextQuestion from './TextQuestion'

export default defineComponent({
    name: 'NumberQuestion',
    components: {
        TextQuestion,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sliderHandlePos() {
            const max = this.question.max - this.question.min;
            const val = this.answer.value - this.question.min;

            return `${100 * val / max}%`;
        },
    },
});
</script>

<style lang="scss" scoped>
.slider {
    position: relative;
    height: 40px;
    margin: 0 10px;

    .slider-track {
        height: 10px;
        width: 100%;
        background: #6d6c97;
        border-radius: 1000px;
        position: absolute;
        bottom: 5px;
    }

    .slider-value {
        display: flex;
        gap: 5px;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 0;
        transform: translateX(-10px);

        .slider-handle {
            width: 20px;
            height: 20px;
            background: #6d6c97;
            border-radius: 100%;
        }
    }
}

.options {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;

    .option {
        display: flex;
        gap: 5px;
        flex-direction: column;
        align-items: center;
    }
}
</style>