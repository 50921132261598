import { getFunctions, httpsCallable } from 'firebase/functions';

type Result<Value=void> = (Value extends void ? {
    status: 'ok'
}:{
    status: 'ok'
    value: Value
})|{
    status: 'error'
    error: string
    info?: string
}

class FirebaseFunctionError extends Error {
    error: string
    info?: string

    constructor(error: string, info?: string) {
        super(error);
        this.error = error;
        this.info = info;
        this.name = 'FirebaseFunctionError';
    }
}

export default function callFirebase<Args extends {}, Value=void>(functionName: string, args: Args): Promise<Value>
export default function callFirebase<Args extends void, Value=void>(functionName: string): Promise<Value>
export default async function callFirebase<Args extends {}|void, Value=void>(functionName: string, args?: Args): Promise<Value> {
    const functions = getFunctions(undefined, 'europe-west1');
    try {
        const func = httpsCallable(functions, functionName);
        const result = ((await func(args)).data as Result<Value>);

        if (result.status == 'error') throw new FirebaseFunctionError(result.error, result.info);
        if ('value' in result) return result.value;
        return undefined as unknown as Value; // Can only reach this line if Value is void
    } catch (e) {
        if (e instanceof FirebaseFunctionError) throw e;
        throw new FirebaseFunctionError(e + '');
    }
}
