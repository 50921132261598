<template>
    <div v-pre></div>
</template>

<script>
import { defineComponent } from 'vue'
import Plotly from 'plotly.js-dist-min'

export default defineComponent({
    name: 'MultipleChoiceGridQuestion',
    props: {
        question: {
            type: Object,
            required: true,
        },
        answers: {
            type: Object,
            required: true,
        },
    },
    computed: {
        data() {
            const z = [];
            let max = 0;
            for (let c of this.question.columnsOrder) {
                const column = [];
                z.push(column);
                for (let r of this.question.rowsOrder) {
                    let rowVal;
                    if (this.answers[c] && this.answers[c][r]) rowVal = this.answers[c][r];
                    else rowVal = 0;
                    column.push(rowVal);
                    max = Math.max(rowVal, max);
                }
            }
            z.reverse();

            return [
                {
                    x: this.question.columnsOrder.map(col => this.question.columns[col]),
                    y: this.question.rowsOrder.map(row => this.question.rows[row]).reverse(),
                    z,
                    type: 'heatmap',
                    hoverongaps: false,
                    colorscale: [
                        //[0, '#4C4B7B'],
                        //[1, '#F28251'],
                        [0, 'lightgrey'],
                        [1, '#4C4B7B'],
                    ],
                }
            ];
        },
    },
    mounted() {
        Plotly.newPlot(this.$el, this.data);
    },
});
</script>