<template>
    <div class="dropdown">
        <div class="main">
            <slot></slot>
            <div class="arrow" :class="{ open: isOpen }"></div>
        </div>
        <div class="inner">
            <div class="main-sim" @click="open"></div>
            <div class="options" ref="options" v-show="isOpen">
                <div class="calendar">
                    <div class="year-row period-row">
                        <div class="period-control clickable" data-control="year-prev">&lt;</div>
                        <div class="period clickable" :class="{ selected: level == 'year' }" data-period="year">{{ year }}</div>
                        <div class="period-control clickable" data-control="year-next">&gt;</div>
                    </div>
                    <div class="month-row period-row">
                        <div class="period-control clickable" data-control="month-prev">&lt;</div>
                        <div class="period clickable" :class="{ selected: level == 'month' }" data-period="month">{{ monthName }}</div>
                        <div class="period-control clickable" data-control="month-next">&gt;</div>
                    </div>
                    <table>
                        <tr>
                            <th class="week-header">Uke</th>
                            <th>M</th>
                            <th>T</th>
                            <th>O</th>
                            <th>T</th>
                            <th>F</th>
                            <th>L</th>
                            <th>S</th>
                        </tr>
                        <tr v-for="weekObj in weeks">
                            <td><div class="clickable cal-number week-number" :class="{ selected: level == 'week' && weekObj.number == week }" data-period="week" :data-week="weekObj.number" :data-year="weekObj.year">{{ weekObj.number }}</div></td>
                            <td v-for="dayVal in weekObj.days">
                                <div v-if="dayVal" class="clickable cal-number" :class="{ selected: level == 'day' && dayVal == day }" data-period="day" :data-day="dayVal">{{ dayVal }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';

const months = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

export default {
    name: 'CalendarDropdown',
    props: {
        level: {
            type: String,
            default: 'month',
        },
        year: {
            type: Number,
            required: true,
        },
        month: {
            type: Number,
            required: true,
        },
        week: {
            type: Number,
        },
        day: {
            type: Number,
        },
    },
    data: () => ({
        isOpen: false,
    }),
    computed: {
        weeks() {
            const startOfMonth = DateTime.fromObject({ year: this.year, month: this.month, day: 1 });
            const weeks = [
                {
                    number: startOfMonth.weekNumber,
                    days: [],
                },
            ];
            weeks[0].days.push(1);
            let date = startOfMonth.plus({ days: 1 });
            while (date.month == this.month) {
                if (date.weekday == 1) {
                    weeks.push({
                        year: date.weekYear,
                        number: date.weekNumber,
                        days: [],
                    });
                }
                weeks[weeks.length-1].days.push(date.day);
                date = date.plus({ days: 1 });
            }
            while (weeks[0].days.length < 7) weeks[0].days.unshift(null);
            while (weeks[weeks.length-1].days.length < 7) weeks[weeks.length-1].days.push(null);
            return weeks;
        },
        monthName() {
            return months[this.month-1];
        },
    },
    methods: {
        open() {
            this.isOpen = true;
            this.$dropdownOverlay.open(this);
        },
        clickOverlay() {
            this.isOpen = false;
        },
        clickOption(index) {
            return true; // Should remain open
        },
        emitFilter(filter) {
            this.$emit('updateFilter', filter);
            this.$nextTick(() => this.$dropdownOverlay.open(this));
            return true;
        },
        clickClickable(element) {
            if (element.classList.contains('period-control')) {
                switch (element.getAttribute('data-control')) {
                    case 'year-prev': return this.prevYear();
                    case 'year-next': return this.nextYear();
                    case 'month-prev': return this.prevMonth();
                    case 'month-next': return this.nextMonth();
                }
            }

            const filter = {
                period: element.getAttribute('data-period'),
                year: this.year,
                month: this.month,
                week: this.week,
                day: this.day,
            };

            if (element.hasAttribute('data-year')) filter.year = parseInt(element.getAttribute('data-year'));
            if (element.hasAttribute('data-month')) filter.month = parseInt(element.getAttribute('data-month'));
            if (element.hasAttribute('data-week')) filter.week = parseInt(element.getAttribute('data-week'));
            if (element.hasAttribute('data-day')) filter.day = parseInt(element.getAttribute('data-day'));

            this.emitFilter(filter);
        },
        prevYear() {
            this.emitFilter({
                period: 'year',
                year: this.year-1,
                month: this.month,
            });
        },
        nextYear() {
            this.emitFilter({
                period: 'year',
                year: this.year+1,
                month: this.month,
            });
        },
        prevMonth() {
            let month = (this.month + 11) % 12;
            if (month == 0) month = 12;
            this.emitFilter({
                period: 'month',
                year: this.year - (this.month == 1 ? 1 : 0),
                month,
            });
        },
        nextMonth() {
            let month = (this.month + 1) % 12;
            if (month == 0) month = 12;
            this.emitFilter({
                period: 'month',
                year: this.year + (this.month == 12 ? 1 : 0),
                month,
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.dropdown {
    background: white;
    border-radius: 10px;
    padding: 10px 30px;
    position: relative;
    z-index: 1;
    min-width: 300px;

    border: 1px solid darkgray;

    .main {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .arrow {
            width: 12px;
            height: 7px;
            background-color: darkslategray;
            clip-path: polygon(25% 0, 50% 55%, 75% 0, 100% 0, 50% 100%, 0 0);
            justify-self: end;
            margin-left: 10px;

            &.open {
                transform: scaleY(-1);
            }
        }
    }

    .inner {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .main-sim {
            width: 100%;
            height: 100%;
        }
    }

    .options {
        background: white;

        .calendar {
            .period-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .period-control.clickable {
                    font-weight: bold;
                    font-size: 1.3em;
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 8px 12px;
                    border: none;

                    &:hover {
                        background: #EEE;
                    }
                }

                .period {
                    flex-grow: 1;
                }
            }

            table {
                margin: auto;
                width: 350px;
            }

            .week-header {
                padding-right: 10px;
            }

            .week-number {
                margin-right: 10px;
            }

            .clickable {
                border: 1px solid darkgrey;
                border-radius: 5px;
                padding: 8px;
                cursor: pointer;

                &.cal-number {
                    width: 40px;
                    box-sizing: border-box;
                }

                &:hover {
                    background: #EEE;
                }

                &.selected {
                    background: #DDD;
                }

                &.selected:hover {
                    background: #BBB;
                }
            }
        }
    }
}
</style>