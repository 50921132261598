<template>
    <div class="item-container" :class="{ noContent: noContent }">
        <img :src="imgSrc" v-if="imgSrc" @click="$emit('img-click')"/>
        <div v-else></div>
        <div class="title-container">
            <h4><slot name="title"></slot></h4>
            <p class="stops" v-if="stops">Denne vandringen har {{stops}} stopp</p>
        </div>
        <p v-if="!noContent"><slot></slot></p>
        <div class="btn-container">
            <div class="btns">
                <slot name="buttons"></slot>
                <button class="delete-btn" @click="$emit('delete')" v-if="deleteButton">{{ typeof deleteButton == 'string' ? deleteButton : 'Fjern' }}</button>
            </div>
            <div class="hamburger-menu" @click="menuIsOpen = !menuIsOpen">
                <img src="../assets/hamburger_menu.png" alt="" />
            </div>
            <div class="popup-menu" v-if="menuIsOpen">
                <div class="btns" @click="menuIsOpen = !menuIsOpen">
                    <slot name="buttons"></slot>
                    <button class="delete-btn" @click="$emit('delete')" v-if="deleteButton">{{ typeof deleteButton == 'string' ? deleteButton : 'Fjern' }}</button>
                </div>
            </div>
            <p class="updated-by" v-if="updatedBy">Sist endret av {{ updatedBy }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemCard',
    emits: ['delete', 'img-click'],
    props: {
        imgSrc: {
            type: String,
            required: false,
        },
        stops: {
            type: Number,
            required: false,
        },
        updatedBy: {
            type: String,
            required: false,
        },
        deleteButton: {
            type: [String, Boolean],
            default: 'Fjern',
            required: false,
        },
        noContent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menuIsOpen: false,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';
.item-container {
    display: grid;
    grid-template-rows: 125px;
    grid-template-columns: 0.6fr 1fr 1fr 1fr;
    grid-template-areas: 
        ". . . .";
    width: 90%;
    align-items: center;
    justify-items: start;
    border-bottom: 1px solid #D1D1D1;
    padding: 5px;

    &.noContent {
        grid-template-columns: 0.6fr 1fr 2fr;
    }

    >p {
        padding-left: 50px;
    }

    .btn-container {
        justify-self: end;
    }

    @media (max-width: $tablet-size) {
        grid-template-rows: 62.5px 62.5px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "img h4 btns"
            "img p btns";
        align-items: center;

        &.noContent {
            grid-template-areas: "img h4 btns";
        }

        .btn-container { 
            grid-area: btns; 
            display: grid;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            position: relative;
        }

        .title-container {
            justify-self: center;
            padding: 20px;
        }

        h4 { grid-area: h4;}
        p { grid-area: p; }
        img { grid-area: img; }
    }

    .btns {
        display: flex;
        flex-direction: row;
        gap: 10px;

        @media (max-width: $tablet-size) {
            /* flex-direction: column;
            gap: 3px;
            justify-self: center; */
            display: none;
        }
    }

    .popup-menu {
        background: #6d6c97;
        padding: 15px;
        position: absolute;
        display: flex;
        top: 50px;
        flex-direction: column;
        z-index: 100;
        border-radius: 10px;

        .btns {
            display: flex;
            flex-direction: column;
        }
    }

    .updated-by {
        text-align: center;
    }

    .delete-btn {
        color: white;
        background: #CE6868;
        border-radius: 25px;
        border: none;
        padding: .75em 1.5em;
        font-size: 14px;
        letter-spacing: 0.15em;
        text-transform: capitalize;
        cursor: pointer;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: 175px;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px;
        /* cursor: pointer; */
    }

    .stops, .updated-by {
        color: gray;
        font-style: italic;
        font-size: 14px;
        padding-top: 10px;
    }
}
.hamburger-menu {
    width: 80px;
    height: 80px;

    img {
        height: 100%;
        width: 100%;
    }
    
    @media (min-width: $tablet-size) {
        display: none;
    }
}

</style>