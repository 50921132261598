<template>
    <button class="process-button" :class="{ processing: processing }" @click.prevent="internalClick"><slot :processing="processing"></slot></button>
</template>

<script>
export default {
    name: 'ProcessButton',
    props: {
        process: {
            type: Function,
            required: false,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        processing: false,
    }),
    watch: {
        modelValue() {
            this.processing = this.modelValue ?? false;
        },
    },
    methods: {
        async internalClick() {
            if (!this.process) return;
            if (this.processing) return;
            this.processing = true;
            const process = this.process();
            this.$emit('update:modelValue', true);
            this.$emit('start');
            try {
                await process;
            } catch (e) {
                console.error(e);
                this.$emit('error', e);
                this.$emit('update:modelValue', false);
                this.processing = false;
            }
            this.$emit('end');
            this.$emit('update:modelValue', false);
            this.processing = false;
        },
    },
    beforeMount() {
        this.processing = this.modelValue ?? false;
    },
}
</script>