import { Module } from 'vuex'
import { getFirestore, query, collection } from 'firebase/firestore'
import Language from '@/types/Language'
import Firestore from '@/util/FirestoreHelper'

interface LanguagesState {
    languages: Language[]
}

const languagesModule: Module<LanguagesState, void> = {
    namespaced: true,
    state: {
        languages: [],
    },
    mutations: {
        saveLanguages(state, languages: Language[]) {
            state.languages = languages;
        },
    },
    actions: {
        async init({ commit }) {
            const languages = await Firestore.get<Language>(query(collection(getFirestore(), 'Language')));
            commit('saveLanguages', languages);
        },
    },
}

export default languagesModule;