<template>
    <div class="container">
        <edit-access v-if="newAccessPopup" @close="newAccessPopup = false"></edit-access>
        <edit-access 
            v-if="editAccessPopup" 
            @close="editAccessPopup = false"
            :user="selectedUser"
            :role="selectedRole"
            ></edit-access>
        <header-shell>
            <template #title>Tilganger</template>
            <template #header-buttons v-if="$store.getters['Areas/hasEditorAccess']">
                <header-button @click="toggleNewAccessPopup">Inviter bruker</header-button>
            </template>
        </header-shell>
        <div class="inner-access-wrapper">
            <access-row v-for="user in users" :user="user.user" :role="user.role" @edit="toggleEditAccessPopup(user)" :key="user.id" />
            <invite-row v-for="invite in invited" :id="invite.id" :email="invite.inviteeEmail" :role="invite.role" :invitedBy="invite.invitedBy" />
        </div>
    </div>
</template>

<script>
import Parse from 'parse'

import EditAccess from "../components/access/EditAccess.vue"
import AccessRow from './AccessView/AccessRow.vue'
import InviteRow from './AccessView/InviteRow.vue'

export default {
    components: {
        EditAccess,
        AccessRow,
        InviteRow,
    },
    data() {
        return {
            newAccessPopup: false,
            editAccessPopup: false,
            selectedUser: null,
            selectedRole: null,
        }
    },
    computed: {
        users() {
            return [
                ...this.$store.state.Roles.admins.map(user => ({ user, role: 'admin' })),
                ...this.$store.state.Roles.editors.map(user => ({ user, role: 'editor' })),
                ...this.$store.state.Roles.viewers.map(user => ({ user, role: 'viewer' })),
            ];
        },
        invited() {
            return this.$store.state.Roles.invited;
        },
    },
    methods: {
        toggleNewAccessPopup() {
            this.newAccessPopup = !this.newAccessPopup
        },
        toggleEditAccessPopup(user) {
            this.selectedUser = user.user;
            this.selectedRole = user.role;
            this.editAccessPopup = true;
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    gap: 25px;

    .access-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 1px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 25px;
    }
}
.inner-access-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 25px 50px;
    padding: 2vw 7vw 0 6vw;

    .access-row, .invite-row {
        width: 100%;
    }
}
.access-item {
    display: flex;
    justify-content: space-between;
    width: 300px;

    &:not(.invite) {
        cursor: pointer;
    }
}
.admin {
    color: red;
}
</style>