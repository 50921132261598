<template>
    <div class="age-group-graph">
        <Bar class="bar-wrapper" :chartData="chartData" :chartOptions="graphOptions" />
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    Plugin,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const barStyle = {
    backgroundColor: '#4C4B7B',
    radius: 0,
    maxBarThickness: 50,
};

export default {
    name: 'AgeGroupGraph',
    components: {
        Bar,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
    },
    computed: {
        useRawStats() {
            return this.$store.state.Stats.useRawStats;
        },
        chartData() {
            return {
                labels: ['0-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80+'],
                datasets: [
                    {
                        data: this.values,
                        backgroundColor: '#2762FF',
                    },
                ],
                
            };
        },
        graphOptions() {
            const options = {
                scales: {
                    y: {
                        min: 0,
                        max: this.useRawStats ? null : 1,
                        ticks: {
                            callback: (value, index, ticks) => (this.useRawStats ? Math.floor(value) : `${value*100}%`)
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: (data) => {
                                if (this.useRawStats) return data.raw;
                                return `${Math.floor(data.raw * 10000) / 100}%`;
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            };

            return options;
        },
    },
}
</script>

<style lang="scss" scoped>
.age-group-graph {
    position: relative;
    width: 100%;
    height: 100%;

    .bar-wrapper {
        height: 100%;
    }
}
</style>