<template>
    <div class="checkbox" @click="$emit('update:modelValue', !modelValue)">
        <div class="box" :class="{ checked: modelValue }"></div>
        <slot></slot>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Checkbox',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
.checkbox {
    display: flex;
    align-items: center;
    gap: 5px;

    .box {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid black;
        border-radius: 4px;

        &.checked {
            background: #6d6c97;
        }
    }
}
</style>