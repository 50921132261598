<template>
    <label>
        <toggle-switch :modelValue="element.shareWithArea" @update:modelValue="update('shareWithArea', $event)" /> Gjør skjemasvar synlig for sonen som blir valgt
    </label>
</template>

<script>
import { defineComponent } from 'vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default defineComponent({
    name: 'AreaQuestion',
    components: {
        ToggleSwitch,
    },
    emits: ['update-raw', 'update-tr'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        language: {
            type: Object,
            required: false,
        },
    },
    methods: {
        update(name, value) {
            this.$emit('update-raw', { [name]: value });
        },
    },
});
</script>

<style lang="scss" scoped>
label {
    display: flex;
    gap: 10px;
    align-items: center;
}
</style>