<template>
    <text-question :question="question" :answer="{ value: area }" />
</template>

<script>
import { defineComponent } from 'vue'
import TextQuestion from './TextQuestion'

export default defineComponent({
    name: 'AreaQuestion',
    components: {
        TextQuestion,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        area() {
            return this.$store.state.Areas.areas.filter(area => area.area.id == this.answer.value)[0].area.shortName;
        },
    },
});
</script>