<template>
    <header>
        <div class="titles">
            <h1><slot name="title"></slot></h1>
            <p v-if="$slots.subtitle"><slot name="subtitle"></slot></p>
        </div>
        <div class="header-buttons">
            <slot name="header-buttons"></slot>
        </div>
    </header>
</template>

<style scoped lang="scss">
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    border-radius: 10px;
    margin:  0 5vw;

    background-color: white;
}

h1 {
    font-size: 1.5rem;
    font-weight: 400;
}

.header-buttons {
    display: flex;
    gap: 1.25rem;
    align-items: center;
}

.titles {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;

    align-items: start;
    text-align: start;

    p {
        color: gray;
    }
}
</style>