<template>
    <div class="google-map"></div>
</template>

<script>
import googlemaps from '@googlemaps/js-api-loader';

export default {
    name: 'GoogleMap',
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        zoom: {
            type: Number,
            default: 16,
        },

        draggable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        map: null,
        marker: null,

        isInternalUpdate: false,
        clickListener: null,
    }),
    methods: {
        onClick(event) {
            if (this.disabled) return;
            this.isInternalUpdate = true;
            this.$emit('update:modelValue', { lat: event.latLng.lat(), lng: event.latLng.lng() });
            this.$nextTick(() => this.isInternalUpdate = false);
        },
    },
    watch: {
        modelValue() {
            if (!this.isInternalUpdate) this.map.setCenter(this.modelValue);
            this.marker.setPosition(this.modelValue);
        },
    },
    mounted() {
        this.map = new google.maps.Map(this.$el, {
            zoom: this.zoom,
            center: this.modelValue,
            disableDefaultUI: true,
            styles: [
                {
                    featureType: "poi",
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: "transit",
                    stylers: [{ visibility: 'off' }],
                },
            ],
        });

        this.marker = new google.maps.Marker({
            position: this.modelValue,
            draggable: this.draggable,
            map: this.map,
        });

        this.clickListener = ev => this.onClick(ev);

        this.map.addListener('click', this.clickListener);
    },
}
</script>

<style lang="scss" scoped>
.google-map {
    width: 100%;
    height: 250px;
    border-radius: 15px;
    margin-top: 15px;
}
</style>