<template>
    <form>
        <div>
            <slot name="slot1"></slot>
        </div>
        <div>
            <slot name="slot2"></slot>
        </div>
        <div>
            <slot name="slot3"></slot>
        </div>
        <div>
            <slot name="slot4"></slot>
        </div>
        <div>
            <slot name="slot5"></slot>
        </div>
        <div>
            <slot name="slot6"></slot>
        </div>
        <div v-if="slots === 7">
            <slot name="slot7"></slot>
        </div>
        <slot name="button-slot"></slot>
    </form>
</template>

<script>
export default {
    props: {
        slots: {
            type: Number,
        }
    }
}
</script>

<style scoped>
form {
    display: flex;
    flex-direction: column;
}
</style>