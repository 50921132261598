<template>
    <div class="form-stats-view" v-if="form">
        <h1>Skjemastatistikk: {{ form.title }}</h1>

        <div class="questions" v-if="stats">
            <template v-for="questionId in questions">
                <div class="question" v-if="stats[questionId]">
                    <div class="question-line">
                        <div class="title">Spørsmål</div>
                        <div class="value">{{ form.elements[questionId].text }}</div>
                    </div>
                    <div class="question-line">
                        <div class="title">Svar</div>
                        <div class="value"><question-wrapper :question="form.elements[questionId]" :answers="stats[questionId]" /></div>
                    </div>
                </div>
                <div class="question" v-else>
                    <div class="question-line">
                        <div class="title">Spørsmål</div>
                        <div class="value">{{ form.elements[questionId].text }}</div>
                    </div>
                    <div class="question-line">
                        <div class="title">Svar</div>
                        <div class="value response-list">
                            <table>
                                <tr>
                                    <th>Navn</th>
                                    <th>Svar</th>
                                </tr>
                                <tr v-for="response in responses">
                                    <td>
                                        <user-loader :id="response.user">
                                            <template #default="{ user }">{{ user.username }}</template>
                                            <template #placeholder>...</template>
                                        </user-loader>
                                    </td>
                                    <td>{{ response.answers[questionId].value }}</td>
                                </tr>
                                <tr v-if="hasMoreResponses">
                                    <td colspan="2">
                                        <a href="" @click.prevent="loadMore">Last inn flere</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import QuestionWrapper from './FormStatsView/QuestionWrapper.vue'
import UserLoader from '@/components/UserLoader.vue'

export default defineComponent({
    name: 'FormStatsView',
    components: {
        QuestionWrapper,
        UserLoader,
    },
    props: {
        id: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        //form: null,
        stats: null,
    }),
    computed: {
        form() {
            return this.$store.state.Forms.byId[this.id];
        },
        questions() {
            return this.form.elementOrder.filter(elementId => this.form.elements[elementId].type.startsWith('q-'));
        },
        responses() {
            if (!this.$store.state.Forms.responses[this.id]) return null;
            return this.$store.state.Forms.responses[this.id].list;
        },
        hasMoreResponses() {
            if (!this.$store.state.Forms.responses[this.id]) return true;
            return this.$store.state.Forms.responses[this.id].hasMore;
        },
    },
    methods: {
        loadMore() {
            return this.$store.dispatch('Forms/fetchResponses', { form: this.form, reload: false });
        },
    },
    watch: {
        form: {
            async handler(newValue, oldValue) {
                if (!this.form) return;
                if (oldValue && oldValue.id == newValue.id) return;
                if (this.stats) return;
                this.stats = await this.$store.dispatch('Forms/fetchStats', this.form);
                await this.$store.dispatch('Forms/fetchResponses', { form: this.form, reload: true });
            },
            immediate: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.form-stats-view {
    text-align: left;
    padding: 0 7vw;

    .questions {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .question {
            display: flex;
            flex-direction: column;
            
            .question-line {
                display: flex;

                .title {
                    width: 120px;
                    font-weight: bold;
                }

                .value {
                    flex-grow: 1;

                    &.response-list {
                        background: white;
                        padding: 50px;
                        max-height: 200px;
                        overflow-y: auto;

                        table {
                            th {
                                padding: 10px 30px 10px 0;
                            }

                            td {
                                padding: 0 30px 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>