<template>
    <button><slot></slot></button>
</template>

<style lang="scss" scoped>
button {
    background: #6d6c97;
    color: white;
    border-radius: 40px;
    border: none;
    padding: 1rem 2rem;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: capitalize;
    cursor: pointer;
}
</style>