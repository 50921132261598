<template>
    <div class="stats-view">
        <header-shell class="header">
            <template #title>
                <slot name="title"></slot>
            </template>

            <template #subtitle>
                <slot name="subtitle"></slot>
            </template>

            <template #header-buttons>
                <checkbox :modelValue="$store.state.Stats.useRawStats" @update:modelValue="setShowRawStats" v-if="$store.getters['Areas/hasSystemAdminAccess']">Vis råtall</checkbox>
                <checkbox-dropdown class="gender-dropdown" :values="['male', 'female', 'other']" v-model="selectedGenders">
                    {{ genderString }}
                    <template #value="value">{{ getGenderString(value.value) }}</template>
                </checkbox-dropdown>
                <checkbox-dropdown class="age-group-dropdown" :values="ageGroups" v-model="selectedAgeGroups">
                    {{ ageGroupString }}
                </checkbox-dropdown>
                <calendar-dropdown class="period-dropdown"
                    :level="periodFilter.period"
                    :year="periodFilter.year"
                    :month="periodFilter.month"
                    :week="periodFilter.week"
                    :day="periodFilter.day"
                    @updateFilter="updatePeriodFilter"
                >
                    {{ periodString }}
                </calendar-dropdown>
            </template>
        </header-shell>
        <div class="contents">
            <div class="left-side">
                <slot name="left"></slot>
            </div>
            <div class="right-side">
                <ratios-display :ratios="ratios" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Checkbox from '@/components/Checkbox.vue'
import RatiosDisplay from './StatsView/RatiosDisplay.vue'
import CheckboxDropdown from '@/components/CheckboxDropdown.vue'
import CalendarDropdown from './StatsView/CalendarDropdown.vue'

const genderStrings = {
    male: 'Menn',
    female: 'Kvinner',
    other: 'Andre',
}

const months = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];
const ageGroups = ['0-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80+'];

export default defineComponent({
    name: 'StatsView',
    components: {
        Checkbox,
        RatiosDisplay,
        CheckboxDropdown,
        CalendarDropdown,
    },
    props: {
        backButton: {
            type: Boolean,
            default: false,
        },
        stats: {
            type: Object,
            required: false,
        },
        mainAction: {
            type: String,
            default: 'user-active',
        },
    },
    computed: {
        ratios() {
            if (this.$store.state.Stats.useRawStats) return this.$store.getters['Stats/counts'](this.mainAction);
            return this.$store.getters['Stats/ratios'](this.mainAction);
        },
        ageGroups() { return ageGroups; },
        selectedGenders: {
            get() {
                const filter = this.$store.state.Stats.genderFilter;
                return Object.keys(filter).filter(f => filter[f]);
            },
            set(val) {
                const filter = Object.assign({}, this.$store.state.Stats.genderFilter);
                for (let k in filter) filter[k] = false;
                for (let k of val) filter[k] = true;
                this.$store.dispatch('Stats/setGenderFilter', filter);
            },
        },
        selectedAgeGroups: {
            get() {
                const filter = this.$store.state.Stats.ageGroupFilter;
                return Object.keys(filter).filter(f => filter[f]);
            },
            set(val) {
                const filter = Object.assign({}, this.$store.state.Stats.ageGroupFilter);
                for (let k in filter) filter[k] = false;
                for (let k of val) filter[k] = true;
                this.$store.dispatch('Stats/setAgeGroupFilter', filter);
            },
        },
        genderString() {
            const filter = this.$store.state.Stats.genderFilter;
            if (Object.keys(filter).reduce((a, n) => a && filter[n], true)) return 'Alle kjønn';
            if (!Object.keys(filter).reduce((a, n) => a || filter[n], false)) return 'Ingen kjønn';
            return Object.keys(filter).filter(f => filter[f]).map(s => genderStrings[s]).join(', ');
        },
        ageGroupString() {
            const filter = this.$store.state.Stats.ageGroupFilter;
            const out = [];
            let found = null;
            for (let i = 0; i < ageGroups.length; ++i) {
                if (found === null && filter[ageGroups[i]]) {
                    found = ageGroups[i];
                    continue;
                }

                if (found !== null && !filter[ageGroups[i]]) {
                    const start = found.split('-')[0];
                    const end = ageGroups[i-1].split('-')[1];
                    out.push(`${start}-${end}`);
                    found = null;
                }
            }

            if (found) {
                if (found == ageGroups[ageGroups.length-1]) out.push(ageGroups[ageGroups.length-1]);
                else if (found == ageGroups[0]) return 'Alle aldre';
                else {
                    const start = found.split('-')[0];
                    out.push(`${start}+`);
                }
            }

            if (out.length == 0) return 'Ingen aldre';

            return out.join(', ');
        },
        periodString() {
            switch (this.periodFilter.period) {
                case 'year': return this.periodFilter.year.toString();
                case 'month': return `${months[this.periodFilter.month-1]} ${this.periodFilter.year}`;
                case 'week': return `Uke ${this.periodFilter.week}, ${this.periodFilter.year}`;
                case 'day': return `${this.periodFilter.day}. ${months[this.periodFilter.month-1].toLowerCase()} ${this.periodFilter.year}`;
                default: return '';
            }
        },
        periodFilter() {
            return this.$store.state.Stats.periodFilter;
        },
    },
    methods: {
        getGenderString(s) {
            return genderStrings[s];
        },
        updatePeriodFilter(filter) {
            this.$store.dispatch('Stats/setPeriodFilter', filter);
        },
        setShowRawStats(value) {
            this.$store.dispatch('Stats/setUseRawStats', value);
        },
        goBack() {
            history.back();
        },
    },
});
</script>

<style lang="scss" scoped>
.stats-view {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .header {
        margin: 0;
    }

    .contents {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 30px;

        .left-side {
            width: 100%;
        }

        .right-side {
            width: 100%;
            height: 100%;

            .ratios {
                height: 100%;
            }
        }
    }
}
</style>