import { createStore } from 'vuex'

import Auth from './modules/Auth'
import Users from './modules/Users'
import Areas from './modules/Areas'
import Pins from './modules/Pins'
import Roles from './modules/Roles'
//import Prizes from './modules/Prizes'
import Languages from './modules/Languages'
import Forms from './modules/Forms'
//import Tasks from './modules/Tasks'
import PinCategories from './modules/PinCategories'
import Stats from './modules/Stats'

//import AreaStats from './modules/AreaStats'
//import PinStats from './modules/PinStats'
//import PrizeStats from './modules/PrizeStats'

const store = createStore<any>({
    actions: {
        async init({ dispatch }) {
            // Dispatched when the application initializes. This is a good time
            // to load resources that need to be loaded early, that do not
            // require authentication. If authentication is required, wait for
            // onSignIn instead.

            // @ts-ignore
            let modules = this._modules.root._children;

            for (let moduleName in modules) {
                let rawModule = modules[moduleName]._rawModule;
                if (rawModule.namespaced && rawModule.actions.init) {
                    dispatch(`${moduleName}/init`);
                }
            }
        },
        async moduleEvent({ dispatch }, arg: string|{ event: string, data: any }) {
            // @ts-ignore
            let modules = this._modules.root._children;

            let event: string;
            let data: any;

            if (typeof arg == 'string') {
                event = arg;
                data = null;
            } else {
                event = arg.event;
                data = arg.data;
            }

            let dispatchName = `on${event[0].toUpperCase()}${event.substring(1)}`;

            const promises: Promise<void>[] = [];

            for (let moduleName in modules) {
                let rawModule = modules[moduleName]._rawModule;
                if (rawModule.namespaced && rawModule.actions[dispatchName]) {
                    const promise = dispatch(`${moduleName}/${dispatchName}`, data);
                    promises.push(promise);
                }
            }

            await Promise.all(promises);
        },
    },
    modules: {
        Auth,
        Users,
        Areas,
        Pins,
        Roles,
        //Prizes,
        Languages,
        Forms,
        //Tasks,
        PinCategories,
        Stats,

        //AreaStats,
        //PinStats,
        //PrizeStats,
    }
});
export default store;