<template>
    <text-question v-if="question.presentation == 'dropdown'" :question="question" :answer="{ value: question.options[answer.value[0]] }" />
    <div class="answer-line" v-else>
        <!--div v-for="value in answer.value">{{ question.options[value] }}</div-->
        <div class="option" v-for="option in question.optionsOrder">
            <input :type="question.multipleSelection ? 'checkbox' : 'radio'" :checked="answer.value.indexOf(option) != -1" disabled />
            <span>{{ question.options[option] }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import TextQuestion from './TextQuestion'

export default defineComponent({
    name: 'MultipleChoiceQuestion',
    components: {
        TextQuestion,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.answer-line {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .option {
        display: flex;
        gap: 10px;
    }
}
</style>