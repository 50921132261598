<template>
    <input type="text" class="number-input" :value="modelValue" @input="update">
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IntegerInput',
    props: {
        modelValue: {
            type: Number,
            required: false,
        },
        validate: {
            type: Function,
            required: false,
        },
    },
    methods: {
        update(event) {
            const raw = event.target.value;
            if (raw == '') {
                this.$emit('update:modelValue', null);
                return;
            }
            const parsed = raw.match(/^-?\d*$/);
            if (!parsed) {
                this.$el.value = this.modelValue;
                return;
            }
            const out = parseInt(parsed);
            if (isNaN(out)) return; // Something that is valid partial input but isn't a valid number (e.g. a minus sign) was entered
            if (this.validate && !this.validate(out)) {
                this.$el.value = this.modelValue;
                return;
            }
            this.$emit('update:modelValue', out);
        },
    },
});
</script>

<style lang="scss" scoped></style>