import { Module } from 'vuex'
import { query, collection, getFirestore, where, or, deleteField } from 'firebase/firestore'
import User from '@/types/User'
import RoleInvite from '@/types/RoleInvite'
import { AreaInfo } from './Areas'
import Firestore from '@/util/FirestoreHelper'
import callFirebase from '@/util/callFirebase'

/*interface InvitedUser {
    id: string
    email: string
    role: 'admin'|'editor'|'viewer'|'none'
    invitedBy: Parse.User
}*/

interface RolesState {
    admins: User[]
    editors: User[]
    viewers: User[]
    invited: RoleInvite[]
}

const rolesModule: Module<RolesState, any> = {
    namespaced: true,
    state: {
        admins: [],
        editors: [],
        viewers: [],
        invited: [],
    },
    mutations: {
        setUnloaded(state) {
            state.admins = [];
            state.editors = [];
            state.viewers = [];
            state.invited = [];
        },
        setLoaded(state, newState: RolesState) {
            state.admins = newState.admins;
            state.editors = newState.editors;
            state.viewers = newState.viewers;
            state.invited = newState.invited;
        },
        changeRole(state, { user, newRole }: { user: User, newRole: 'admin'|'editor'|'viewer'|'none' }) {
            state.admins = state.admins.filter(u => u.id != user.id);
            state.editors = state.editors.filter(u => u.id != user.id);
            state.viewers = state.viewers.filter(u => u.id != user.id);
            if (newRole != 'none') {
                let key = newRole + 's' as keyof RolesState;
                (state[key] as User[]).push(user);
            }
        },
        addInvite(state, invite: RoleInvite) {
            state.invited.push(invite);
        },
        removeInvite(state, inviteId: string) {
            state.invited = state.invited.filter(inv => inv.id != inviteId);
        },
    },
    actions: {
        async onAreaChanged({ commit }, area?: AreaInfo) {
            if (!area || area.role != 'admin') {
                commit('setUnloaded');
                return;
            };

            const db = getFirestore();
            const users = await Firestore.get<User>(query(
                collection(db, 'Userdata'),
                or(
                    where(`roles.${area.area.id}`, '==', 'admin'),
                    where(`roles.${area.area.id}`, '==', 'editor'),
                    where(`roles.${area.area.id}`, '==', 'viewer'),
                ),
            ));
            
            const admins: User[] = [];
            const editors: User[] = [];
            const viewers: User[] = [];

            for (let user of users) {
                if (user.roles[area.area.id] == 'admin') admins.push(user);
                if (user.roles[area.area.id] == 'editor') editors.push(user);
                if (user.roles[area.area.id] == 'viewer') viewers.push(user);
            }

            // TODO Invites
            const invited = await Firestore.get<RoleInvite>(query(
                collection(db, `Area/${area.area.id}/RoleInvite`),
                where('status', '==', 'sent'),
            ));

            commit('setLoaded', { admins, editors, viewers, invited });
        },
        async invite({ rootState, commit }, { email, role }: { email: string, role: 'admin'|'editor'|'viewer' }) {
            const areaId = rootState.Areas.selectedArea.area.id;
            const user = rootState.Auth.user!;
            const invite = await Firestore.add<RoleInvite>(`Area/${areaId}/RoleInvite`, {
                inviteeEmail: email,
                invitedBy: user.id,
                role,
                status: 'sent',
            });
            commit('addInvite', invite);
        },
        async rescind({ rootState, commit }, inviteId: string) {
            const areaId = rootState.Areas.selectedArea.area.id;
            await Firestore.update<RoleInvite>(`Area/${areaId}/RoleInvite/${inviteId}`, {
                status: 'rescinded',
            });
            commit('removeInvite', inviteId);
        },
        async accept({}, token: string) {
            const result = await callFirebase('user-acceptrole', { token });
        },
        async decline({}, token: string) {
            const result = await callFirebase('user-declinerole', { token });
        },
        async changeRole({ commit, rootState }, { user, role }: { user: User, role: 'admin'|'editor'|'viewer'|'none' }) {
            const areaId: string = rootState.Areas.selectedArea.area.id;
            await Firestore.merge(user, {
                roles: {
                    [areaId]: role == 'none' ? deleteField() : role,
                },
            });
            commit('changeRole', { user, newRole: role });
        },
    },
}

export default rolesModule;