<template>
    <popup-shell>
        <div class="new-post-container">
            <h4>Foreslått kulturskatt</h4>
            <new-form-shell>
                <template #slot1>
                    <input type="text" placeholder="Tittel" v-model="title" />
                </template>
                <template #slot2>
                    <textarea placeholder="Beskrivelse" rows="6" v-model="description"></textarea>
                </template>
                <template #slot3>
                    <input type="text" placeholder="Adresse" v-model="address" />
                </template>
                <template #slot4>
                    <input type="text" placeholder="Kilde for beskrivelse" v-model="descriptionSrc" />
                </template>
                <template #slot5>
                    <input type="text" placeholder="Kilde for bilde" v-model="imageSrc" />
                </template>
                <template #slot6>
                    <div class="upload-image-div">
                        <input type="text" placeholder="Last opp bilde"/>
                        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.21387 1.27539L7.58435 5.18015L1.21387 9.08492" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </template>
                <template #slot7>
                    <!-- <img src="../assets/bryggen.png" /> -->
                    <img :src="'../' + image" alt=""/>
                </template>
                <template #button-slot>
                    <div class="button-div">
                        <button @click.prevent="$emit('accept')">Lagre og godta</button>
                        <button @click.prevent="$emit('reject')" class="reject-btn">Avslå</button>
                    </div>
                </template>
            </new-form-shell>
        </div>
    </popup-shell>
</template>

<script>
export default {
    emits: ['accept', 'reject'],
    props: [
        'post-id',
        'title',
        'description',
        'descriptionSrc',
        'address',
        'image',
        'imageSrc'
    ]
}
</script>

<style lang="scss" scoped>
.new-post-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    /* padding: 15px; */

    h4 {
        padding: 10px;
        text-transform: uppercase;
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        flex-grow: 1;
        box-sizing: border-box;
        input, textarea {
            padding: 1em;
            border: 1px black solid;
            border-radius: 10px;
            box-sizing: border-box;
            width: 80%;
        }
        textarea {
            resize: none;
        }
        .button-div {
            display: flex;
            box-sizing: border-box;
            gap: 15px;
            /* width: 80%; */
        }
        .upload-image-div {
            position:relative;
            svg {
                position:absolute;
                right: 15%;
                top: 50%;
                transform: translateY(-50%)
            }
        }
        img {
            width: 125px;
            height: 75px;
            object-fit: cover;
            border-radius: 10px;
            vertical-align: bottom;
        }
        button {
            border: none;
            background: #6d6c97;
            padding: 1rem 11%;
            border-radius: 40px;
            color: white;
            text-transform: uppercase;
            cursor: pointer;
            margin-top: 10px;
        }
        .reject-btn {
            background: #D28484;
        }
    }
}

</style>