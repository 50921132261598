<template>
    <div class="presentation">
        <radio-group :values="['dropdown', 'radio']" :modelValue="element.presentation" @update:modelValue="update('presentation', $event)">
            <template #default="{value}">
                <span v-if="value == 'dropdown'">Dropdown</span>
                <span v-if="value == 'radio'">Avkrysning</span>
            </template>
        </radio-group>
        <div class="multiple-selection" v-if="element.presentation == 'radio'">
            <toggle-switch :modelValue="element.multipleSelection" @update:modelValue="update('multipleSelection', $event)"></toggle-switch>
            <span>Tillat valg av flere</span>
        </div>
    </div>

    <div class="options" :class="{ dropdown: element.presentation == 'dropdown', radio: element.presentation == 'radio' }">
        <div class="dropdown-value-sim" v-if="element.presentation == 'dropdown' && element.optionsOrder.length > 0">
            <span>{{ options[element.optionsOrder[0]] }}</span>
            <div class="chevron"></div>
        </div>
        <div class="option" v-for="(option, idx) in element.optionsOrder">
            <div class="input-line">
                <input type="checkbox" :checked="idx % 2 == 0" disabled v-if="element.presentation == 'radio' && element.multipleSelection" />
                <input type="radio" :checked="idx == 0" disabled v-if="element.presentation == 'radio' && !element.multipleSelection" />
                <input type="text" :value="options[option]" @input="updateOption(option, $event.target.value)" />
            </div>
            <div class="remove-button" @click="removeOption(option)"><div></div></div>
        </div>
        <div class="option add-option">
            <div class="add-button" @click="addOption"><div></div></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import RadioGroup from '@/components/RadioGroup.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default defineComponent({
    name: 'MultipleChoiceQuestion',
    components: {
        RadioGroup,
        ToggleSwitch,
    },
    emits: ['update-raw', 'update-tr'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        language: {
            type: Object,
            required: false,
        },
    },
    computed: {
        options() {
            if (this.language && this.language.key != 'no') {
                if (this.element.tr && this.element.tr[this.language.key] && this.element.tr[this.language.key].options) {
                    return this.element.tr[this.language.key].options;
                }
                return [];
            }
            return this.element.options;
        },
    },
    methods: {
        update(name, value) {
            this.$emit('update-raw', { [name]: value });
        },
        addOption() {
            let len = this.element.optionsOrder.length;
            while (this.element.options[`o${len}`]) ++len;
            this.$emit('update-raw', { optionsOrder: this.element.optionsOrder.concat([`o${len}`]) });
        },
        removeOption(id) {
            this.$emit('update-raw', { optionsOrder: this.element.optionsOrder.filter(opt => opt != id) });
        },
        updateOption(id, value) {
            const copy = {};
            for (let k in this.options) copy[k] = this.options[k];
            copy[id] = value;
            this.$emit('update-tr', { options: copy });
        },
    },
});
</script>

<style lang="scss" scoped>
.presentation {
    display: flex;
    gap: 30px;
    align-items: center;

    .multiple-selection {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}

.options {
    margin-top: 20px;
    max-width: 400px;

    &.dropdown {
        border: 1px solid lightgrey;
        border-radius: 10px;
        overflow: hidden;

        .option {
            cursor: pointer;

            &:hover {
                background: #EEE;
            }
        }

        .dropdown-value-sim {
            position: relative;
            padding: 15px 30px 15px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .chevron {
                background-color: darkslategray;
                clip-path: polygon(25% 0, 50% 55%, 75% 0, 100% 0, 50% 100%, 0 0);
                width: 12px;
                height: 7px;
                transform: scaleY(-1);
            }
        }
    }

    .option {
        padding: 15px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        &.add-option {
            justify-content: center;
        }

        .input-line {
            display: flex;
            gap: 10px;
            align-items: center;
            flex-grow: 1;

            input[type="text"] {
                width: 100%;
                padding: 5px;
                outline: none;
                border: none;
                border-bottom: 1px solid black;
            }
        }
    }
}

.remove-button, .add-button {
    background: lightgrey;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: red;
    }

    > div {
        background-color: white;
        clip-path: polygon(25% 0, 50% 25%, 75% 0, 100% 25%, 75% 50%, 100% 75%, 75% 100%, 50% 75%, 25% 100%, 0 75%, 25% 50%, 0 25%);
        width: 10px;
        height: 10px;
    }
}

.add-button {
    transform: rotate(45deg);

    &:hover {
        background: #6d6c97;
    }
}
</style>