<template>
    <div class="dropdown checkbox-dropdown">
        <div class="main">
            <slot></slot>
            <div class="arrow" :class="{ open: isOpen }"></div>
        </div>
        <div class="inner">
            <div class="main-sim" @click="open"></div>
            <div class="options" ref="options" v-show="isOpen">
                <template v-for="(value, idx) in values">
                    <div class="option" :value="idx">
                        <Checkbox :modelValue="selected[idx]"><slot name="value" :value="value">{{ value }}</slot></Checkbox>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
// This is a special case of dropdown, which displays checkboxes for selected items and doesn't close on selection.
// The values should be a list of selectable items, like with an ordinary Dropdown, but the modelValue is a list of
// those objects that were selected from that list.

// Because of this, the display value for the list's value does not have a default implementation. This is the default
// slot, and should be filled with elements to display for the list.

import Checkbox from '@/components/Checkbox.vue'

export default {
    name: 'CheckboxDropdown',
    components: {
        Checkbox,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Array,
            required: false,
        },
        checkByDefault: {
            // Whether to check new entries by default; has no effect on the initial list
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isOpen: false,
        selected: [],
        previousEmit: null,
    }),
    methods: {
        open() {
            this.isOpen = true;
            this.$dropdownOverlay.open(this);
        },
        clickOverlay() {
            this.isOpen = false;
        },
        clickOption(index) {
            this.selected[index] = !this.selected[index];
            this.$nextTick(() => this.$dropdownOverlay.open(this));
            this.emitSelected();
            return true; // Should remain open
        },
        updateSelected(addedValues) {
            const selected = new Map();
            for (let value of this.values) selected.set(value, false);
            for (let value of this.modelValue) selected.set(value, true);
            if (addedValues) for (let value of addedValues) selected.set(value, true);
            for (let i = 0; i < this.values.length; ++i) {
                this.selected[i] = selected.get(this.values[i]);
            }
        },
        emitSelected() {
            const out = this.selected.map((_, idx) => this.values[idx]).filter((_, idx) => this.selected[idx]);
            if (this.previousEmit && this.previousEmit.length == out.length && this.previousEmit.reduce((a, n, i) => a && (n == out[i]), true)) return;
            this.previousEmit = out;
            this.$emit('update:modelValue', out);
        },
    },
    watch: {
        values: {
            handler(newValues, oldValues) {
                const addedValues = new Set(newValues);
                for (let val of oldValues) {
                    addedValues.delete(val);
                }
                this.updateSelected(this.checkByDefault ? addedValues : []);
                this.emitSelected();
            },
            immediate: false,
        },
        modelValue: {
            handler() {
                this.updateSelected();
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.dropdown {
    background: white;
    border-radius: 10px;
    padding: 10px 30px;
    position: relative;
    z-index: 1;
    border: 1px solid darkgray;

    .main {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .arrow {
            width: 12px;
            height: 7px;
            background-color: darkslategray;
            clip-path: polygon(25% 0, 50% 55%, 75% 0, 100% 0, 50% 100%, 0 0);
            justify-self: end;
            margin-left: 10px;

            &.open {
                transform: scaleY(-1);
            }
        }
    }

    .inner {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .main-sim {
            width: 100%;
            height: 100%;
        }
    }

    .options {
        background: white;

        .search {
            .search-field {
                width: 100%;
                border: 1px solid lightgrey;
                padding: 5px 10px;
                border-radius: 5px;
                margin: 10px;
                cursor: text;

                .search-input {
                    border: none;
                    outline: none;
                    width: 100%;
                }
            }
        }

        .option {
            padding: 10px;
            text-align: center;
            cursor: default;

            &:hover {
                background: #EEEEEE;
            }
        }
    }
}
</style>