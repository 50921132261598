<template>
    <div class="answer-line">
        <table>
            <tr>
                <td></td>
                <th v-for="col in question.columnsOrder">{{ question.columns[col] }}</th>
            </tr>
            <tr v-for="row in question.rowsOrder">
                <th>{{ question.rows[row] }}</th>
                <td v-for="col in question.columnsOrder">
                    <input :type="question.multipleSelection ? 'checkbox' : 'radio'" :checked="isChecked(row, col)" disabled />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MultipleChoiceGridQuestion',
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        },
    },
    methods: {
        isChecked(row, col) {
            return !!this.answer.value.find(val => val.row == row && val.col == col);
        },
    },
});
</script>

<style lang="scss" scoped>
table {
    th, td {
        padding: 5px 10px;
    }
}
</style>